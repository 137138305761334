import "styles/modal/index.scss";
import { FunctionComponent } from "react";
import { Modal } from "antd";
import { X } from "react-feather";

interface ModalProps {
  header?: JSX.Element;
  headerType?: "error" | "normal";
  content?: JSX.Element;
  footer?: JSX.Element | boolean | undefined;
  isOpen?: boolean;
  handleOk?: () => void;
  handleCancel?: () => void;
  afterClose?: () => void;
  width?: string;
  closable?: boolean;
  okText?: any;
}

const BasicModal: FunctionComponent<ModalProps> = ({
  header,
  headerType,
  content,
  footer,
  handleOk,
  handleCancel,
  isOpen,
  width,
  closable,
  afterClose,
  okText,
}) => {
  return (
    <div onClick={(e) => e.stopPropagation()}>
      <Modal
        closeIcon={
          <div className="closeIcon">
            <X />
          </div>
        }
        className={"modal-" + headerType}
        visible={isOpen}
        title={header}
        onOk={handleOk}
        onCancel={handleCancel}
        afterClose={afterClose}
        footer={footer}
        width={width}
        destroyOnClose
        closable={closable}
        okText={okText}
      >
        {content}
      </Modal>
    </div>
  );
};

export default BasicModal;
