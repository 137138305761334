import FormElement from "components/form-element";
import { FunctionComponent } from "react";
import { useForm } from "react-hook-form";
import { RequestType } from "constants/enums";
import { Button, Col, Row } from "antd";
import { useMutation, useQuery } from "react-query";
import { mapEnumToSelectValues } from "utils/map-enum";
import pa from "api/pa";
import { UpdateStepOneDto } from "models/pa-step";
import LoaderApp from "components/loader";

interface IPaStepOneProps {
  paId: number;
}

const PaStepOne: FunctionComponent<IPaStepOneProps> = ({ paId }) => {
  const { control, reset, handleSubmit } = useForm<UpdateStepOneDto>({
    mode: "onBlur",
    // defaultValues: {
    //   ...paData,
    // },
    shouldFocusError: true,
  });

  const sharedProps = {
    control,
  };

  const paUpdateMutation = useMutation(pa.paUpdateStepOne);

  const getStepOne = useQuery(
    ["getStepOne"],
    () => pa.getStepOne({ id: paId }),
    {
      onSuccess(data) {
        reset({
          ...data,
        });
      },
    }
  );

  const handleConfirm = (data: any) => {
    paUpdateMutation.mutate({
      ...data,
      id: Number(paId),
    });
  };

  return (
    <LoaderApp loading={getStepOne.isLoading}>
      <Row align="bottom" justify="center">
        <Col span={4}>
          <FormElement
            {...sharedProps}
            options={mapEnumToSelectValues(RequestType)}
            label="Request Type"
            type="select"
            name="requestType"
            required
          />
        </Col>
        <Col span={4}>
          <FormElement
            {...sharedProps}
            label="Directions"
            type="input"
            name="directions"
          />
        </Col>
        <Col span={4}>
          <FormElement
            {...sharedProps}
            label="Strength"
            type="input"
            name="strength"
          />
        </Col>
        <Col span={4}>
          <FormElement
            {...sharedProps}
            label="Duration"
            type="input"
            name="duration"
          />
        </Col>
        <Col span={4}>
          <FormElement
            {...sharedProps}
            label="Quantity"
            type="input"
            name="quantity"
          />
        </Col>
        {/* <Col span={4}>
          <FormElement
            {...sharedProps}
            label="Allergies"
            type="input"
            name="allergies"
          />
        </Col> */}
        <Col span={2}>
          <Button
            type="primary"
            className="mb-3"
            loading={paUpdateMutation.isLoading}
            onClick={handleSubmit(handleConfirm)}
          >
            Confirm
          </Button>
        </Col>
      </Row>
    </LoaderApp>
  );
};

export default PaStepOne;
