import {
  useMutation,
  UseMutationResult,
  useQuery,
  useQueryClient,
  UseQueryResult,
} from "react-query";
import { notify } from "components/notification";

const useCrud = <
  CreateData,
  UpdateData,
  GetAllQuery,
  DeleteQuery,
  CancelQuery,
  GetAllResult extends {
    items: any[];
  }
>(
  operations: {
    create: (data: CreateData) => Promise<any>;
    update: (data: UpdateData) => Promise<any>;
    getAllFunc: (data: GetAllQuery) => Promise<GetAllResult>;
    delete: (data: DeleteQuery) => Promise<any>;
    cancel: (data: CancelQuery) => Promise<any>;
  },
  options: {
    dataName: string;
    getAllDefaultParams: GetAllQuery;
    getAllEnabled?: boolean;
  },
  events?: {
    onCreateSuccess: (data: any) => void;
    onUpdateSuccess: (data: any) => void;
    onCreateError?: (err: any) => void;
    onUpdateError?: (err: any) => void;
  }
): {
  getAllHook: UseQueryResult<GetAllResult>;
  createMutation: UseMutationResult<any, unknown, CreateData, unknown>;
  updateMutation: UseMutationResult<any, unknown, UpdateData, unknown>;
  deleteMutation: UseMutationResult<any, unknown, DeleteQuery, unknown>;
  cancelMutation: UseMutationResult<any, unknown, CancelQuery, unknown>;
} => {
  const QueryClient = useQueryClient();
  const getAllDefaultParams = options.getAllDefaultParams || {};
  const getAllHook = useQuery(
    [`${options.dataName}s`, ...Object.values(getAllDefaultParams)],
    () => operations.getAllFunc(options.getAllDefaultParams),
    {
      // refetchOnWindowFocus: false,
      enabled: Boolean(options.getAllEnabled),
    }
  );
  const createMutation = useMutation(operations.create, {
    onSuccess: (data) => {
      events?.onCreateSuccess(data);
      QueryClient.invalidateQueries(`${options.dataName}s`);
      notify("success", "Success", `${options?.dataName} Created Successfully`);
    },
    onMutate: async (data: CreateData) => {
      await QueryClient.cancelQueries(`${options.dataName}s`);
      const previousData = QueryClient.getQueryData<GetAllResult>(
        `${options.dataName}s`
      );
      if (previousData) {
        QueryClient.setQueryData<any>(`${options.dataName}s`, {
          ...previousData,
          items: [...previousData.items, data],
        });
      }
      return { previousData };
    },
  });
  const updateMutation = useMutation(operations.update, {
    onSuccess: (data) => {
      events?.onUpdateSuccess(data);
      QueryClient.invalidateQueries(`${options.dataName}s`);
      notify("success", "Success", `${options?.dataName} Updated Successfully`);
    },
    onSettled: () => {
      QueryClient.invalidateQueries(`${options.dataName}s`);
    },
  });
  const deleteMutation = useMutation(operations.delete, {
    onSuccess: () => {
      QueryClient.invalidateQueries(`${options.dataName}s`);
      notify("success", "Success", `${options?.dataName} Deleted Successfully`);
    },
  });

  // Cancel Mutation
  const cancelMutation = useMutation(operations.cancel, {
    onSuccess: () => {
      QueryClient.invalidateQueries(`${options.dataName}s`);
      notify(
        "success",
        "Success",
        `${options?.dataName} Stop Processing Successfully`
      );
    },
  });

  return {
    getAllHook,
    createMutation,
    updateMutation,
    deleteMutation,
    cancelMutation,
  };
};

export default useCrud;
