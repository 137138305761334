import config from "./config";
import ApiService from "./baseApi/api-service";
import axios from "axios";
import { LoginRequest } from "models/login";

class Account extends ApiService {
  constructor() {
    super({ baseURL: config.baseUrl, headers: undefined });
  }

  /* @tags Account */
  /* @name inputOutputSignin */
  /* @request POST:/api/services/app/Account/signin */
  public login = (params: LoginRequest): Promise<any> => {
    return this.post("/TokenAuth/Authenticate", {
      userNameOrEmailAddress: params.username,
      password: params.password,
    });
  };

  /* @tags Account */
  /* @name inputOutputSignup */
  /* @request POST:/api/services/app/Account/signup */
  public register = (params: {
    username: string;
    email?: string;
    password1: string;
    password2: string;
    first_name?: string;
    last_name?: string;
  }): Promise<any> => {
    return axios.post("/Account/Register/", {
      ...params,
    });
  };
}
const account = new Account();
export default account;
