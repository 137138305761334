import { FunctionComponent } from "react";
import BasicModal from "components/modal";
import AssessmentPage from "pages/assessment";
interface IAssesmentModle {
  isOpenAssesment: boolean;
  patientId?: number;
  setIsOpenAssesment: (item: boolean) => void;
}

const AssesmentModal: FunctionComponent<IAssesmentModle> = ({
  isOpenAssesment,
  patientId,
  setIsOpenAssesment,
}) => {
  return (
    <BasicModal
      header={<h2 className="text-primary"> Assessment List </h2>}
      headerType="normal"
      content={
        <AssessmentPage
          patientId={patientId}
          setIsOpenAssesment={setIsOpenAssesment}
        />
      }
      isOpen={isOpenAssesment}
      footer={false}
      width="55vw"
      handleCancel={() => {
        setIsOpenAssesment(false);
      }}
    />
  );
};

export default AssesmentModal;
