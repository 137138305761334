import { currentIndexStepAtom } from "atoms/atoms";
import { Card, Col, Row, Tooltip } from "antd";
import { FunctionComponent } from "react";
import { useRecoilState } from "recoil";
import colors from "constants/colors";
import questionMark from "assets/svgs/patient-table/assesment/question-mark.svg";
import _ from "lodash";

const SidePage: FunctionComponent<any> = ({ getAssessment }: any) => {
  const [currentIndexStep, _c] = useRecoilState(currentIndexStepAtom);

  return (
    <Card
      title={`Scores After Step ${currentIndexStep - 1}`}
      size={"small"}
      className="p-i-card-side"
      loading={getAssessment.isLoading}
    >
      <Card className="card-row-header">
        <Row justify="space-around" align="middle">
          <Col className="gutter-row text-center title-header" span={8}>
            Biologic
          </Col>

          <Col className="gutter-row text-center title-header" span={8}>
            Score
          </Col>

          <Col className="gutter-row text-center title-header" span={8}>
            PA
          </Col>
        </Row>
      </Card>
      {getAssessment?.data?.patientBiologicScoresList?.map(
        (biologic: any, index: number) => {
          const nameClarity = `${biologic?.brandName.toLowerCase()}`;
          return (
            <Card className="card-row-side" key={index}>
              <Row
                className="row-side"
                justify="space-around"
                align="middle"
                style={{
                  backgroundColor: biologic?.enabled ? colors.white : "",
                  borderColor: colors.green,
                  borderWidth: biologic?.enabled ? "3px" : "0px",
                }}
              >
                <Col className="gutter-row text-center" span={8}>
                  <img src={biologic?.biologicUrl} style={{ width: "200px" }} />
                </Col>
                <Col className="gutter-row text-center" span={7}>
                  <p>{biologic?.enabled && biologic?.patientTotalScore}</p>
                </Col>

                <Col className="gutter-row text-center" span={7}>
                  <p>
                    {biologic?.enabled &&
                      parseFloat(biologic?.biologicTotalScore).toFixed(2) +
                        " %"}{" "}
                  </p>
                </Col>
                <Col className="gutter-row text-center" span={2}>
                  <Tooltip
                    color={colors.blueGray}
                    title={
                      // <></>
                      <p style={{ whiteSpace: "pre", color: colors.black }}>
                        {!_.isEmpty(
                          getAssessment?.data?.biologicNotes?.[nameClarity]
                        ) && <p> The following attributes: </p>}
                        {getAssessment?.data?.biologicNotes?.[nameClarity]?.map(
                          (item: any) => `- ${item} \n`
                        )}
                        {!_.isEmpty(
                          getAssessment?.data?.biologicNotes?.[nameClarity]
                        ) && (
                          <p>
                            are far from spec and may affect <br /> PA approval{" "}
                          </p>
                        )}
                      </p>
                    }
                  >
                    <img src={questionMark} />
                  </Tooltip>
                </Col>
              </Row>
            </Card>
          );
        }
      )}
    </Card>
  );
};
export default SidePage;
