import React from "react";
import { Col, Row } from "antd";
import FormElement from "components/form-element";
import { GenderType, PatientType } from "constants/enums";
import { mapEnumToSelectValues } from "utils/map-enum";
import { IFormProps } from "models/baseModels/form-props";

const PatientForm: React.FC<IFormProps> = ({ control, disabled, type }) => {
  const sharedProps = {
    control,
    disabled,
  };

  return (
    <Row>
      <Col span={12}>
        <FormElement
          {...sharedProps}
          label="First Name"
          type="input"
          name="fName"
          required
        />
      </Col>
      <Col span={12}>
        <FormElement
          {...sharedProps}
          label="Last Name"
          type="input"
          name="lName"
          required
        />
      </Col>
      <Col span={12}>
        <FormElement
          {...sharedProps}
          label="City"
          type="input"
          name="city"
          required
        />
      </Col>
      <Col span={12}>
        <FormElement
          {...sharedProps}
          label="State"
          type="input"
          name="state"
          // pattern={inputsPatterns.statePattern}
          required
        />
      </Col>
      <Col span={12}>
        <FormElement
          {...sharedProps}
          label="Zip"
          type="input"
          name="zip"
          // pattern={inputsPatterns.zipCodePattern}
          required
        />
      </Col>
      <Col span={12}>
        <FormElement
          {...sharedProps}
          label="Birth Date"
          type="datePicker"
          name="birthDate"
          required
        />
      </Col>
      <Col span={12}>
        <FormElement
          {...sharedProps}
          label="Gender"
          type="select"
          name="gender"
          options={mapEnumToSelectValues(GenderType)}
          required
        />
      </Col>
      <Col span={12}>
        <FormElement
          {...sharedProps}
          label="Cell Phone"
          type="input"
          name="cellPhone"
          // pattern={inputsPatterns.phonePattern}
          required
        />
      </Col>
      <Col span={12}>
        <FormElement
          {...sharedProps}
          label="Home Phone"
          type="input"
          name="homePhone"
          // pattern={inputsPatterns.phonePattern}
          required
        />
      </Col>
      <Col span={12}>
        <FormElement
          {...sharedProps}
          label="Patient Type"
          type="select"
          name="patientType"
          options={mapEnumToSelectValues(PatientType)}
          required
        />
      </Col>
      <Col span={12}>
        <FormElement
          {...sharedProps}
          label="Address"
          type="input"
          name="address"
        />
      </Col>
      <Col span={12}>
        <FormElement
          {...sharedProps}
          label="Email"
          type="input"
          name="email"
          otherFormProps={{ type: "email" }}
        />
      </Col>
      <Col span={12}>
        <FormElement
          {...sharedProps}
          label="Weight (lbs)"
          type="input"
          name="weight"
        />
      </Col>
      <Col span={12}>
        <FormElement
          {...sharedProps}
          label="Height (in)"
          type="input"
          name="height"
        />
      </Col>
      {/* <Col span={12}>
        <FormElement
          {...sharedProps}
          label="Practitioner"
          type="multiSelect"
          name="practitioners"
          options={getAllPractitioner?.data?.results?.map((item: any) => {
            return {
              label: item.fname + item.lname,
              value: item.id,
            };
          })}
          required
        />
      </Col> */}
    </Row>
  );
};

export default PatientForm;
