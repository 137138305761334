import config from "./config";
import ApiService from "./baseApi/api-service";
import {
  PaStepFourDto,
  PaStepOneDto,
  PaStepSevenDto,
  PaStepSixDto,
  PaStepThreeDto,
  UpdateStepFiveDto,
  UpdateStepOneDto,
  UpdateStepSevenDto,
  UpdateStepSixDto,
  UpdateStepThreeDto,
  UpdateStepTwoDto,
} from "models/pa-step";
import { PaCreateDto, PaDto, PaPageDto, PaUpdateDto } from "models/pa";
import { getAllParams } from "models/baseModels/getAll-params";
import { UpdateStepFourDto } from "models/assessment-step";
class Pa extends ApiService {
  constructor() {
    super({ baseURL: config.baseUrl, headers: config.headers });
  }
  /* @tags Pa */
  /* @name PaGet */
  /* @request GET:/api/services/app/Pa/Get */
  public Get = (params: { id?: string }): Promise<PaDto> => {
    return this.get("/PriorAuthorization/Get", {
      params,
    });
  };

  /* @tags PriorAuthorization */
  /* @name PaGetAll */
  /* @request GET:/api/services/app/PriorAuthorization/GetAll */
  public getAllPa = (params: getAllParams): Promise<PaPageDto> => {
    return this.get("/PriorAuthorization/GetAll", {
      params,
    });
  };

  /* @tags PriorAuthorization */
  /* @name inputOutputCreate */
  /* @request POST:/api/services/app/PriorAuthorization/Create */
  public paCreate = (params: PaCreateDto): Promise<PaDto> => {
    return this.post("/PriorAuthorization/Create", {
      ...params,
    });
  };

  /* @tags PriorAuthorization */
  /* @name inputOutputUpdate */
  /* @request PUT:/api/services/app/PriorAuthorization/Update */
  public paUpdate = (params: PaUpdateDto): Promise<PaDto> => {
    return this.put(`/PriorAuthorization/Update`, {
      ...params,
    });
  };

  /* @tags PriorAuthorization */
  /* @name inputOutputDelete */
  /* @request POST:/api/services/app/PriorAuthorization/Delete */
  public paDelete = (params: { id: number }): Promise<any> => {
    return this.delete(`/PriorAuthorization/Delete`, { params });
  };

  /* @tags PriorAuthorization */
  /* @name inputOutputUpdate */
  /* @request PUT:/api/services/app/PriorAuthorization/UpdateStepOne */
  public paUpdateStepOne = (params: UpdateStepOneDto): Promise<any> => {
    return this.put(`/PriorAuthorization/UpdateStepOne`, {
      ...params,
    });
  };

  /* @tags PriorAuthorization */
  /* @name inputOutputUpdate */
  /* @request PUT:/api/services/app/PriorAuthorization/UpdateStepTwo */
  public paUpdateStepTwo = (params: UpdateStepTwoDto): Promise<any> => {
    return this.put(`/PriorAuthorization/UpdateStepTwo`, {
      ...params,
    });
  };

  /* @tags PriorAuthorization */
  /* @name inputOutputUpdate */
  /* @request PUT:/api/services/app/PriorAuthorization/UpdateStepThree */
  public paUpdateStepThree = (
    params: UpdateStepThreeDto
  ): Promise<PaStepThreeDto> => {
    return this.put(`/PriorAuthorization/UpdateStepThree`, {
      ...params,
    });
  };

  /* @tags PriorAuthorization */
  /* @name inputOutputUpdate */
  /* @request PUT:/api/services/app/PriorAuthorization/UpdateStepFour */
  public paUpdateStepFour = (
    params: UpdateStepFourDto
  ): Promise<PaStepFourDto> => {
    return this.put(`/PriorAuthorization/UpdateStepFour`, {
      ...params,
    });
  };

  /* @tags PriorAuthorization */
  /* @name inputOutputUpdate */
  /* @request PUT:/api/services/app/PriorAuthorization/UpdateStepFive */
  public paUpdateStepFive = (params: UpdateStepFiveDto): Promise<any> => {
    return this.put(`/PriorAuthorization/UpdateStepFive`, {
      ...params,
    });
  };

  /* @tags PriorAuthorization */
  /* @name inputOutputUpdate */
  /* @request PUT:/api/services/app/PriorAuthorization/UpdateStepFive */
  public paUpdateStepSix = (params: UpdateStepSixDto): Promise<any> => {
    return this.put(`/PriorAuthorization/UpdateStepSix`, {
      ...params,
    });
  };

  /* @tags PriorAuthorization */
  /* @name inputOutputUpdate */
  /* @request PUT:/api/services/app/PriorAuthorization/UpdateStepSeven */
  public UpdateStepSeven = (
    params: UpdateStepSevenDto
  ): Promise<PaStepSevenDto> => {
    return this.put(`/PriorAuthorization/UpdateStepSeven`, {
      ...params,
    });
  };

  public getStepOne = (params: { id: number }): Promise<PaStepOneDto> => {
    return this.get("/PriorAuthorization/GetStepOne", {
      params,
    });
  };

  public getStepThree = (params: { id: number }): Promise<PaStepThreeDto> => {
    return this.get("/PriorAuthorization/GetStepThree", {
      params,
    });
  };

  public getStepFour = (params: { id: number }): Promise<PaStepFourDto> => {
    return this.get("/PriorAuthorization/GetStepFour", {
      params,
    });
  };

  public getStepSix = (params: { id: number }): Promise<PaStepSixDto> => {
    return this.get("/PriorAuthorization/GetStepSix", {
      params,
    });
  };

  public getStepSeven = (params: { id: number }): Promise<PaStepSevenDto> => {
    return this.get("/PriorAuthorization/GetStepSeven", {
      params,
    });
  };
}
const pa = new Pa();
export default pa;
