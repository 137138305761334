import { BiologicType, OutComeType, RequestType } from "constants/enums";
import FormElement from "components/form-element";
import { paAtom } from "atoms/atoms";
import { FunctionComponent, useEffect, useState } from "react";
import { useForm } from "react-hook-form";
import { useRecoilState } from "recoil";
import { Button, Col, Row } from "antd";
import { mapEnumToSelectValues } from "utils/map-enum";
import { useMutation, useQuery } from "react-query";
import pa from "api/pa";
import LoaderApp from "components/loader";
import { PaStepSevenDto } from "models/pa-step";

interface IPaStepSevenProps {
  paId: number;
  disabled?: boolean;
}

const PaStepSeven: FunctionComponent<IPaStepSevenProps> = ({
  paId,
  disabled,
}) => {
  const [paData, _p] = useRecoilState(paAtom);
  const [outCome, setOutcome] = useState<number>(paData.outCome);
  const { control, reset, handleSubmit } = useForm<any>({
    mode: "onBlur",
    defaultValues: {
      ...paData,
    },
    shouldFocusError: true,
  });

  const sharedProps = {
    control,
  };

  useEffect(() => {
    reset({ ...paData });
    setOutcome(paData.outCome);
  }, [paData]);

  const paUpdateMutation = useMutation(pa.UpdateStepSeven);

  const getStepSeven = useQuery(
    ["getStepSeven"],
    () => pa.getStepSeven({ id: paId }),
    {
      onSuccess(data) {
        reset({
          ...data,
        });
        setOutcome(data.outCome);
      },
    }
  );
  const handleConfirm = (data: PaStepSevenDto) => {
    paUpdateMutation.mutate({
      outCome: data.outCome,
      denialReason: data.denialReason,
      effectiveDate: data.effectiveDate,
      expirationDate: data.expirationDate,
      id: paId,
    });
  };

  return (
    <LoaderApp loading={getStepSeven.isLoading}>
      <Row gutter={[8, 8]} align="bottom" justify="center">
        <Col span={5}>
          <FormElement
            {...sharedProps}
            options={mapEnumToSelectValues(OutComeType)}
            placeholder="Outcome"
            label="Outcome"
            type="select"
            name="outCome"
            onChange={(e: number) => {
              setOutcome(e);
            }}
            required
            disabled={disabled}
          />
        </Col>
        {outCome == OutComeType.Denied && (
          <Col span={10}>
            <FormElement
              {...sharedProps}
              placeholder="Denial Reason"
              label="Denial Reason"
              type="textarea"
              name="denialReason"
              required
              disabled={disabled}
            />
          </Col>
        )}

        {outCome == OutComeType.Approved && (
          <>
            <Col span={5}>
              <FormElement
                {...sharedProps}
                placeholder="Effective Date"
                label="Effective Date"
                type="datePicker"
                name="effectiveDate"
                required
                disabled={disabled}
              />
            </Col>
            <Col span={5}>
              <FormElement
                {...sharedProps}
                placeholder="Expiration Date"
                label="Expiration Date"
                type="datePicker"
                name="expirationDate"
                required
                disabled={disabled}
              />
            </Col>
          </>
        )}

        {!disabled && (
          <Col span={2}>
            <Button
              type="primary"
              className="mb-3"
              onClick={handleSubmit(handleConfirm)}
              loading={paUpdateMutation.isLoading}
            >
              Confirm
            </Button>
          </Col>
        )}
      </Row>
    </LoaderApp>
  );
};

export default PaStepSeven;
