import config from "./config";
import ApiService from "./baseApi/api-service";
import {
  PatientCreateDto,
  PatientDto,
  PatientPageDto,
  PatientUpdateDto,
} from "models/patient";
import { getAllParams } from "models/baseModels/getAll-params";

class Patient extends ApiService {
  constructor() {
    super({ baseURL: config.baseUrl, headers: config.headers });
  }
  /* @tags Patient */
  /* @name PatientGetAll */
  /* @request GET:/api/services/app/Patient/Get */
  public Get = (params: { id: number }): Promise<PatientDto> => {
    return this.get("/Patient/Get", {
      params,
    });
  };

  /* @tags Patient */
  /* @name PatientGetAll */
  /* @request GET:/api/services/app/Patient/GetAll */
  public getAll = (params: getAllParams): Promise<PatientPageDto> => {
    return this.get("/Patient/GetAll", {
      params,
    });
  };

  /* @tags Patient */
  /* @name inputOutputCreate */
  /* @request POST:/api/services/app/Patient/Create */
  public Create = (params: PatientCreateDto): Promise<PatientDto> => {
    return this.post("/Patient/Create", {
      ...params,
    });
  };

  /* @tags Patient */
  /* @name inputOutputCreate */
  /* @request POST:/api/services/app/Patient/Update */
  public Update = (params: PatientUpdateDto): Promise<PatientDto> => {
    return this.put("/Patient/Update", {
      ...params,
    });
  };

  /* @tags Patient */
  /* @name inputOutputDelete */
  /* @request POST:/api/services/app/Patient/Delete */
  public Delete = (params: { patientId: number }): Promise<any> => {
    return this.delete(`/Patient/Delete`, {
      params,
    });
  };

  /* @tags Patient */
  /* @name inputOutputDelete */
  /* @request POST:/api/services/app/Patient/Delete */
  public getPatientPaInformation = (params: {
    patientId: any;
  }): Promise<any> => {
    return this.get(`/Patient/pa_information/${params?.patientId}/`);
  };
}
const patient = new Patient();
export default patient;
