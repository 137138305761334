import config from "./config";
import ApiService from "./baseApi/api-service";
import { getAllParams } from "models/baseModels/getAll-params";
import {
  PayerCreateDto,
  PayerDto,
  PayerPageDto,
  PayerUpdateDto,
} from "models/payer";

class Payer extends ApiService {
  constructor() {
    super({ baseURL: config.baseUrl, headers: config.headers });
  }
  /* @tags Payer */
  /* @name PayerGet */
  /* @request GET:/api/services/app/Payer/Get */
  public Get = (params: { id?: number }): Promise<PayerDto> => {
    return this.get("/Payer/Get", {
      params,
    });
  };

  /* @tags Payer */
  /* @name PayerGetAll */
  /* @request GET:/api/services/app/Payer/GetAll */
  public getAll = (params: getAllParams): Promise<PayerPageDto> => {
    return this.get("/Payer/GetAll", {
      params,
    });
  };

  /* @tags Payer */
  /* @name get_all_lite */
  /* @request GET:/api/services/app/payer/get_all_lite */
  public getAllLitePayer = (params: getAllParams): Promise<any> => {
    return this.get("/Payer/get_all_lite/", {
      params,
    });
  };

  /* @tags Payer */
  /* @name inputOutputCreate */
  /* @request POST:/api/services/app/Payer/Create */
  public Create = (params: PayerCreateDto): Promise<PayerDto> => {
    return this.post("/Payer/Create", {
      ...params,
    });
  };

  /* @tags Payer */
  /* @name inputOutputUpdate */
  /* @request PUT:/api/services/app/Payer/Update */
  public Update = (params: PayerUpdateDto): Promise<PayerDto> => {
    return this.put(`/Payer/Update`, {
      ...params,
    });
  };

  /* @tags Payer */
  /* @name inputOutputDelete */
  /* @request POST:/api/services/app/Payer/Delete */
  public Delete = (params: { id: number }): Promise<any> => {
    return this.delete(`/Payer/Delete`, { params });
  };
}
const payer = new Payer();
export default payer;
