import pa from "api/pa";
import GeneralFormContainer from "components/general-form-containner";
import { FunctionComponent } from "react";
import { useMutation } from "react-query";
import StepFourForm from "./form-step-four";
import { Card } from "antd";

interface IPaStepFourProps {
  paId: number;
}

const PaStepFour: FunctionComponent<IPaStepFourProps> = ({ paId }) => {
  const paUpdateMutation = useMutation(pa.paUpdateStepFour);

  const handleConfirm = (data: any) => {
    paUpdateMutation.mutate({
      ...data,
      id: Number(paId),
    });
  };

  return (
    <Card size="small" className="form-step-four">
      <GeneralFormContainer
        dataName="StepFour"
        type="Create"
        content={StepFourForm}
        actionLoading={paUpdateMutation.isLoading}
        onSubmit={(data: any) => {
          handleConfirm(data);
        }}
        detailsMapper={(data: any) => {
          return {
            ...data,
          };
        }}
        getFunc={() => pa.getStepFour({ id: paId })}
      />
    </Card>
  );
};

export default PaStepFour;
