import BasicModal from "components/modal";
import { FunctionComponent } from "react";

interface IActionModle {
  isOpenAction: boolean;
  setIsOpenAction: (item: boolean) => void;
  data: any;
}

const ActionModal: FunctionComponent<IActionModle> = ({
  isOpenAction,
  setIsOpenAction,
  data,
}) => {
  return (
    <BasicModal
      header={<h2 className="text-primary"> Action List </h2>}
      headerType="normal"
      content={<> - {data?.value} </>}
      isOpen={isOpenAction}
      footer={false}
      width="35vw"
      handleCancel={() => {
        setIsOpenAction(false);
      }}
    />
  );
};

export default ActionModal;
