import { RouteObject } from "react-router";
import PatientPage from "pages/patients";
import Assessment from "pages/assessment/forms/assessment-details";
import SettingPage from "pages/settings/page";
import PaDashboardPage from "pages/pa/pa-dashboard";
import PaPage from "pages/pa";
import UsersPage from "pages/users";
import PayersPage from "pages/payers/payer-list";
import PayerInfo from "pages/payers/payer-list/forms/payer-info-form";
import PatientDetailsPage from "pages/patients/forms/patient-details";

export const authenticatedRoutes: RouteObject[] = [
  { path: "dashboard", element: <PatientPage /> },
  { path: "patients", element: <PatientPage /> },
  { path: "patient/:id", element: <PatientDetailsPage /> },
  { path: "assessment/:id/:patientId", element: <Assessment /> },
  { path: "pa", element: <PaPage /> },
  { path: "pa/:id/:biologic", element: <PaDashboardPage /> },
  { path: "payers", element: <PayersPage /> },
  { path: "payer/:id", element: <PayerInfo /> },
  { path: "users", element: <UsersPage /> },
  { path: "settings", element: <SettingPage /> },
];
