import React from "react";
import { Col, Row } from "antd";
import FormElement from "components/form-element";
import { PayerType } from "constants/enums";
import { mapEnumToSelectValues } from "utils/map-enum";
import { IFormProps } from "models/baseModels/form-props";

interface PayerFormProps extends IFormProps {}

const PayerForm: React.FC<PayerFormProps> = ({ control, disabled }) => {
  const sharedProps = {
    control,
    disabled,
  };

  return (
    <Row>
      <Col span={12}>
        <FormElement
          {...sharedProps}
          label="Name"
          type="input"
          name="name"
          required
        />
      </Col>
      <Col span={12}>
        <FormElement
          {...sharedProps}
          label="Payer Type"
          type="select"
          name="payerType"
          options={mapEnumToSelectValues(PayerType)}
          required
        />
      </Col>
      <Col span={12}>
        <FormElement
          {...sharedProps}
          label="Contact Number"
          type="input"
          name="contactNumber"
        />
      </Col>
      <Col span={12}>
        <FormElement
          {...sharedProps}
          label="State"
          type="input"
          name="state"
          max={2}
        />
      </Col>
    </Row>
  );
};

export default PayerForm;
