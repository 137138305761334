import { IFormError } from "components/general-form-containner";
import { ApiErrorDto } from "models/baseModels/api-error";
import _ from "lodash";

/**
 * Maps api validation errors to react-hook-form errors
 * @param  {ApiErrorDto} Api error response
 * @returns IFormError[]
 */
export const mapApiErrorToForm = (errorResponse: ApiErrorDto): IFormError[] => {
  if (_.isEmpty(errorResponse?.error?.validationErrors)) return [];
  const validationErrors: IFormError[] = [];
  errorResponse.error.validationErrors.forEach((validationError: any) => {
    validationErrors.push({
      path: validationError.members[0],
      message: validationError.message,
    });
  });

  return validationErrors;
};
