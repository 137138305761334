import { AssessmentStepType } from "constants/enums";
import { columnsInterface } from "models/baseModels/table-columns";

export const assessmentColumns: columnsInterface[] = [
  {
    title: "Id",
    dataSelector: "id",
    type: "string",
  },
  {
    title: "Patient Name",
    dataSelector: "patientName",
    type: "string",
  },
  {
    title: "Practitioner",
    dataSelector: "practitionerName",
    type: "string",
  },
  {
    title: "Assessment Date",
    dataSelector: "date",
    type: "date",
  },
  {
    title: "Current Step",
    dataSelector: "currentStep",
    type: "custom",
    render(value, row) {
      return row?.currentStep + "/5 " + AssessmentStepType[row?.currentStep];
    },
  },
];
