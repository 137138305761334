import { columnsInterface } from "models/baseModels/table-columns";

export const medicationColumns: columnsInterface[] = [
  { type: "string", title: "Name", dataSelector: "name" },
  {
    type: "custom",
    title: "Dose",
    dataSelector: "dose",
    render(text, row) {
      return row.dose;
    },
  },
  { type: "string", title: "Rate", dataSelector: "rate" },
  {
    type: "custom",
    title: "Effictive Period",
    dataSelector: "effectivePeriodStart",
    render(text, row) {
      const effectivePeriodStart: any = new Date(row.effectivePeriodStart);
      const effectivePeriodEnd: any = new Date(row.effectivePeriodEnd);

      const differenceInMilliseconds =
        effectivePeriodEnd.getTime() - effectivePeriodStart.getTime();
      const differenceInDays = differenceInMilliseconds / (1000 * 60 * 60 * 24);
      return differenceInDays + " Day";
    },
  },
];
