import _ from "lodash";

export const attachmentMapper = (
  attachmentsNamesArr: string[],
  data: any,
  multiple?: boolean
): Object => {
  let result: any = {};

  const pickedAttachments = _.pick(data, attachmentsNamesArr);

  _.forIn(pickedAttachments, (value, key) => {
    let Ids =
      value && Array.isArray(value)
        ? value?.map((v: any) => v.response?.result?.id ?? v?.id)
        : [value?.id];
    result[`${key}Id${multiple ? "s" : ""}`] = multiple ? Ids : _.first(Ids);
  });

  return result;
};
