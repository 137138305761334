import GeneralTable from "components/general-table";
import DetailsIcon from "assets/svgs/general-table/details.svg";
import { payerColumns } from "./data";
import payer from "api/payer";
import { PayerType } from "constants/enums";
import PayerForm from "./forms";
import { useNavigate } from "react-router-dom";

export default function PayersPage() {
  const navigate = useNavigate();

  return (
    <GeneralTable
      headerTable="Payers"
      dataName="Payer"
      getAllFunc={payer.getAll}
      getFunc={payer.Get}
      delete
      deleteFunc={payer.Delete}
      create
      createFunc={payer.Create}
      updateFunc={payer.Update}
      formContent={PayerForm}
      tableColumns={payerColumns}
      customOnRowClick={(row) => {
        navigate(`/auth/payer/${row?.id}`);
      }}
      tableDataMapper={(rows) => {
        return rows?.map((row: any) => {
          return {
            ...row,
            payerType: PayerType[row?.payerType],
          };
        });
      }}
      customActions={[
        {
          Icon: (
            <img
              src={DetailsIcon}
              className="icon-button-table"
              alt="Dashboard Icon"
            />
          ),
          cb: (row) => {
            navigate(`/auth/payer/${row?.id}`);
          },
          tooltip: "Payer Dashboard",
        },
      ]}
    />
  );
}
