import Cookies from "js-cookie";

const config = {
	baseUrl:
		process.env.REACT_APP_API_URL?.replace(/\/$/, "") + "/services/app/",
	headers: {
		Authorization: `Bearer ${Cookies.get("CPI_TOKEN")}`,
	},
};
export default config;
