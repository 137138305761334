import { Avatar, Card, Col, Row } from "antd";
import { FunctionComponent } from "react";
import moment from "moment";
import { dateFormatMonth } from "constants/index";
import { useParams } from "react-router-dom";
import PatientCard from "components/patient-info/card-info";

const PatientInfo: FunctionComponent<any> = ({ getAssessment }) => {
  var assessments = getAssessment?.data;
  const { patientId } = useParams();

  return (
    <Row style={{ alignItems: "center" }} className="patient-information">
      <Col span={8}>
        <PatientCard
          gender={assessments?.patient?.gender}
          name={assessments?.patient?.name}
          age={assessments?.patient?.age}
          birthDate={assessments?.patient?.birthDate}
          weight={assessments?.patient?.weight}
          patientId={Number(patientId)}
          loading={getAssessment.isLoading}
        />
      </Col>
      <Col span={5} className="col-p-i-card one">
        <Card
          title="Assessment Details"
          size={"small"}
          className="p-i-card one"
          loading={getAssessment.isLoading}
        >
          <p className="date-asses">
            Date: {moment(assessments?.date)?.format(dateFormatMonth)}
          </p>
          <p>
            ID: {assessments?.id} .....Current Step: {assessments?.currentStep}{" "}
            /5{" "}
          </p>
          <p>Practitioner: {assessments?.practitioner?.label}</p>
        </Card>
      </Col>
      <Col span={8} className="col-p-i-card">
        <Card
          title="Clarity Notes"
          size={"small"}
          className="p-i-card three"
          loading={getAssessment.isLoading}
        >
          <Row>
            <p className="title-ac">Missing attributes: </p>
            <p>
              {assessments?.clarityNotes?.missingAttributes?.map(
                (missing: any) => missing + ", "
              )}
            </p>
          </Row>
          <Row>
            <p className="title-ac">Out of date attributes: </p>
            <p>
              {assessments?.clarityNotes?.oldAttributes?.map(
                (old: any) => old + ", "
              )}
            </p>
          </Row>
        </Card>
      </Col>
    </Row>
  );
};
export default PatientInfo;
