import { CardDto } from "models/assessment-step";
import BasicModal from "components/modal";
import { Button, Col, Row } from "antd";

interface ConfirmFormProps {
  formVisable: boolean;
  setFormVisable: (state: boolean) => void;
  onConfirmHandle?: () => Promise<any>;
  loadingConfirm?: boolean;
  Card?: CardDto[];
}

const ModalConfirm: React.FC<ConfirmFormProps> = ({
  formVisable,
  setFormVisable,
  onConfirmHandle,
  loadingConfirm,
  Card,
}) => {
  return (
    <BasicModal
      header={<h2> Are You Sure? </h2>}
      headerType="normal"
      content={
        <>
          <div className="confirm-contant">
            {Card && Card.some((card: CardDto) => !card.cardRecords) && (
              <Row justify="center" className="font-bold">
                {" "}
                Missing Attributes:{" "}
              </Row>
            )}
            {Card &&
              Card.map((card: CardDto) => {
                return (
                  <>
                    {!card.cardRecords && (
                      <Row justify="center"> - {card.cardElement.title} </Row>
                    )}
                  </>
                );
              })}
          </div>
          <Row>
            <Col span={8} offset={4}>
              <Button
                type="primary"
                className="savebtn"
                onClick={onConfirmHandle}
                loading={loadingConfirm}
              >
                Yes
              </Button>
            </Col>
            <Col span={8} offset={4}>
              <Button
                type="default"
                className="savebtn"
                onClick={() => {
                  setFormVisable(false);
                }}
              >
                No
              </Button>
            </Col>
          </Row>
        </>
      }
      isOpen={formVisable}
      footer={false}
      width="30vw"
      handleCancel={() => {
        setFormVisable(false);
      }}
    />
  );
};
export default ModalConfirm;
