import { useParams } from "react-router-dom";
import GeneralTable from "components/general-table";
import condition from "api/condition";
import { conditionColumns } from "./data";
import ConditionForm from "./forms";

const Condition = () => {
  const { patientId } = useParams();

  return (
    <GeneralTable
      dataName="Condition"
      getAllFunc={() => condition.getAll({ patientId: patientId })}
      getFunc={condition.Get}
      delete
      deleteFunc={condition.Delete}
      create
      createFunc={condition.Create}
      update
      updateFunc={condition.Update}
      formContent={ConditionForm}
      tableColumns={conditionColumns}
      FormSubmitMapper={(data: any) => {
        return {
          ...data,
          code: data?.display?.value ?? data?.code,
          display: data?.display?.label ?? data?.display,
          patientId: patientId,
        };
      }}
    />
  );
};
export default Condition;
