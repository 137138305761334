import "./assesment.scss";
import { Button, Col, Row } from "antd";
import { PhenoType } from "constants/enums";
import { useMutation, useQueryClient } from "react-query";
import assessment from "api/assessment";
import { notify } from "components/notification";
import { useForm } from "react-hook-form";
import FormElement from "components/form-element";
import LableColor from "components/lable-color";
import { useRecoilState } from "recoil";
import { refetchMedicationDataAtom } from "atoms/atoms";
import { FunctionComponent, useEffect, useState } from "react";
import ModalObservation from "./modal-observation";
import ModalConfirm from "./modal-confirm";
import _ from "lodash";
import { mapEnumToSelectValues } from "utils/map-enum";
import CardAssessment from "components/card-assessment";
import { AssessmentStepTwoDto, UpdateStepTwoDto } from "models/assessment-step";

interface StepTwoProps {
  data: AssessmentStepTwoDto;
  assessmentId: number;
  getAssessment: any;
}

const StepTwo: FunctionComponent<StepTwoProps> = ({
  data,
  assessmentId,
  getAssessment,
}) => {
  const [refetchMedicationData, setRefetchMedicationData] = useRecoilState(
    refetchMedicationDataAtom
  );
  const [formVisable, setFormVisable] = useState<boolean>(false);
  const [confirmVisible, setConfirmVisible] = useState<boolean>(false);
  const [cardInfo, setCardInfo] = useState<number>();
  const queryClient = useQueryClient();

  const { control, reset, handleSubmit, setValue } = useForm<UpdateStepTwoDto>({
    mode: "onBlur",
    // defaultValues: {
    //   phenotype: data?.phenotype ? data?.phenotype : data?.suggestedPhenotype,
    // },
    shouldFocusError: true,
  });

  const sharedProps = {
    control,
  };

  const ConfirmMutate = (data: UpdateStepTwoDto) => {
    Confirm.mutate({
      ...data,
      id: assessmentId,
    });
  };

  const OnSubmitResetValue = () => {
    setValue?.("phenotype", data?.suggestedPhenotype);
  };

  const Confirm = useMutation(assessment.updateStepTwoAssessment, {
    onSuccess: () => {
      setConfirmVisible(false);
      setRefetchMedicationData(!refetchMedicationData);
      notify("success", "ok", "Success", "Update Step Two");
      queryClient.invalidateQueries(["assessmentStepTwo"]);
    },
  });

  useEffect(() => {
    setValue?.("phenotype", data?.phenotype);
  }, [assessmentId, data]);

  return (
    <div className="one-step">
      <ModalObservation
        setFormVisable={setFormVisable}
        formVisable={formVisable}
        cardInfo={cardInfo}
        getAssessment={getAssessment}
      />{" "}
      {/* modal create observation after click on card */}
      <ModalConfirm
        formVisable={confirmVisible}
        setFormVisable={setConfirmVisible}
        onConfirmHandle={handleSubmit(ConfirmMutate)}
        loadingConfirm={Confirm.isLoading}
        Card={data.cardDtos}
      />
      <Row>
        <Col flex={3}>
          <Row>
            <p className="one-step-header-left">Biomarkers and Tests</p>
          </Row>
        </Col>
        <Col flex={0.1}>
          <div className="one-step-header-right">
            <Row justify="space-between">
              {LableColor("Normal", "#00B050")}
              {LableColor("High", "#EA2323")}
            </Row>
          </div>
        </Col>
      </Row>
      <Row gutter={[8, 8]} className="row-card-asess">
        {data?.cardDtos?.map((card: any, index: number) => {
          return (
            <CardAssessment
              numberCol={4}
              card={card}
              index={index}
              cardDtos={data?.cardDtos}
              onClickCard={() => {
                setFormVisable(true);
                setCardInfo(card);
              }}
            />
          );
        })}
      </Row>
      <Row className="row-asses-f">
        <Col span={3}>
          {" "}
          <p className="full-width"> phenotype </p>{" "}
        </Col>
        <Col span={7}>
          <FormElement
            {...sharedProps}
            // value={data?.phenotype !== 0 ? data?.phenotype : data?.suggestedPhenotype}
            options={mapEnumToSelectValues(PhenoType)}
            // className="full-width"
            placeholder="Allergic"
            label=""
            type="select"
            name="phenotype"
            required
          />
        </Col>
        <Col span={4}>
          <Button
            className="full-width"
            onClick={() => {
              OnSubmitResetValue();
            }}
          >
            Reset to default
          </Button>
        </Col>
        <Col span={6}>
          <Button
            className="full-width"
            type="primary"
            onClick={() => {
              setConfirmVisible(true);
            }}
          >
            Confirm phenotype
          </Button>
        </Col>
      </Row>
    </div>
  );
};
export default StepTwo;
