import { FunctionComponent } from "react";
import { Collapse } from "antd";
import type { CollapseProps } from "antd";
import "styles/collapse/index.scss";

interface CollapseComProps {
  items: CollapseProps["items"];
  accordion?: boolean;
  style?: any;
  defaultActiveKey?: number;
}

const AntCollapse: FunctionComponent<CollapseComProps> = ({
  items,
  accordion,
  style,
  defaultActiveKey,
}) => {
  return (
    <Collapse
      style={
        style ?? {
          background: "$primary",
          border: "none",
        }
      }
      defaultActiveKey={defaultActiveKey}
      accordion={accordion}
      items={items}
    />
  );
};

export default AntCollapse;
