import { FunctionComponent } from "react";
import { Checkbox, Col, Row } from "antd";

interface ITopRightProps {
  data: any;
}

const TopRight: FunctionComponent<ITopRightProps> = ({ data }) => {
  // const { control, reset, handleSubmit } = useForm<any>({
  //   mode: "onBlur",
  //   defaultValues: {},
  //   shouldFocusError: true,
  // });

  return (
    <div className="section t-r">
      {data?.flags?.map((item: any) => (
        <Row justify="center" align="middle">
          <Col span={20}>
            <Checkbox
              defaultChecked={item?.isValid}
              name={item?.label}
              disabled={true}
              className="ant-custom-input"
            >
              {item?.label}
            </Checkbox>
            {`${"(" + item?.percentage + " %)"}`}
          </Col>
        </Row>
      ))}
    </div>
  );
};

export default TopRight;
