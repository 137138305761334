import FormElement from "components/form-element";
import { FunctionComponent } from "react";
import { useForm } from "react-hook-form";
import {
  AdministrationType,
  AuthorizationType,
  CoverageType,
  ServiceType,
} from "constants/enums";
import { Button, Col, Row } from "antd";
import { mapEnumToSelectValues } from "utils/map-enum";
import { UpdateStepThreeDto } from "models/pa-step";
import { useMutation, useQuery } from "react-query";
import pa from "api/pa";
import LoaderApp from "components/loader";

interface IPaStepthreeProps {
  paId: number;
}

const PaStepthree: FunctionComponent<IPaStepthreeProps> = ({ paId }) => {
  const { control, reset, handleSubmit } = useForm<UpdateStepThreeDto>({
    mode: "onBlur",
    // defaultValues: {
    //   ...paData,
    // },
    shouldFocusError: true,
  });

  const sharedProps = {
    control,
  };

  // useEffect(() => {
  //   reset({ ...paData });
  // }, [paData, reset]);

  const paUpdateMutation = useMutation(pa.paUpdateStepThree);

  const getStepThree = useQuery(
    ["getStepThree"],
    () => pa.getStepThree({ id: paId }),
    {
      onSuccess(data) {
        reset({
          ...data,
        });
      },
    }
  );

  const handleConfirm = (data: UpdateStepThreeDto) => {
    paUpdateMutation.mutate({
      ...data,
      id: Number(paId),
    });
  };

  return (
    <LoaderApp loading={getStepThree.isLoading}>
      <Row align="bottom" justify="center">
        <Col span={5}>
          <FormElement
            {...sharedProps}
            options={mapEnumToSelectValues(ServiceType)}
            placeholder="Submission Service"
            label="Submission Service"
            type="select"
            name="submissionService"
          />
        </Col>
        <Col span={5}>
          <FormElement
            {...sharedProps}
            options={mapEnumToSelectValues(AuthorizationType)}
            placeholder="Authorization Type"
            label="Authorization Type"
            type="select"
            name="authorizationType"
          />
        </Col>
        <Col span={5}>
          <FormElement
            {...sharedProps}
            options={mapEnumToSelectValues(CoverageType)}
            placeholder="Benefit Type"
            label="Benefit Type"
            type="select"
            name="benefitType"
          />
        </Col>
        <Col span={5}>
          <FormElement
            {...sharedProps}
            options={mapEnumToSelectValues(AdministrationType)}
            placeholder="Administered Type"
            label="Administered Type"
            type="select"
            name="administrationType"
          />
        </Col>
        <Col span={2}>
          <Button
            type="primary"
            className="mb-3"
            loading={paUpdateMutation.isLoading}
            onClick={handleSubmit(handleConfirm)}
          >
            Confirm
          </Button>
        </Col>
      </Row>
    </LoaderApp>
  );
};

export default PaStepthree;
