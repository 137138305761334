export enum GenderType {
  "Male" = 1,
  "Female" = 2,
}

export enum PatientType {
  "Synthetic" = 1,
  "FromClinic" = 2,
  "UseCase" = 3,
}

export enum AssessmentStepType {
  "Severity" = 1,
  "Phenotype" = 2,
  "Treatment" = 3,
  "Scores" = 4,
  "PACharts" = 5,
}

export enum PayerType {
  "Private" = 1,
  "Medicare" = 2,
  "Medicaid" = 3,
  "Other" = 4,
}

export enum PlanType {
  "Commercial" = 1,
  "Medicare" = 2,
  "Medicaid" = 3,
  "HMO" = 4,
  "PPO" = 5,
  "EPO" = 6,
  "POS" = 7,
  "Other" = 8,
}

export enum BenefitType {
  "Medical" = 1,
  "Pharmacy" = 2,
  "Both" = 3,
}

export enum CoverageType {
  "Medical" = 1,
  "Pharmacy" = 2,
}

export enum MedicareCoverageType {
  "PartA" = 1,
  "PartB" = 2,
  "PartC" = 3,
  "PartD" = 4,
  "MedicareRx" = 5,
  "MedicareSupplement" = 6,
  "Managed" = 7,
}

export enum ServiceType {
  "None" = 1,
  "Specialist" = 2,
  "OfficeVisit" = 3,
  "UrgentCare" = 4,
  "EmergencyRoom" = 5,
  "Generic" = 6,
  "Brand" = 7,
}

export enum CardType {
  "Observation" = 1,
  "Condition" = 2,
  "Medication" = 3,
  "Score" = 4,
}

export enum GraphType {
  "Line" = 1,
  "Bar" = 2,
}

export enum ObservationConditionCategoryType {
  "Laboratory" = 1,
  "Symptoms" = 2,
  "Procedure" = 3,
  "Survey" = 4,
  "Findings" = 5,
  "Therapy" = 6,
  "SocialHistory" = 7,
  "Condition" = 8,
}

export enum MedicationCategoryType {
  "ICS" = 1,
  "LowDoseICS" = 2,
  "MediumDoseICS" = 3,
  "HighDoseICS" = 4,
  "LABA" = 5,
  "LAMA" = 6,
  "LTRA" = 7,
  "SABA" = 8,
  "OCS" = 9,
  "ControllerOtherThanOCS" = 10,
  "Biologic" = 11,
}

export enum ObservationStatusType {
  "Normal" = 1,
  "High" = 2,
  "Well" = 3,
  "Partially" = 4,
  "Poorly" = 5,
  "None" = 6,
}

export enum AsthmaType {
  "Severe Persistent" = 1,
  "Moderate Persistent" = 2,
  "Mild Persistent" = 3,
  "Mild Intermittent" = 4,
  "Not Decided" = 5,
}

export enum ControlType {
  "Well Controlled" = 1,
  "Not Well Controlled" = 2,
  "Poorly Controlled" = 3,
  "Not Decided" = 4,
}

export enum PhenoType {
  "Non-T2" = 1,
  "Allergic" = 2,
  "Nonallergic" = 3,
}

export enum TreatmentType {
  "Anti-IgE" = 1,
  "Anti-IL4" = 2,
  "Anti-IL5" = 3,
  "Anti-TSLP" = 4,
}

export enum BiologicType {
  "Xolair" = 1,
  "Nucala" = 2,
  "Cinqair" = 3,
  "Fasenra" = 4,
  "Dupixent" = 5,
  "Tezspire" = 6,
}

export enum RefType {
  "PA_FORM" = 1,
  "DRUG_POLICY" = 2,
  "PATIENT_FORM" = 3,
}

export enum StatusType {
  "New" = 1,
  "Info" = 2,
  "Submitted" = 3,
  "Respone" = 4,
  "Completed" = 5,
}

export enum OutComeType {
  "Approved" = 1,
  "Denied" = 2,
}

export enum AuthorizationType {
  "Buy & Bill" = 1,
  "Specialty Pharmacy" = 2,
}

export enum RequestType {
  "Initial" = 1,
  "Renewal" = 2,
  "Appeal" = 3,
  "Peer To Peer" = 4,
  "External Review" = 5,
  "Formal Grievance" = 6,
}

export enum UserType {
  "Admin" = 1,
  "Clinic Admin" = 2,
  "Practitioner" = 3,
  "Back Office" = 4,
  "Front Office" = 5,
}

export enum AdministrationType {
  "Self Administered" = 1,
  "HCP Administered" = 2,
}

export enum ObservationUnitType {
  "per month" = 1,
  "per week" = 2,
  "per day" = 3,
  "per year" = 4,
  "%" = 5,
  "cells/uL" = 6,
  "ppb" = 7,
  "IU/ml" = 8,
  "mm" = 9,
  "KU/L" = 10,
  "Years" = 11,
  "None" = 12,
}

export enum PAStepType {
  "Medication" = 1,
  "Insurance" = 2,
  "Coverage" = 3,
  "Information" = 4,
  "Filling" = 5,
  "Submission" = 6,
  "Response" = 7,
  // "Completed" = 8,
}

export enum SubmissionServiceType {
  "Availity" = 1,
  "Covermymeds" = 2,
  "Uhcprovider" = 3,
  "Umr" = 4,
  "Cignapromptpa" = 5,
  "Fax" = 6,
}

export enum CoverageOrderType {
  "PRIMARY" = 1,
  "SECONDARY" = 2,
  "TERTIARY" = 3,
}

export enum RelationshipToCardholderType {
  "SELF" = 1,
  "SPOUSE" = 2,
  "PARENT" = 3,
  "CHILD" = 4,
  "UNKNOWN" = 5,
  "OTHER RELATIONSHIP" = 6,
}

export enum MedicationRouteType {
  "INHALE" = 1,
  "SWALLOW" = 2,
  "SPRAY" = 3,
  "INJECT" = 4,
  "INFUSE" = 5,
  "RINSE" = 6,
}
