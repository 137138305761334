import { useNavigate, useParams } from "react-router-dom";
import GeneralFormContainer from "components/general-form-containner";
import patient from "api/patient";
import PatientForm from ".";
import { Card } from "antd";
import { useMutation } from "react-query";
import MemberPlanPage from "pages/member-plan";

const PatientDetailsPage = () => {
  const { id: patientId } = useParams();
  const navigate = useNavigate();

  const updatePatientMutation = useMutation(patient.Update);

  return (
    <Card title={<h1 className="text-primary"> Patient Details </h1>}>
      <GeneralFormContainer
        dataName="Patient Details"
        type="Update"
        actionLoading={updatePatientMutation.isLoading}
        onSubmit={(data: any) => {
          updatePatientMutation.mutate(data);
        }}
        toggleContent={() => navigate(-1)}
        // cancelButtonLabel="Cancel"
        // onCancel={() =>  navigate(-1)}
        detailsMapper={(data: any) => {
          return {
            ...data,
          };
        }}
        getFunc={() => patient.Get({ id: Number(patientId) })}
        content={PatientForm}
      />
      <MemberPlanPage />
    </Card>
  );
};

export default PatientDetailsPage;
