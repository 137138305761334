import { FunctionComponent } from "react";
import { useQuery } from "react-query";

interface DocumentationProps {
  id?: number;
}

const Documentation: FunctionComponent<DocumentationProps> = ({ id }) => {
  const docsQuery = useQuery(
    "documentaion",
    // () => documentation.getDocumentations(id ? id : 0),
    () => {},
  );
  const description = () => ({
    // __html: DOMPurify.sanitize(docsQuery.data?.content ?? ''),
  });
  return (
    <>
      {/* <h1>{docsQuery.data?.Title}</h1>
            <div dangerouslySetInnerHTML={description()}></div> */}
    </>
  );
};

export default Documentation;
