import "styles/input/index.scss";
import "styles/uploadFile/index.scss";
import { Modal, Upload, UploadFile as AntdUploadFile } from "antd";
import { FunctionComponent, useState } from "react";
import { motion, useAnimation } from "framer-motion";
import { Controller } from "react-hook-form";
import _ from "lodash";
import { ExclamationCircleOutlined } from "@ant-design/icons";
import { UploadCloud } from "react-feather";
import { defaultTranstion } from "constants/framer";
import config from "api/config";
import Cookies from "js-cookie";
import BasicModal from "components/modal";
import FromElemnetWrapper from "components/form-element-wrapper";

interface UploadFileProps {
  name: string;
  control: any;
  rules?: any;
  label: string;
  disabled?: boolean;
  multiple?: boolean;
  accept?: string;
  refType?: number;
  maxCount?: number;
}
const UploadFile: FunctionComponent<UploadFileProps> = ({
  name,
  control,
  rules,
  label,
  disabled,
  multiple,
  accept,
  refType,
  maxCount,
}) => {
  const focusController = useAnimation();
  const errorController = useAnimation();
  const [previewVisible, setpreviewVisible] = useState(false);
  const [previewImage, setpreviewImage] = useState("");

  const handleCancel = () => setpreviewVisible(false);
  const handlePreview = async (file: any) => {
    const fileExtension = file.url.split(".").pop().toLowerCase();

    // Check the type
    if (
      ["jpg", "jpeg", "png", "gif", "bmp", "svg", "webp"].includes(
        fileExtension
      )
    ) {
      setpreviewImage(file.url);
      setpreviewVisible(true);
    } else {
      window.open(file.url);
    }
  };

  const onGalleryFileRemove = (_file: AntdUploadFile<any>) => {
    const { confirm } = Modal;

    return new Promise<boolean>((resolve, reject) => {
      confirm({
        title: (
          <h3 style={{ marginLeft: "3rem" }}>Confirmation Delete Picture</h3>
        ),
        icon: <ExclamationCircleOutlined style={{ float: "right" }} />,
        onOk: () => {
          resolve(true);
        },
        onCancel: () => {
          reject(true);
        },
        okButtonProps: {
          style: {
            marginRight: "5rem",
          },
        },
      });
    });
  };

  return (
    <>
      <FromElemnetWrapper
        focusController={focusController}
        errorController={errorController}
        label={label}
        required={rules.required?.value}
      >
        <Controller
          control={control}
          name={name}
          rules={rules}
          render={({
            field: { onChange, onBlur, value, name, ref },
            fieldState: { invalid, error },
          }) => {
            if (invalid) {
              errorController.start({ scale: 80 });
            } else {
              errorController.start(
                { scale: 0 },
                { ease: defaultTranstion.ease.reverse() }
              );
            }
            return (
              <div
                onBlur={() => {
                  onBlur();
                  focusController.start({ scale: 0 });
                }}
                onFocus={() => {
                  focusController.start({ scale: 80 });
                }}
                className="relative"
              >
                <div className="upload-container">
                  <form
                    className="dropzone needsclick"
                    id="demo-upload"
                    action="/upload"
                  >
                    <Upload
                      action={`${config.baseUrl}Attachment/Upload`}
                      headers={{
                        Authorization: `Bearer ${Cookies.get("CPI_TOKEN")}`,
                      }}
                      ref={ref}
                      multiple={multiple}
                      maxCount={maxCount}
                      disabled={disabled}
                      data={{ RefType: refType }}
                      listType="picture"
                      fileList={
                        Array.isArray(value)
                          ? value
                          : value
                          ? [value]
                          : undefined
                      }
                      id={name}
                      accept={accept}
                      onPreview={handlePreview}
                      onChange={(e) => onChange(e.fileList)}
                      onRemove={(file) => onGalleryFileRemove(file)}
                    >
                      <div className="upload-button">
                        <div className="wrapper">
                          <motion.div
                            className="fas fa-angle-double-up"
                            whileHover={{
                              y: [0, -2, 2, 0],
                              transition: {
                                duration: 1.5,
                                ease: "easeInOut",
                                yoyo: Infinity,
                              },
                            }}
                          >
                            <UploadCloud
                              style={{
                                margin: ".2rem",
                                display: "inline-block",
                              }}
                              color="white"
                              size={20}
                            />
                            Upload
                          </motion.div>
                        </div>
                      </div>
                    </Upload>

                    {_.isEmpty(value) && (
                      <div className="dz-message needsclick">
                        Drop Files Here
                      </div>
                    )}
                    <BasicModal
                      isOpen={previewVisible}
                      header={<h2>Preview Image</h2>}
                      footer={false}
                      handleCancel={handleCancel}
                      content={
                        <img
                          alt="example"
                          style={{ width: "100%" }}
                          src={previewImage}
                        />
                      }
                    />
                  </form>
                </div>
                {invalid && (
                  <p className="form-element-error">{error?.message}</p>
                )}
              </div>
            );
          }}
        />
      </FromElemnetWrapper>
    </>
  );
};

export default UploadFile;
