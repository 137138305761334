import config from "./config";
import ApiService from "./baseApi/api-service";
class User extends ApiService {
  constructor() {
    super({ baseURL: config.baseUrl, headers: config.headers });
  }

  /* @tags User */
  /* @name UserGet */
  /* @request GET:/api/services/app/User/Get */
  public Get = (params: { id?: number }): Promise<any> => {
    return this.get("/User/Get", {
      params,
    });
  };

  /* @tags User */
  /* @name UserGetAll */
  /* @request GET:/api/services/app/User/GetAll */
  public getAll = (params: {
    page?: number;
    search?: string;
    page_size?: string;
  }): Promise<any> => {
    return this.get("/User/GetAll", {
      params,
    });
  };

  /* @tags User */
  /* @name inputOutputCreate */
  /* @request POST:/api/services/app/User/Create */
  public Create = (params: {}): Promise<any> => {
    return this.post("/User/Create/", {
      ...params,
    });
  };

  /* @tags User */
  /* @name inputOutputUpdate */
  /* @request PUT:/api/services/app/User/Update */
  public Update = (params: { id?: number }): Promise<any> => {
    return this.put(`/User/Update`, {
      ...params,
    });
  };

  /* @tags User */
  /* @name inputOutputDelete */
  /* @request POST:/api/services/app/User/Delete */
  public Delete = (params: { id: number }): Promise<any> => {
    return this.delete(`/User/Delete`, { params });
  };
}
const user = new User();
export default user;
