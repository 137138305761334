import "styles/input/index.scss";
import "styles/select/index.scss";
import { Select as AntdSelect } from "antd";
import { FunctionComponent } from "react";
import { useAnimation } from "framer-motion";
import {
  defaultTranstion,
  maxBackgroundWidth,
  minBackgroundWidth,
} from "constants/framer";
import { Controller } from "react-hook-form";
import FromElemnetWrapper from "components/form-element-wrapper";
import { ValueLabelDto } from "models/baseModels/value-label";
import { DefaultOptionType } from "antd/es/select";

interface InputProps {
  name: string;
  control: any;
  rules?: any;
  allowClear?: boolean;
  options?: ValueLabelDto[] | undefined;
  placeholder?: string;
  label: string;
  labelSide?: string;
  multiple?: boolean;
  disabled?: boolean;
  defaultValue?: number | any;
  loading?: boolean;
  onChange?: (value: any, option: DefaultOptionType) => void;
}
const Select: FunctionComponent<InputProps> = ({
  name,
  control,
  rules,
  placeholder,
  label,
  labelSide,
  options,
  allowClear,
  multiple,
  disabled,
  defaultValue,
  loading,
  onChange: onPropsChange,
}) => {
  const focusController = useAnimation();
  const errorController = useAnimation();
  return (
    <>
      <FromElemnetWrapper
        required={rules.required?.value}
        focusController={focusController}
        errorController={errorController}
        label={label}
        labelSide={labelSide}
      >
        <Controller
          control={control}
          name={name}
          rules={rules}
          defaultValue={defaultValue}
          render={({
            field: { onChange, onBlur, value, name, ref },
            fieldState: { invalid, isTouched, isDirty, error },
          }) => {
            if (invalid) {
              errorController.start(maxBackgroundWidth);
            } else {
              errorController.start(minBackgroundWidth, {
                ease: defaultTranstion.ease.reverse(),
              });
            }
            return (
              <div className="relative">
                <AntdSelect
                  showSearch
                  onFocus={() => {
                    focusController.start(maxBackgroundWidth);
                  }}
                  aria-label={label}
                  options={options}
                  allowClear={allowClear}
                  onChange={(v, option) => {
                    onPropsChange && onPropsChange(v, option);
                    onChange(v);
                  }}
                  // value={value ? value : defaultValue}
                  value={value}
                  defaultValue={defaultValue}
                  ref={ref}
                  id={name}
                  mode={multiple ? "multiple" : undefined}
                  className={
                    invalid ? "ant-custom-input-error" : "ant-custom-input"
                  }
                  onBlur={() => {
                    onBlur();
                    focusController.start({
                      width: 0,
                      height: 0,
                    });
                  }}
                  filterOption={(input, option: any) =>
                    option?.label.toLowerCase().indexOf(input?.toLowerCase()) >=
                    0
                  }
                  placeholder={placeholder}
                  disabled={disabled}
                  loading={loading}
                />
                {invalid && (
                  <p className="form-element-error">{error?.message}</p>
                )}
              </div>
            );
          }}
        />
      </FromElemnetWrapper>
    </>
  );
};

export default Select;
