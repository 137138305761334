import { Button, Card, Col, Row, Tag, Tooltip } from "antd";
import { Typography } from "antd";
import "./assesment.scss";
import { useState } from "react";
import { CaretDownOutlined, CaretUpOutlined } from "@ant-design/icons";
import { Radar } from "react-chartjs-2";
import "chart.js/auto";
import pa from "api/pa";
import { notify } from "components/notification";
import { useMutation } from "react-query";
import { BiologicType } from "constants/enums";
import { useParams } from "react-router-dom";
import colors from "constants/colors";
import LableColor from "components/lable-color";
import TableHoverCriterion from "./table-hover-criterion";

const StepFive = ({ data, assessmentId, getAssessment }: any) => {
  const [toggle, setToggle] = useState<boolean[]>([true]);
  const { id } = useParams();

  const handleToggle = (index: number) => {
    setToggle((prevToggle) => {
      const newToggle = [...prevToggle];
      newToggle[index] = !newToggle[index];
      return newToggle;
    });
  };

  const RadarOptions: any = {
    scales: {
      r: {
        ticks: {
          beginAtZero: true,
        },
      },
    },
  };

  const paCreateMutation = useMutation(pa.paCreate, {
    onSuccess: () => {
      notify("success", "ok", "Success", "PA generation");
    },
  });

  const RadarData = (biologic: any) => {
    return {
      // labels: [
      //   "Age",
      //   "Severity/Phenotype",
      //   "BEC",
      //   "FeNo",
      //   "Adherence",
      //   "Background Therapy",
      //   "OCS",
      //   "Exacerbations",
      //   "Asthma Control",
      //   "FEV1",
      // ],
      labels: Object.entries(biologic?.patientScores).map(
        ([key, value]: any) => key
      ),
      datasets: [
        {
          label: "Patient",
          fill: true,
          backgroundColor: colors.semiPurple,
          borderColor: colors.purple,
          pointBackgroundColor: colors.purple,
          pointBorderColor: colors.white,
          pointHoverBackgroundColor: colors.white,
          pointHoverBorderColor: colors.purple,
          // data: [65, 59, 90, 30, 70, 60, 81, 56, 55, 40],
          data: Object.entries(biologic?.patientScores).map(
            ([key, value]: any) => value
          ),
        },
        {
          label: "Accessibility",
          fill: true,
          backgroundColor: colors.semiBlue,
          borderColor: colors.blue,
          pointBackgroundColor: colors.blue,
          pointBorderColor: colors.white,
          pointHoverBackgroundColor: colors.white,
          pointHoverBorderColor: colors.blue,
          // data: [28, 48, 40, 40, 30, 20, 19, 96, 27, 90],
          // data: medication_bacs
          //   ?.slice(0, -1)
          //   .map((medication_bacs: any) => medication_bacs.medication_score),
          data: Object.entries(biologic?.biologicScores).map(
            ([key, value]: any) => value
          ),
        },
      ],
    };
  };

  return (
    <div className="one-step">
      {data?.patientBiologicScoresList?.map((biologic: any, index: number) => (
        <div key={index}>
          <Card size="small" className="mt-10">
            <Row
              gutter={{ xs: 8, sm: 16, md: 24, lg: 32 }}
              justify="space-around"
              align="middle"
            >
              <Col className="gutter-row text-center" span={8}>
                <Row justify="space-around" align="middle">
                  <Col>
                    <img
                      src={biologic.biologicUrl}
                      style={{ width: "100px" }}
                    />
                  </Col>
                  <Col className="gutter-row text-center">
                    <Typography.Title level={5} style={{ margin: 0 }}>
                      Biologic Spec: {biologic.biologicTotalScore}
                    </Typography.Title>
                  </Col>
                </Row>
              </Col>

              <Col className="gutter-row text-center" span={5}>
                <Typography.Title level={5} style={{ margin: 0 }}>
                  {`Total Score: ${biologic.patientTotalScore}`}
                </Typography.Title>
              </Col>
              <Col className="gutter-row text-center" span={4}>
                <Typography.Title level={5} style={{ margin: 0 }}>
                  Preference {biologic?.order}
                </Typography.Title>
              </Col>
              <Col className="gutter-row text-center" span={6}>
                <Button
                  type="primary"
                  onClick={() => {
                    paCreateMutation.mutate({
                      assessmentId: id,
                      biologic: biologic.biologic,
                    });
                  }}
                >
                  Request PA
                </Button>
                <Button
                  onClick={() => handleToggle(index)}
                  className="mx-2"
                  icon={
                    toggle[index] ? <CaretDownOutlined /> : <CaretUpOutlined />
                  }
                />
              </Col>
            </Row>
          </Card>

          <div hidden={!toggle[index]}>
            <Card className="mt-2">
              <Row justify="space-between" align="middle">
                <Col
                  className="gutter-row"
                  style={{ height: "400px" }}
                  span={10}
                >
                  <p className="ml-15 font-bold text-red-400">
                    {" "}
                    Click on color 👇 to show/hide chart 👇
                  </p>
                  <Radar data={RadarData(biologic)} options={RadarOptions} />
                </Col>
                <Col className="gutter-row" span={14}>
                  <Row justify="space-between" className="mb-5">
                    {LableColor("Fully meets", colors.green)}
                    {LableColor("Mostly meets", colors.semiGreen)}
                    {LableColor("Partially meets", colors.yellow)}
                    {LableColor("Slightly meets", colors.orange)}
                    {LableColor("Does not meet", colors.dangerColor)}
                  </Row>
                  <Row>
                    <Col span={7}>
                      <Tag
                        className="tag-step-five header"
                        color={colors.black}
                      >
                        Criterion
                      </Tag>
                    </Col>
                    <Col span={8}>
                      <Tag
                        className="tag-step-five header"
                        color={colors.purple}
                      >
                        Patient Scores
                      </Tag>
                    </Col>
                    <Col span={8}>
                      <Tag className="tag-step-five header" color={colors.blue}>
                        Biologic Specs
                      </Tag>
                    </Col>
                  </Row>
                  {Object.entries(biologic?.patientScores).map(
                    ([key, value]: any) => (
                      <Row className="tagContent-step-five">
                        <Col span={7}>
                          <Tooltip
                            overlayClassName="tooltip-table"
                            title={
                              // <TableHoverCriterion
                              //   criteria={medication_bacs.criteria}
                              // />
                              <TableHoverCriterion
                                criteria={key}
                                getAssessment={getAssessment}
                              />
                            }
                          >
                            <Tag className="tag-step-five">{key} </Tag>
                          </Tooltip>
                        </Col>
                        <Col span={8}>
                          <Tag
                            className="tag-step-five"
                            color={
                              biologic?.biologicScores[key] == 10
                                ? colors.green
                                : Math.abs(
                                    value - biologic?.biologicScores[key]
                                  ) <= 1
                                ? colors.green
                                : Math.abs(
                                    value - biologic?.biologicScores[key]
                                  ) <= 3
                                ? colors.semiGreen
                                : Math.abs(
                                    value - biologic?.biologicScores[key]
                                  ) <= 5
                                ? colors.yellow
                                : Math.abs(
                                    value - biologic?.biologicScores[key]
                                  ) <= 7
                                ? colors.orange
                                : colors.dangerColor
                            }
                          >
                            {value}
                          </Tag>
                        </Col>
                        <Col span={8}>
                          <Tag className="tag-step-five" color={colors.blue}>
                            {biologic?.biologicScores[key]}
                          </Tag>
                        </Col>
                      </Row>
                    )
                  )}
                  <Row className="tagContent-step-five">
                    <Col span={7}>
                      <Tag className="tag-step-five total">Total</Tag>
                    </Col>
                    <Col span={8}>
                      <Tag className="tag-step-five total">
                        {biologic?.patientTotalScore}
                      </Tag>
                    </Col>
                    <Col span={8}>
                      <Tag className="tag-step-five total">
                        {biologic?.biologicTotalScore}
                      </Tag>
                    </Col>
                  </Row>
                </Col>
              </Row>
            </Card>
          </div>
        </div>
      ))}
    </div>
  );
};
export default StepFive;
