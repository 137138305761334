import { useParams } from "react-router-dom";
import GeneralTable from "components/general-table";
import { attachmentMapper } from "utils/attachment-mapper";
import { paFormColumns } from "./data";
import PaFormForm from "./forms";
import paForm from "api/pa-form";
import { BiologicType } from "constants/enums";

const PaFormPage = () => {
  const { id } = useParams();

  return (
    <GeneralTable
      dataName="PaForm"
      headerTable="Payer PaForms"
      getAllFunc={() => paForm.getAll({ payerId: id })}
      getFunc={paForm.Get}
      delete
      deleteFunc={paForm.Delete}
      create
      createFunc={paForm.Create}
      update
      updateFunc={paForm.Update}
      formContent={PaFormForm}
      tableColumns={paFormColumns}
      tableDataMapper={(rows) => {
        return rows?.map((row: any, index: number) => {
          return {
            ...row,
            biologic: BiologicType[row?.biologic],
          };
        });
      }}
      FromDataMapper={(data: any) => {
        return {
          ...data,
        };
      }}
      FormSubmitMapper={(data: any) => {
        return {
          payerId: Number(id),
          ...data,
          ...attachmentMapper(["attachment"], data, false),
        };
      }}
    />
  );
};
export default PaFormPage;
