import { Button, Col, Row } from "antd";
import pa from "api/pa";
import FormElement from "components/form-element";
import LoaderApp from "components/loader";
import { dateFormatDateCalender } from "constants/index";
import { UpdateStepSixDto } from "models/pa-step";
import moment from "moment";
import { FunctionComponent } from "react";
import { useForm } from "react-hook-form";
import { useMutation, useQuery } from "react-query";

interface IPaStepSixProps {
  paId: number;
}

const PaStepSix: FunctionComponent<IPaStepSixProps> = ({ paId }) => {
  const { control, reset, handleSubmit } = useForm<any>({
    mode: "onBlur",
    shouldFocusError: true,
  });

  const sharedProps = {
    control,
  };

  const getStepSix = useQuery(
    ["getStepSix"],
    () => pa.getStepSix({ id: paId }),
    {
      onSuccess(data) {
        reset({
          ...data,
          submittedDate: data.submittedDate ?? undefined,
        });
      },
    }
  );

  const paUpdateMutation = useMutation(pa.paUpdateStepSix);

  const handleConfirm = (data: UpdateStepSixDto) => {
    paUpdateMutation.mutate({
      ...data,
      id: Number(paId),
    });
  };

  return (
    <LoaderApp loading={getStepSix.isLoading}>
      <Row align="bottom" justify="center">
        <Col span={5}>
          <FormElement
            {...sharedProps}
            label="Submitted Date"
            type="datePicker"
            name="submittedDate"
            defaultValue={moment(new Date()).format(dateFormatDateCalender)}
            required
          />
        </Col>
        <Col span={3}>
          <Button
            type="primary"
            className="mb-3 mt-2"
            onClick={handleSubmit(handleConfirm)}
            loading={paUpdateMutation.isLoading}
          >
            Submission
          </Button>
        </Col>
      </Row>
    </LoaderApp>
  );
};

export default PaStepSix;
