import { Col, Divider, Row } from "antd";
import FormElement from "components/form-element";
import { IFormProps } from "models/baseModels/form-props";

interface StepFourFormProps extends IFormProps {}

const StepFourForm: React.FC<StepFourFormProps> = ({ control, disabled }) => {
  const sharedProps = {
    control,
    disabled,
  };

  return (
    <Row>
      <Divider> Patient Section </Divider>
      <Col span={6}>
        <FormElement
          {...sharedProps}
          label="First Name"
          type="input"
          name="patientSection.fName"
        />
      </Col>
      <Col span={6}>
        <FormElement
          {...sharedProps}
          label="Last Name"
          type="input"
          name="patientSection.lName"
        />
      </Col>
      <Col span={6}>
        <FormElement
          {...sharedProps}
          label="Full Name"
          type="input"
          name="patientSection.fullName"
        />
      </Col>
      <Col span={6}>
        <FormElement
          {...sharedProps}
          label="City"
          type="input"
          name="patientSection.city"
        />
      </Col>
      <Col span={6}>
        <FormElement
          {...sharedProps}
          label="State"
          type="input"
          name="patientSection.state"
          // pattern={inputsPatterns.statePattern}
        />
      </Col>
      <Col span={6}>
        <FormElement
          {...sharedProps}
          label="Zip"
          type="input"
          name="patientSection.zip"
          // pattern={inputsPatterns.zipCodePattern}
        />
      </Col>
      <Col span={6}>
        <FormElement
          {...sharedProps}
          label="Male"
          type="checkbox"
          name="patientSection.male"
        />
      </Col>
      <Col span={6}>
        <FormElement
          {...sharedProps}
          label="Female"
          type="checkbox"
          name="patientSection.female"
        />
      </Col>
      <Col span={6}>
        <FormElement
          {...sharedProps}
          label="Birth Date"
          type="datePicker"
          name="patientSection.birthDate"
        />
      </Col>
      <Col span={6}>
        <FormElement
          {...sharedProps}
          label="Cell Phone"
          type="input"
          name="patientSection.cellPhone"
          // pattern={inputsPatterns.phonePattern}
        />
      </Col>
      <Col span={6}>
        <FormElement
          {...sharedProps}
          label="Home Phone"
          type="input"
          name="patientSection.homePhone"
          // pattern={inputsPatterns.phonePattern}
        />
      </Col>
      <Col span={6}>
        <FormElement
          {...sharedProps}
          label="Home Phone AreaCode"
          type="input"
          name="patientSection.homePhone_AreaCode"
          // pattern={inputsPatterns.phonePattern}
        />
      </Col>
      <Col span={6}>
        <FormElement
          {...sharedProps}
          label="Home Phone LocalNumber"
          type="input"
          name="patientSection.homePhone_LocalNumber"
          // pattern={inputsPatterns.phonePattern}
        />
      </Col>
      <Col span={6}>
        <FormElement
          {...sharedProps}
          label="Address"
          type="input"
          name="patientSection.address"
        />
      </Col>
      <Col span={6}>
        <FormElement
          {...sharedProps}
          label="Email"
          type="input"
          name="patientSection.email"
          otherFormProps={{ type: "email" }}
        />
      </Col>
      <Col span={6}>
        <FormElement
          {...sharedProps}
          label="Weight"
          type="input"
          name="patientSection.weight"
        />
      </Col>
      <Col span={6}>
        <FormElement
          {...sharedProps}
          label="Height"
          type="input"
          name="patientSection.height"
        />
      </Col>

      <Divider> Practitioner Section </Divider>
      <Col span={6}>
        <FormElement
          {...sharedProps}
          label="First Name"
          type="input"
          name="practitionerSection.fName"
        />
      </Col>
      <Col span={6}>
        <FormElement
          {...sharedProps}
          label="Last Name"
          type="input"
          name="practitionerSection.lName"
        />
      </Col>
      <Col span={6}>
        <FormElement
          {...sharedProps}
          label="Full Name"
          type="input"
          name="practitionerSection.fullName"
        />
      </Col>
      <Col span={6}>
        <FormElement
          {...sharedProps}
          label="City"
          type="input"
          name="practitionerSection.city"
        />
      </Col>
      <Col span={6}>
        <FormElement
          {...sharedProps}
          label="State"
          type="input"
          name="practitionerSection.state"
          // pattern={inputsPatterns.statePattern}
        />
      </Col>
      <Col span={6}>
        <FormElement
          {...sharedProps}
          label="Zip"
          type="input"
          name="practitionerSection.zip"
        />
      </Col>
      <Col span={6}>
        <FormElement
          {...sharedProps}
          label="Office Contact Name"
          type="input"
          name="practitionerSection.officeContactName"
        />
      </Col>
      <Col span={6}>
        <FormElement
          {...sharedProps}
          label="City State Zip"
          type="input"
          name="practitionerSection.cityStateZip"
        />
      </Col>
      <Col span={6}>
        <FormElement
          {...sharedProps}
          label="Address"
          type="input"
          name="practitionerSection.address"
        />
      </Col>
      <Col span={6}>
        <FormElement
          {...sharedProps}
          label="Office Phone"
          type="input"
          name="practitionerSection.officePhone"
        />
      </Col>
      <Col span={6}>
        <FormElement
          {...sharedProps}
          label="Cell Phone"
          type="input"
          name="practitionerSection.cellPhone"
        />
      </Col>
      <Col span={6}>
        <FormElement
          {...sharedProps}
          label="Fax"
          type="input"
          name="practitionerSection.fax"
        />
      </Col>
      <Col span={6}>
        <FormElement
          {...sharedProps}
          label="Email"
          type="input"
          name="practitionerSection.email"
        />
      </Col>
      <Col span={6}>
        <FormElement
          {...sharedProps}
          label="Npi"
          type="input"
          name="practitionerSection.Npi"
        />
      </Col>
      <Col span={6}>
        <FormElement
          {...sharedProps}
          label="Dea"
          type="input"
          name="practitionerSection.dea"
        />
      </Col>
      <Col span={6}>
        <FormElement
          {...sharedProps}
          label="Specialty"
          type="input"
          name="practitionerSection.specialty"
        />
      </Col>

      <Divider> Member Section </Divider>
      <Col span={6}>
        <FormElement
          {...sharedProps}
          label="Primary Insurance"
          type="input"
          name="memberSection.primaryInsurance"
        />
      </Col>
      <Col span={6}>
        <FormElement
          {...sharedProps}
          label="MemberId"
          type="input"
          name="memberSection.memberId"
        />
      </Col>
      <Col span={6}>
        <FormElement {...sharedProps} label="Group" type="input" name="group" />
      </Col>
      <Col span={6}>
        <FormElement
          {...sharedProps}
          label="Secondary Insurance"
          type="input"
          name="memberSection.secondaryInsurance"
        />
      </Col>
      <Col span={6}>
        <FormElement
          {...sharedProps}
          label="Secondary MemberId"
          type="input"
          name="memberSection.secondaryMemberId"
        />
      </Col>
      <Col span={6}>
        <FormElement
          {...sharedProps}
          label="MedicareId"
          type="input"
          name="memberSection.medicareId"
        />
      </Col>
      <Col span={6}>
        <FormElement
          {...sharedProps}
          label="MedicaidId"
          type="input"
          name="memberSection.medicaidId"
        />
      </Col>

      <Divider> Clinical Section </Divider>
      <Col span={6}>
        <FormElement
          {...sharedProps}
          label="IgE"
          type="input"
          name="clinicalSection.igE"
        />
      </Col>
      <Col span={6}>
        <FormElement
          {...sharedProps}
          label="Bec"
          type="input"
          name="clinicalSection.bec"
        />
      </Col>

      <Divider> Requested Medication Section </Divider>
      <Col span={6}>
        <FormElement
          {...sharedProps}
          label="IcD10"
          type="input"
          name="requestedMedicationSection.icD10"
        />
      </Col>
      <Col span={6}>
        <FormElement
          {...sharedProps}
          label="Diagnosis"
          type="input"
          name="requestedMedicationSection.diagnosis"
        />
      </Col>
      <Col span={6}>
        <FormElement
          {...sharedProps}
          label="Diagnosis ICD10"
          type="input"
          name="requestedMedicationSection.diagnosis_ICD10"
        />
      </Col>
      <Col span={6}>
        <FormElement
          {...sharedProps}
          label="Directions"
          type="input"
          name="requestedMedicationSection.directions"
        />
      </Col>
      <Col span={6}>
        <FormElement
          {...sharedProps}
          label="Dose"
          type="input"
          name="requestedMedicationSection.dose"
        />
      </Col>
      <Col span={6}>
        <FormElement
          {...sharedProps}
          label="Quantity"
          type="input"
          name="requestedMedicationSection.quantity"
        />
      </Col>
      <Col span={6}>
        <FormElement
          {...sharedProps}
          label="Dose Quantity"
          type="input"
          name="requestedMedicationSection.doseQuantity"
        />
      </Col>
      <Col span={6}>
        <FormElement
          {...sharedProps}
          label="Strength"
          type="input"
          name="requestedMedicationSection.strength"
        />
      </Col>
      <Col span={6}>
        <FormElement
          {...sharedProps}
          label="Duration"
          type="input"
          name="requestedMedicationSection.duration"
        />
      </Col>
      <Col span={6}>
        <FormElement
          {...sharedProps}
          label="Allergies"
          type="input"
          name="requestedMedicationSection.allergies"
        />
      </Col>
      <Col span={6}>
        <FormElement
          {...sharedProps}
          label="Initial"
          type="input"
          name="requestedMedicationSection.initial"
        />
      </Col>
      <Col span={6}>
        <FormElement
          {...sharedProps}
          label="Renewal"
          type="input"
          name="requestedMedicationSection.renewal"
        />
      </Col>
      <Divider />
    </Row>
  );
};

export default StepFourForm;
