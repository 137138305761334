import {
  BiologicType,
  OutComeType,
  RequestType,
  StatusType,
} from "constants/enums";
import DetailsIcon from "assets/svgs/general-table/details.svg";
import { currentIndexStepPaAtom, paAtom } from "atoms/atoms";
import { patientColumns } from "./data";
import { useRecoilState } from "recoil";
import { useNavigate } from "react-router-dom";
import pa from "api/pa";
import GeneralTable from "components/general-table";

export default function PaPage() {
  const [_, setPaAtom] = useRecoilState(paAtom);
  const [_c, setCurrentIndexStep] = useRecoilState(currentIndexStepPaAtom);
  const navigate = useNavigate();

  return (
    <GeneralTable
      headerTable="Prior Authorization Requests"
      dataName="PA"
      getAllFunc={pa.getAllPa}
      delete
      deleteFunc={pa.paDelete}
      tableColumns={patientColumns}
      customOnRowClick={(row) => {
        setPaAtom(row);
        setCurrentIndexStep(row?.currentStep);
        navigate(`/auth/pa/${row.id}/${row.biologic}/`);
      }}
      tableDataMapper={(rows) => {
        return rows?.map((row: any, index: number) => {
          return {
            ...row,
            patient: row?.patient?.name,
            practitioner: row?.practitioner?.label,
            biologic: BiologicType[row?.biologic],
            status: StatusType[row?.currentStep],
            outCome: OutComeType[row?.outCome],
            requestType: RequestType[row?.requestType],
          };
        });
      }}
      customActions={[
        {
          Icon: <img src={DetailsIcon} className="icon-button-table" />,
          cb: (row) => {
            setPaAtom(row);
            setCurrentIndexStep(row?.currentStep);
            navigate(`/auth/pa/${row.id}/${row.biologic}/`);
          },
          tooltip: "PA Dashboard",
        },
      ]}
    />
  );
}
