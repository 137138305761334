import { Col, Divider, Row } from "antd";
import FormElement from "components/form-element";
import {
  BenefitType,
  CoverageOrderType,
  MedicareCoverageType,
  PlanType,
  RelationshipToCardholderType,
  ServiceType,
} from "constants/enums";
import _ from "lodash";
import { Controller, useFieldArray } from "react-hook-form";
import AntCollapse from "components/collapse";
import { mapEnumToSelectValues } from "utils/map-enum";
import { IFormProps } from "models/baseModels/form-props";
import plan from "api/plan";
import { useQuery } from "react-query";

interface PlanFormProps extends IFormProps {}

const PlanForm: React.FC<PlanFormProps> = ({ control, disabled }) => {
  const sharedProps = {
    control,
    disabled,
  };

  const getAllPlan = useQuery(["plan"], () => plan.getAllLite({}));

  return (
    <Row>
      <Col span={12}>
        <FormElement
          {...sharedProps}
          label="Plan"
          type="select"
          name="planId"
          options={getAllPlan?.data?.items}
          required
        />
      </Col>
      <Col span={12}>
        <FormElement
          {...sharedProps}
          label="Member Id"
          type="input"
          name="memberId"
        />
      </Col>
      <Col span={12}>
        <FormElement {...sharedProps} label="Group" type="input" name="group" />
      </Col>
      <Col span={12}>
        <FormElement
          {...sharedProps}
          label="Rx Bin"
          type="input"
          name="rxBin"
        />
      </Col>
      <Col span={12}>
        <FormElement
          {...sharedProps}
          label="Rx PCN"
          type="input"
          name="rxPCN"
        />
      </Col>
      <Col span={12}>
        <FormElement
          {...sharedProps}
          label="Rx Group"
          type="input"
          name="rxGroup"
        />
      </Col>
      <Col span={12}>
        <FormElement
          {...sharedProps}
          label="Coverage Order"
          type="select"
          name="coverageOrder"
          options={mapEnumToSelectValues(CoverageOrderType)}
          required
        />
      </Col>
      <Col span={12}>
        <FormElement
          {...sharedProps}
          label="Relationship To Cardholder"
          type="select"
          name="relationshipToCardholder"
          options={mapEnumToSelectValues(RelationshipToCardholderType)}
          required
        />
      </Col>
      <Col span={12}>
        <FormElement
          {...sharedProps}
          label="Effective Date"
          type="datePicker"
          name="effectiveDate"
          required
        />
      </Col>
      <Col span={12}>
        <FormElement
          {...sharedProps}
          label="Expiration Date"
          type="datePicker"
          name="expirationDate"
          required
        />
      </Col>
    </Row>
  );
};

export default PlanForm;
