import { Button, Col, Row } from "antd";
import _ from "lodash";
// import 'styles/button/index.scss';
import { useEffect } from "react";
import { FieldValues, useForm } from "react-hook-form";
import { useQuery } from "react-query";
import { IFormOperation } from "models/baseModels/form-operation";
import { DevTool } from "@hookform/devtools";
import LoaderApp from "components/loader";
/* eslint-disable react-hooks/exhaustive-deps */

export interface IFormError {
  path: any;
  message: string;
}
export interface GeneralSideFormProps<IForm> {
  type?: IFormOperation; //the type of the selected form
  defaultValues?: any; //the default values for the react hook form
  routeName?: string; // the key for the name to be added after the headers
  otherFormProps?: any; //any other props you want to be passed to the form element
  selectedId?: number | string; //the data id row the user clicked the action on
  content?: any; //the content that would appear in the sidebar or the page 2
  toggleContent?: (state: boolean) => void; //toogles the form
  onSubmit?: (data: any) => void; //gets called only when the page 2 save button is clicked
  isDisabled?: boolean;
  customAction?: () => void;
  submitButtonLabel?: any;
  actionLoading?: boolean;
  getFunc?: (query: any) => Promise<any>;
  dataName: string;
  detailsMapper?: (data: any) => any;
  disableCancelButton?: boolean;
  getParams?: { [params: string]: string | undefined };
  errors?: IFormError[];
  disableDeleteButton?: boolean;
  disabledCancelButton?: boolean;
  onDelete?: (data: any) => void; //gets called only when the page 2 delete button is clicked
  onCancel?: (data: any) => void; //gets called only when Stop processing
  deleteButtonLabel?: any;
  cancelButtonLabel?: any;
}

function GeneralFormContainer<IForm extends FieldValues>(
  props: GeneralSideFormProps<IForm>
) {
  /* .......... Form Hook .......... */
  const {
    formState: { errors, isDirty },
    control,
    reset,
    resetField,
    handleSubmit,
    watch,
    setValue,
    setError,
    clearErrors,
  } = useForm<IForm>({
    mode: "onChange",
    reValidateMode: "onChange",
    defaultValues: props.defaultValues,
    shouldFocusError: true,
  });

  useEffect(() => {
    if (_.isEmpty(props.errors)) clearErrors();

    props.errors?.forEach((error) => {
      setError(error.path, {
        type: "custom",
        message: error.message,
      });
    });

    return () => {
      clearErrors();
    };
  }, [props.errors]);

  useEffect(() => {
    return () => {
      reset();
      getDataHook.remove();
    };
  }, []);

  const getDataHook = useQuery(
    [`${props.dataName}${props.type}`, props.selectedId, props.getParams?.Id],
    () =>
      props.selectedId !== 0 &&
      props.type !== "Create" &&
      props.getFunc &&
      props.getFunc({ id: props.selectedId, ...props.getParams }),
    {
      // refetchOnWindowFocus: false,
      refetchOnMount: true,
      enabled: props.type !== "Create" && props.getFunc !== undefined,
      onSuccess: (data) => {
        reset(props.detailsMapper ? props.detailsMapper(data) : data);
      },
    }
  );

  /* ............................... */
  /* ......... components .......... */
  /* ............................... */

  // AnalysisDetails
  /* actions for the side or page 2 */
  const Footer = (
    <Row align="middle" justify="space-between" className="mt-5">
      <Col>
        {props.type !== "Details" && props.onSubmit && (
          <Button
            onClick={handleSubmit(props.onSubmit)}
            className={
              props?.type === "Update" && !isDirty ? "disabledbtn" : "savebtn"
            }
            // disabled={ props.actionLoading|| !isDirty}
            disabled={props?.type === "Update" && !isDirty}
            style={{
              cursor: Object.keys(errors).length !== 0 ? "not-allowed" : "",
            }}
            loading={props.actionLoading}
          >
            {props.submitButtonLabel ? props.submitButtonLabel : "Save"}
          </Button>
        )}
        {props.deleteButtonLabel &&
          !props.disableDeleteButton &&
          props.onDelete && (
            <Button
              onClick={props.onDelete}
              className="m-1 cancelbtn"
              type="default"
            >
              {props.deleteButtonLabel}
            </Button>
          )}

        {props.cancelButtonLabel &&
          !props.disableCancelButton &&
          props.onCancel && (
            <Button
              onClick={props.onCancel}
              className="m-1 cancelbtn"
              type="default"
            >
              {props.cancelButtonLabel}
            </Button>
          )}
      </Col>

      <Col>
        {!props.disableCancelButton && props.toggleContent && (
          <Button
            onClick={() => {
              reset(props.defaultValues);
              props.toggleContent && props.toggleContent(false);
            }}
            className="m-1 cancelbtn"
            type="default"
          >
            Cancel
          </Button>
        )}
      </Col>
    </Row>
  );
  /*  the header for the sidebar and the second page */
  if (!getDataHook.isLoading) {
    return (
      <div
        id={props.dataName}
        className="d-flex flex-column justify-content-between align-items-start h-100 w-100"
        style={{ padding: "1rem" }}
      >
        <props.content
          data={watch()}
          selectedId={props.selectedId}
          {...props.otherFormProps}
          control={control}
          disabled={props.actionLoading || props.type === "Details"}
          setValue={setValue}
          handleSubmit={handleSubmit(
            props.onSubmit ? props.onSubmit : () => {}
          )}
          toggleContent={props.toggleContent}
          type={props.type}
          actionLoading={props.actionLoading}
          reset={reset}
          resetField={resetField}
        />
        <DevTool control={control} />
        {Footer}
      </div>
    );
  } else {
    return (
      <Row justify="center" className="w-full h-128 text-center">
        <LoaderApp />
      </Row>
    );
  }
}
export default GeneralFormContainer;
