import { FunctionComponent } from "react";
import _ from "lodash";
import moment from "moment";
import { useRecoilState } from "recoil";
import { currentIndexStepAtom } from "atoms/atoms";
import assessment from "api/assessment";
import { useNavigate, useParams } from "react-router-dom";
import colors from "constants/colors";
import GeneralTable from "components/general-table";
import { dateFormatMonth } from "constants/index";
import { AssessmentDto } from "models/assessment";
import { assessmentColumns } from "./data";
import AssessmentForm from "./forms/index";

interface IAssesmentModle {
  patientId?: number;
  setIsOpenAssesment: (item: boolean) => void;
}

const AssessmentPage: FunctionComponent<IAssesmentModle> = ({
  patientId,
  setIsOpenAssesment,
}) => {
  const [_c, setCurrentIndexStep] = useRecoilState(currentIndexStepAtom);
  const navigate = useNavigate();
  const { id: assessmentId } = useParams();

  return (
    <GeneralTable
      dataName="Assessment"
      getAllFunc={assessment.GetAll}
      getAllParams={{ patientId }}
      delete
      deleteFunc={assessment.Delete}
      tableColumns={assessmentColumns}
      create
      createFunc={(params) =>
        assessment.Create({ ...params, patientId: patientId })
      }
      // customCreateAction={() => {
      //   createAssessment.mutate({ patient: patientId });
      // }}
      formContent={AssessmentForm}
      customOnRowClick={(row: AssessmentDto) => {
        navigate(`/auth/assessment/${row.id}/${row?.patient?.id}`);
        setCurrentIndexStep(row.currentStep);
        setIsOpenAssesment(false);
      }}
      onRow={(row: any) => ({
        style:
          row.id == assessmentId
            ? { backgroundColor: colors.hovercolor }
            : undefined,
      })}
      tableDataMapper={(rows) => {
        return rows?.map((row: any, index: number) => {
          return {
            ...row,
            patientName: row?.patient?.name,
            practitionerName: row?.practitioner?.label,
            date: moment(row.date).format(dateFormatMonth),
            style: { backgroundColor: colors.hovercolor },
          };
        });
      }}
    />
  );
};

export default AssessmentPage;
