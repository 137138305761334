import config from "./config";
import ApiService from "./baseApi/api-service";
import { getAllParams } from "models/baseModels/getAll-params";
class Practitioner extends ApiService {
  constructor() {
    super({ baseURL: config.baseUrl, headers: config.headers });
  }
  /* @tags Practitioner */
  /* @name PractitionerGet */
  /* @request GET:/api/services/app/Practitioner/Get */
  public Get = (params: { id?: number }): Promise<any> => {
    return this.get("/Practitioner/Get", {
      params,
    });
  };

  /* @tags Practitioner */
  /* @name PractitionerGetAll */
  /* @request GET:/api/services/app/Practitioner/GetAll */
  public getAll = (params: getAllParams): Promise<any> => {
    return this.get("/Practitioner/GetAll", {
      params,
    });
  };

  public getAllLite = (params: getAllParams): Promise<any> => {
    return this.get("/Practitioner/GetAllLite", {
      params,
    });
  };

  /* @tags Practitioner */
  /* @name inputOutputCreate */
  /* @request POST:/api/services/app/Practitioner/Create */
  public Create = (params: { assessment: number }): Promise<any> => {
    return this.post("/Practitioner/Create", {
      ...params,
    });
  };

  /* @tags Practitioner */
  /* @name inputOutputUpdate */
  /* @request PUT:/api/services/app/Practitioner/Update */
  public Update = (params: { id?: number }): Promise<any> => {
    return this.put(`/Practitioner/Update`, {
      ...params,
    });
  };

  /* @tags Practitioner */
  /* @name inputOutputDelete */
  /* @request POST:/api/services/app/Practitioner/Delete */
  public Delete = (params: { id: number }): Promise<any> => {
    return this.delete(`/Practitioner/Delete`, { params });
  };
}
const practitioner = new Practitioner();
export default practitioner;
