import moment from "moment";
import _ from "lodash";
import { dateFormatMonth } from "constants/index";

export const dataChart = (cardRecords: any) => {
  let newData = cardRecords?.map((item: any) => [
    new Date(item.date), //col month
    Number(item.value), //row value
    item.statusDto?.color, //color
    `date: ${moment(item.date).format(dateFormatMonth)} \n value: ${
      item.label ?? item.value
    }`, //display on hover
  ]);

  newData = !_.isEmpty(newData) ? [...newData] : [[0, 0, "#fff", "month"]];

  return [
    ["month", "value", { role: "style" }, { role: "tooltip" }],
    ...newData,
  ];
};

export const optionsChart = (cardRecords: any) => {
  return {
    backgroundColor: "#EFEFEF",
    legend: "none",
    pointSize: 7,
    hAxis: {
      title: "Month",
    },
    // vAxis: {
    //   title: "Value",
    // },
    animation: {
      startup: true,
      duration: 500,
    },
    chartArea: {
      left: "8%",
      right: "8%",
      top: "8%",
      width: "80%",
      height: "70%",
    },
  };
};
