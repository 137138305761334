import config from "./config";
import ApiService from "./baseApi/api-service";
class Medication extends ApiService {
  constructor() {
    super({ baseURL: config.baseUrl, headers: config.headers });
  }
  /* @tags Medication */
  /* @name MedicationGet */
  /* @request GET:/api/services/app/Medication/Get */
  public Get = (params: { id?: number }): Promise<any> => {
    return this.get("/Medication/Get", {
      params,
    });
  };

  /* @tags Medication */
  /* @name MedicationGetAll */
  /* @request GET:/api/services/app/Medication/GetAll */
  public getAll = (params: {
    patient_id?: number;
    page?: number;
    search?: string;
    page_size?: string;
  }): Promise<any> => {
    return this.get("/Medication/GetAll", {
      params,
    });
  };

  /* @tags Medication */
  /* @name inputOutputCreate */
  /* @request POST:/api/services/app/Medication/Create */
  public Create = (params: {
    assessment: number;
    biologic: string;
  }): Promise<any> => {
    return this.post("/Medication/Create", {
      ...params,
    });
  };

  /* @tags Medication */
  /* @name inputOutputUpdate */
  /* @request PUT:/api/services/app/Medication/Update */
  public Update = (params: {
    id?: number;
    payer?: string;
    submitted_date?: Date;
    status?: number;
    outcome?: number;
    denial_reason?: number;
  }): Promise<any> => {
    return this.put(`/Medication/Update`, {
      ...params,
    });
  };

  /* @tags Medication */
  /* @name inputOutputDelete */
  /* @request POST:/api/services/app/Medication/Delete */
  public Delete = (params: { id: number }): Promise<any> => {
    return this.delete(`/Medication/Delete`, { params });
  };
}
const medication = new Medication();
export default medication;
