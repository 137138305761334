import config from "./config";
import ApiService from "./baseApi/api-service";
class MemberPlan extends ApiService {
  constructor() {
    super({ baseURL: config.baseUrl, headers: config.headers });
  }
  /* @tags MemberPlan */
  /* @name PaGet */
  /* @request GET:/api/services/app/MemberPlan/Get */
  public Get = (params: { id?: number }): Promise<any> => {
    return this.get("/MemberPlan/Get", {
      params,
    });
  };

  /* @tags MemberPlan */
  /* @name PaGetAll */
  /* @request GET:/api/services/app/MemberPlan/GetAll */
  public getAll = (params: any): Promise<any> => {
    return this.get("/MemberPlan/GetAll", {
      params,
    });
  };

  /* @tags MemberPlan */
  /* @name inputOutputCreate */
  /* @request POST:/api/services/app/MemberPlan/Create */
  public Create = (params: {}): Promise<any> => {
    return this.post("/MemberPlan/Create", {
      ...params,
    });
  };

  /* @tags MemberPlan */
  /* @name inputOutputUpdate */
  /* @request PUT:/api/services/app/MemberPlan/Update */
  public Update = (params: { id?: number }): Promise<any> => {
    return this.put(`/MemberPlan/Update`, {
      ...params,
    });
  };

  /* @tags MemberPlan */
  /* @name inputOutputDelete */
  /* @request POST:/api/services/app/MemberPlan/Delete */
  public Delete = (params: { id: number }): Promise<any> => {
    return this.delete(`/MemberPlan/Delete`, { params });
  };
}
const memberPlan = new MemberPlan();
export default memberPlan;
