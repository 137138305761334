import { Col, Row } from "antd";
import FormElement from "components/form-element";
import { BiologicType, RefType } from "constants/enums";
import { mapEnumToSelectValues } from "utils/map-enum";
import { IFormProps } from "models/baseModels/form-props";

interface DrugPolicyFormProps extends IFormProps {}

const DrugPolicyForm: React.FC<DrugPolicyFormProps> = ({
  control,
  disabled,
  setValue,
  data,
}) => {
  const sharedProps = {
    control,
    disabled,
  };

  return (
    <Row>
      <Col span={12}>
        <FormElement
          {...sharedProps}
          label="Name"
          type="input"
          name="name"
          required
        />
      </Col>
      <Col span={12}>
        <FormElement
          {...sharedProps}
          label="Biologic"
          options={mapEnumToSelectValues(BiologicType)}
          type="select"
          name="biologic"
          required
        />
      </Col>
      <Col span={24}>
        <FormElement {...sharedProps} label="Link" type="input" name="link" />
      </Col>
      <Col span={24}>
        <FormElement
          {...sharedProps}
          label="Attachment"
          type="uploadFile"
          name="attachment"
          otherFormProps={{ refType: RefType.DRUG_POLICY }}
        />
      </Col>
    </Row>
  );
};

export default DrugPolicyForm;
