import config from "./config";
import ApiService from "./baseApi/api-service";
import {
  DrugPolicyCreateDto,
  DrugPolicyDto,
  DrugPolicyGetAllParamsDto,
  DrugPolicyPageDto,
  DrugPolicyUpdateDto,
} from "models/drug-policy";
class DrugPolicy extends ApiService {
  constructor() {
    super({ baseURL: config.baseUrl, headers: config.headers });
  }
  /* @tags DrugPolicy */
  /* @name PlanGet */
  /* @request GET:/api/services/app/DrugPolicy/Get */
  public Get = (params: { id?: number }): Promise<DrugPolicyDto> => {
    return this.get("/DrugPolicy/Get", {
      params,
    });
  };

  /* @tags DrugPolicy */
  /* @name PlanGetAll */
  /* @request GET:/api/services/app/DrugPolicy/GetAll */
  public getAll = (
    params: DrugPolicyGetAllParamsDto
  ): Promise<DrugPolicyPageDto> => {
    return this.get("/DrugPolicy/GetAll", {
      params,
    });
  };

  /* @tags DrugPolicy */
  /* @name inputOutputCreate */
  /* @request POST:/api/services/app/DrugPolicy/Create */
  public Create = (params: DrugPolicyCreateDto): Promise<DrugPolicyDto> => {
    return this.post("/DrugPolicy/Create", {
      ...params,
    });
  };

  /* @tags DrugPolicy */
  /* @name inputOutputUpdate */
  /* @request PUT:/api/services/app/DrugPolicy/Update */
  public Update = (params: DrugPolicyUpdateDto): Promise<DrugPolicyDto> => {
    return this.put(`/DrugPolicy/Update`, {
      ...params,
    });
  };

  /* @tags DrugPolicy */
  /* @name inputOutputDelete */
  /* @request POST:/api/services/app/DrugPolicy/Delete */
  public Delete = (params: { id: number }): Promise<any> => {
    return this.delete(`/DrugPolicy/Delete`, { params });
  };
}
const drugPolicy = new DrugPolicy();
export default drugPolicy;
