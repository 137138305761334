import "styles/input/index.scss";
import "styles/date-picker/index.scss";
import { TimePicker as AntdTimePicker } from "antd";
import { FunctionComponent, useState } from "react";
import { useAnimation } from "framer-motion";
import {
  defaultTranstion,
  maxBackgroundWidth,
  minBackgroundWidth,
} from "constants/framer";
import { Controller } from "react-hook-form";
import moment from "moment";
import FromElemnetWrapper from "components/form-element-wrapper";
import { timeFormat } from "constants/index";

interface InputProps {
  name: string;
  control: any;
  rules?: any;
  placeholder?: string;
  label: string;
  disabled?: boolean;
  onChange?: (value: any) => void;
}

const TimePicker: FunctionComponent<InputProps> = ({
  name,
  control,
  rules,
  placeholder,
  label,
  disabled,
  onChange: onPropsChange,
}) => {
  const focusController = useAnimation();
  const errorController = useAnimation();

  return (
    <>
      <FromElemnetWrapper
        focusController={focusController}
        errorController={errorController}
        label={label}
        required={rules.required?.value}
      >
        <Controller
          control={control}
          name={name}
          rules={rules}
          render={({
            field: { onChange, onBlur, value, name, ref },
            fieldState: { invalid, error },
          }) => {
            if (invalid) {
              errorController.start(maxBackgroundWidth);
            } else {
              errorController.start(minBackgroundWidth, {
                ease: defaultTranstion.ease.reverse(),
              });
            }
            if (typeof value === "string") {
              value = moment(value);
            }
            return (
              <div className="relative">
                <AntdTimePicker
                  onFocus={() => {
                    focusController.start(maxBackgroundWidth);
                  }}
                  // defaultValue={today}
                  format={timeFormat}
                  value={value}
                  onChange={(v) => {
                    onPropsChange && onPropsChange(v);
                    onChange(v);
                  }}
                  ref={ref}
                  id={name}
                  className={
                    invalid ? "ant-custom-input-error" : "ant-custom-input"
                  }
                  onBlur={() => {
                    onBlur();
                    focusController.start(minBackgroundWidth);
                  }}
                  placeholder={placeholder}
                  disabled={disabled}
                />
                {invalid && (
                  <p className="form-element-error">{error?.message}</p>
                )}
              </div>
            );
          }}
        />
      </FromElemnetWrapper>
    </>
  );
};

export default TimePicker;
