import React from "react";
import { Col, Row } from "antd";
import FormElement from "components/form-element";
import { UserType } from "constants/enums";
import { mapEnumToSelectValues } from "utils/map-enum";
import { inputsPatterns } from "constants/inputs-patterns";
import { IFormProps } from "models/baseModels/form-props";

interface UserFormProps extends IFormProps {}

const UserForm: React.FC<UserFormProps> = ({ control, disabled, type }) => {
  const sharedProps = {
    control,
    disabled,
  };

  return (
    <Row>
      <Col span={12}>
        <FormElement
          {...sharedProps}
          label="First Name"
          type="input"
          name="name"
          required
        />
      </Col>
      <Col span={12}>
        <FormElement
          {...sharedProps}
          label="Last Name"
          type="input"
          name="surname"
          required
        />
      </Col>
      <Col span={12}>
        <FormElement
          {...sharedProps}
          label="User Name"
          type="input"
          name="userName"
          required
        />
      </Col>
      <Col span={12}>
        <FormElement
          {...sharedProps}
          label="Email Address"
          type="input"
          name="emailAddress"
          otherFormProps={{ type: "email" }}
          required
        />
      </Col>
      {type === "Create" && (
        <>
          <Col span={12}>
            <FormElement
              {...sharedProps}
              label="Password"
              type="input"
              name="password"
              pattern={inputsPatterns.password}
              otherFormProps={{ type: "password" }}
              required
            />
          </Col>
          {/* <Col span={12}>
            <FormElement
              {...sharedProps}
              label="Confirm Password"
              type="input"
              name="password"
              otherFormProps={{ type: "password" }}
              required
            />
          </Col> */}
        </>
      )}
      <Col span={12}>
        <FormElement
          {...sharedProps}
          label="Roles"
          type="multiSelect"
          name="roleNames"
          options={mapEnumToSelectValues(UserType)}
          required
        />
      </Col>
    </Row>
  );
};

export default UserForm;
