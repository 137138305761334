import { ObservationUnitType } from "constants/enums";
import { columnsInterface } from "models/baseModels/table-columns";

export const observationColumns: columnsInterface[] = [
  { type: "string", title: "Name", dataSelector: "display" },
  { type: "string", title: "Code", dataSelector: "code" },
  {
    type: "string",
    title: "Value",
    dataSelector: "valueWithUnit",
  },
  { type: "date", title: "Date Issued", dataSelector: "issuedAt" },
];
