import React from "react";
import { Col, Row } from "antd";
import FormElement from "components/form-element";
import { IFormProps } from "models/baseModels/form-props";
import { mapEnumToSelectValues } from "utils/map-enum";
import { MedicationCategoryType, MedicationRouteType } from "constants/enums";

interface MedicationFormProps extends IFormProps {}

const MedicationForm: React.FC<MedicationFormProps> = ({
  control,
  disabled,
}) => {
  const sharedProps = {
    control,
    disabled,
  };

  return (
    <Row>
      <Col span={12}>
        <FormElement
          {...sharedProps}
          label="Name"
          type="input"
          name="name"
          required
        />
      </Col>
      <Col span={12}>
        <FormElement
          {...sharedProps}
          label="Medication Category"
          type="select"
          options={mapEnumToSelectValues(MedicationCategoryType)}
          name="medicationCategory"
          required
        />
      </Col>
      <Col span={12}>
        <FormElement
          {...sharedProps}
          label="Medication Route"
          type="select"
          options={mapEnumToSelectValues(MedicationRouteType)}
          name="medicationRoute"
          required
        />
      </Col>
      <Col span={12}>
        <FormElement
          {...sharedProps}
          label="Response"
          type="input"
          name="responce"
        />
      </Col>
      <Col span={12}>
        <FormElement
          {...sharedProps}
          label="Directions"
          type="input"
          name="directions"
        />
      </Col>
      <Col span={12}>
        <FormElement
          {...sharedProps}
          label="Quantity"
          type="input"
          name="quantity"
        />
      </Col>
      <Col span={12}>
        <FormElement
          {...sharedProps}
          label="Strength"
          type="input"
          name="strength"
        />
      </Col>
      <Col span={12}>
        <FormElement {...sharedProps} label="Dose" type="input" name="dose" />
      </Col>
      <Col span={12}>
        <FormElement {...sharedProps} label="Rate" type="input" name="rate" />
      </Col>
      <Col span={12}>
        <FormElement {...sharedProps} label="Code" type="input" name="code" />
      </Col>

      <Col span={12}>
        <FormElement
          {...sharedProps}
          label="Date Requested"
          type="datePicker"
          name="dateRequested"
        />
      </Col>
      <Col span={12}>
        <FormElement
          {...sharedProps}
          label="Date Asserted"
          type="datePicker"
          name="dateAsserted"
        />
      </Col>
      <Col span={12}>
        <FormElement
          {...sharedProps}
          label="Effective Period Start"
          type="datePicker"
          name="effectivePeriodStart"
        />
      </Col>
      <Col span={12}>
        <FormElement
          {...sharedProps}
          label="Effective Period End"
          type="datePicker"
          name="effectivePeriodEnd"
        />
      </Col>
    </Row>
  );
};

export default MedicationForm;
