import { Col, Row } from "antd";
import FormElement from "components/form-element";
import {
  BenefitType,
  MedicareCoverageType,
  PlanType,
  ServiceType,
} from "constants/enums";
import { mapEnumToSelectValues } from "utils/map-enum";
import { IFormProps } from "models/baseModels/form-props";

interface PlanFormProps extends IFormProps {}

const PlanForm: React.FC<PlanFormProps> = ({ control, disabled }) => {
  const sharedProps = {
    control,
    disabled,
  };
  // Use useFieldArray to handle dynamic fields
  // const { fields, append, remove } = useFieldArray({
  //   control,
  //   name: "pa_forms", // The name must match the array structure in defaultValues
  // });

  // //render Children
  // const renderChildren = (pa_form: any, index: number) => {
  //   return (
  //     <>
  //       <Col span={24}>
  //         <FormElement
  //           {...sharedProps}
  //           label="Link"
  //           type="input"
  //           name={`pa_forms[${index}].link`}
  //         />
  //       </Col>
  //       <Col span={24}>
  //         <FormElement
  //           {...sharedProps}
  //           label="Attachment"
  //           type="uploadFile"
  //           name={`pa_forms[${index}].attachment`}
  //         />
  //       </Col>
  //     </>
  //   );
  // };

  return (
    <Row>
      <Col span={12}>
        <FormElement
          {...sharedProps}
          label="Name"
          type="input"
          name="name"
          required
        />
      </Col>
      <Col span={12}>
        <FormElement
          {...sharedProps}
          label="Plan Type"
          type="select"
          name="planType"
          options={mapEnumToSelectValues(PlanType)}
          required
        />
      </Col>
      <Col span={12}>
        <FormElement
          {...sharedProps}
          label="Benefit Type"
          type="select"
          name="benefitType"
          options={mapEnumToSelectValues(BenefitType)}
          required
        />
      </Col>
      <Col span={12}>
        <FormElement
          {...sharedProps}
          label="Services"
          type="multiSelect"
          name="services"
          options={mapEnumToSelectValues(ServiceType)}
          required
        />
      </Col>
      <Col span={12}>
        <FormElement
          {...sharedProps}
          label="Medicare"
          type="multiSelect"
          name="medicare"
          options={mapEnumToSelectValues(MedicareCoverageType)}
          required
        />
      </Col>
      <Col span={12}>
        <FormElement
          {...sharedProps}
          label="Start Date"
          type="datePicker"
          name="startDate"
          required
        />
      </Col>
      {/* {type === "Update" && (
        <Col span={24}>
          <AntCollapse
            accordion={true}
            items={fields.map((pa_form: any, index) => ({
              key: index,
              label: <strong>{pa_form?.name}</strong>,
              children: renderChildren(pa_form, index),
            }))}
            defaultActiveKey={0}
          />
        </Col>
      )} */}
    </Row>
  );
};

export default PlanForm;
