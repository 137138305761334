import slide from "assets/svgs/sidebar/slide.svg";
import patients from "assets/svgs/sidebar/patient.svg";
import pa from "assets/svgs/sidebar/pa.svg";
import payers from "assets/svgs/sidebar/payer.svg";
import setting from "assets/svgs/sidebar/setting.svg";
import logout from "assets/svgs/sidebar/logout.svg";

export interface IIconsType {
  label: string;
  icon: any;
  link: string;
}

export const sideList: IIconsType[] = [
  {
    label: " ",
    icon: slide,
    link: "/auth/dashboard",
  },
  {
    label: "Patients",
    icon: patients,
    link: "/auth/patients",
  },
  {
    label: "Prior Authorization",
    icon: pa,
    link: "/auth/pa",
  },
  {
    label: "payers",
    icon: payers,
    link: "/auth/payers",
  },
  {
    label: "Setting",
    icon: setting,
    link: "/auth/users",
  },
  {
    label: "Logout",
    icon: logout,
    link: "/logout",
  },
];
