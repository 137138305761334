import React, { useEffect, useState } from "react";
import { Col, Row } from "antd";
import FormElement from "components/form-element";
import condition from "api/condition";
import { IFormProps } from "models/baseModels/form-props";
import { ValueLabelDto } from "models/baseModels/value-label";

interface ConditionFormProps extends IFormProps {}

const ConditionForm: React.FC<ConditionFormProps> = ({
  control,
  disabled,
  setValue,
  data,
}) => {
  const sharedProps = {
    control,
    disabled,
  };

  return (
    <Row>
      <Col span={12}>
        <FormElement
          {...sharedProps}
          label="Display"
          type="selectWithPage"
          name="display"
          defaultValue={data?.search}
          getAllFunc={condition.getAllLite}
          optionDataMapper={(items: any) => {
            return items?.map((item: ValueLabelDto, index: number) => {
              return {
                ...item,
                label: item.label,
                value: item.value,
              };
            });
          }}
          otherFormProps={{ labelInValue: true }}
          onChange={(value, option) => {
            setValue?.("code", option?.value);
          }}
          required
        />
      </Col>
      <Col span={12}>
        <FormElement
          {...sharedProps}
          label="Code"
          type="input"
          name="code"
          disabled
        />
      </Col>
      <Col span={12}>
        <FormElement
          {...sharedProps}
          label="Start Date"
          type="datePicker"
          name="startDate"
          required
        />
      </Col>
      <Col span={12}>
        <FormElement
          {...sharedProps}
          label="End Date"
          type="datePicker"
          name="endDate"
        />
      </Col>
    </Row>
  );
};

export default ConditionForm;
