import { IInputsPattern } from "models/baseModels/input-pattern";
export const inputsPatterns: IInputsPattern = {
  invalid: { value: "", msgKey: "invalidMsg" },
  userName: {
    value: /^[A-Za-z][A-Za-z0-9]*$/,
    msgKey: "userNamePatterMsg",
  },
  name: {
    value:
      /^[\u0621-\u064A\u0660-\u0669A-Za-z0-9_ ][\u0621-\u064A\u0660-\u0669A-Za-z0-9_ ]*$/,
    msgKey: "namePatterMsg",
  },
  password: {
    value: /^[a-zA-Z0-9]+$/,
    msgKey: "passwordPatterMsg",
  },
  phoneNumber: {
    value: /^09[0-9]{8}$/,
    msgKey: "phoneNumberPatterMsg",
  },
  landline: {
    value: /^[0-9]{0,10}$/,
    msgKey: "landlinePatterMsg",
  },
  nationalNumber: {
    value: /^\d{11}$/,
    msgKey: "nationalNumberInfoPatterMsg",
  },
  address: {
    value: /^[\u0621-\u064A\u0660-\u0669A-Za-z0-9_ ]{8,100}$/,
    msgKey: "addressPatterMsg",
  },
  militaryNumber: {
    value: /^\d{7}$/,
    msgKey: "militaryNumberPatterMsg",
  },
  zipCodePattern: {
    value: /^\d{5}(-\d{4})?$/,
    msgKey: "ZipCodePattern",
  },
  statePattern: {
    value:
      /^(A[LKZR]|C[AOT]|D[CE]|F[LM]|G[A]|HI|I[DLNA]|K[SY]|LA|M[DEHINOST]|N[CEDHJMVY]|O[HKR]|P[A]|RI|S[CD]|T[NX]|UT|V[AIT]|W[AIVY])$/,
    msgKey: "ZipCodePattern",
  },
  phonePattern: {
    value: /^(\+1\s?)?(\(?\d{3}\)?[\s.-]?)?\d{3}[\s.-]?\d{4}$/,
    msgKey: "ZipCodePattern",
  },
};
