import { Button, Col, Row } from "antd";
import "./assesment.scss";
import leftIcon from "assets/svgs/step/left.svg";
import rightIcon from "assets/svgs/step/right.svg";
import StepOne from "./step-one";
import { useEffect, useMemo, useState } from "react";
import StepTwo from "./step-two";
import StepThree from "./step-three";
import StepFour from "./step-four";
import StepFive from "./step-five";
import { useQuery } from "react-query";
import assessment from "api/assessment";
import _ from "lodash";
import LoaderApp from "components/loader";
import { useRecoilState } from "recoil";
import { currentIndexStepAtom } from "atoms/atoms";
import StepsDiv from "components/steps";
import { AssessmentStepType } from "constants/enums";
import { mapEnumToItems } from "utils/map-enum";

const Assessment = ({ assessmentId, getAssessment }: any) => {
  const [currentIndex, setCurrentIndex] = useRecoilState(currentIndexStepAtom);
  const [loading, setLoading] = useState<boolean>(true);
  const [data, setData] = useState<any>();

  const currentInternal = (index: number) => {
    switch (index) {
      case AssessmentStepType.Severity:
        return (
          <StepOne
            data={data!}
            assessmentId={assessmentId}
            getAssessment={getAssessment}
          />
        );

      case AssessmentStepType.Phenotype:
        return (
          <StepTwo
            data={data}
            assessmentId={assessmentId}
            getAssessment={getAssessment}
          />
        );

      case AssessmentStepType.Treatment:
        return <StepThree data={data} assessmentId={assessmentId} />;

      case AssessmentStepType.Scores:
        return (
          <StepFour
            data={data}
            assessmentId={assessmentId}
            getAssessment={getAssessment}
          />
        );

      case AssessmentStepType.PACharts:
        return (
          <StepFive
            data={data}
            assessmentId={assessmentId}
            getAssessment={getAssessment}
          />
        );
    }
  };

  useQuery(
    ["assessmentStepOne", assessmentId],
    () =>
      assessment.getStepOne({
        id: assessmentId,
      }),
    {
      enabled: !!assessmentId && currentIndex == AssessmentStepType.Severity,
      onSuccess(data: any): void {
        setData(data);
        setLoading(false);
      },
    }
  );

  useQuery(
    ["assessmentStepTwo", assessmentId],
    () =>
      assessment.getStepTwo({
        id: assessmentId,
      }),
    {
      enabled: !!assessmentId && currentIndex == AssessmentStepType.Phenotype,
      onSuccess(data: any): void {
        setData(data);
        setLoading(false);
      },
    }
  );

  useQuery(
    ["assessmentStepThree", assessmentId],
    () =>
      assessment.getStepThree({
        id: assessmentId,
      }),
    {
      enabled: !!assessmentId && currentIndex == AssessmentStepType.Treatment,
      onSuccess(data: any): void {
        setData(data);
        setLoading(false);
      },
    }
  );

  useQuery(
    ["assessmentStepFour", assessmentId],
    () =>
      assessment.getStepFour({
        id: assessmentId,
      }),
    {
      enabled: !!assessmentId && currentIndex == AssessmentStepType.Scores,
      onSuccess(data: any): void {
        setData(data);
        setLoading(false);
      },
    }
  );

  useQuery(
    ["assessmentStepFive", assessmentId],
    () =>
      assessment.getStepFive({
        id: assessmentId,
      }),
    {
      enabled: !!assessmentId && currentIndex == AssessmentStepType.PACharts,
      onSuccess(data: any): void {
        setData(data);
        setLoading(false);
      },
    }
  );

  // useQuery(
  //   ["assessmentStep", assessmentId, currentIndex],
  //   () =>
  //     assessment.getStepAssessmentById({
  //       id: assessmentId,
  //       step: currentIndex + 1,
  //     }),
  //   {
  //     enabled: !!assessmentId,
  //     onSuccess(data: any): void {
  //       setData(data);
  //       setLoading(false);
  //     },
  //   },
  // );

  useEffect(() => {
    setLoading(true);
  }, [assessmentId]);

  const expensiveResult = useMemo(() => {
    return currentInternal(currentIndex);
  }, [assessmentId, data]);

  return (
    <div className="assesment">
      <LoaderApp loading={loading}>
        <StepsDiv
          current={currentIndex}
          items={mapEnumToItems(AssessmentStepType)}
          onStepClick={(current: number) => {
            setCurrentIndex(current + 1);
            setLoading(true);
          }}
        />
        <Row className="assesment-contant">
          <Col span={1}>
            <Button
              style={{
                display: `${
                  currentIndex <= AssessmentStepType.Severity ? "none" : ""
                }`,
              }}
              className="btn-step"
              icon={
                <img
                  src={leftIcon}
                  onClick={() => {
                    setCurrentIndex(currentIndex - 1);
                    setLoading(true);
                  }}
                />
              }
            />
          </Col>
          <Col span={22}>{data && expensiveResult}</Col>
          <Col span={1}>
            <Button
              style={{
                display: `${
                  currentIndex > AssessmentStepType.Scores ? "none" : ""
                }`,
              }}
              className="btn-step"
              icon={
                <img
                  src={rightIcon}
                  onClick={() => {
                    setCurrentIndex(currentIndex + 1);
                    setLoading(true);
                  }}
                />
              }
            />
          </Col>
        </Row>
      </LoaderApp>
    </div>
  );
};
export default Assessment;
