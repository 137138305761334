import FormElement from "components/form-element";
import AntCollapse from "components/collapse";
import { FunctionComponent } from "react";
import { useForm } from "react-hook-form";
import _ from "lodash";

interface IGuidPanelProps {
  guideData: any;
}

const GuidPanel: FunctionComponent<IGuidPanelProps> = ({ guideData }) => {
  const { control, reset, handleSubmit } = useForm<any>({
    mode: "onBlur",
    defaultValues: {},
    shouldFocusError: true,
  });

  const childrenPanel = (item: any) => {
    return (
      <div>
        {/* {/* {!_.isEmpty(item?.approvalCriteria) && (
        <p className="title"> Approval Criteria : </p>
      )} */}
        {
          !_.isEmpty(item?.approvalCriteria) && (
            // item.approvalCriteria.map((item: any) => (
            <>
              <p className="title-ac"> - Approval Criteria : </p>
              {item?.approvalCriteria?.options?.map((item: any) => (
                <FormElement
                  type="checkbox"
                  name={item?.label}
                  control={control}
                  label={item?.label}
                  isChecked={item?.value}
                  disabled
                />
              ))}
            </>
          )
          // ))
        }
        {!_.isEmpty(item.notes) && (
          <>
            <p className="title"> Notes : </p>
            {item.notes.map((item: any) => (
              <>
                <p> {item} </p>
              </>
            ))}
          </>
        )}

        {!_.isEmpty(item.missingInfo) && (
          <>
            <p className="title-missing_info"> Missing Info: </p>
            {item.missingInfo?.map((item: any) => (
              <p> {item} </p>
            ))}
          </>
        )}
      </div>
    );
  };

  return (
    <div>
      <div className="guid-panel-text"> Guide Panel </div>
      {
        <div className="item-panel">
          <AntCollapse
            accordion={true}
            defaultActiveKey={0}
            items={
              guideData &&
              Object.entries(guideData)?.map(([key, item]: any, index) => {
                return {
                  key: index,
                  label: <strong>{item?.title}</strong>,
                  children: childrenPanel(item),
                };
              })
            }
          />
        </div>
      }
    </div>
  );
};

export default GuidPanel;
