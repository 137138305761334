import GeneralTable from "components/general-table";
import patient from "api/patient";
import { patientColumns } from "./data";
import DetailsIcon from "assets/svgs/general-table/details.svg";
import RefreshIcon from "assets/svgs/patient-table/patient-refresh.svg";
import AssessmentIcon from "assets/svgs/patient-table/assessment.svg";
import AssesmentModal from "./assesment-modal";
import { useState } from "react";
import PatientForm from "./forms";
import { useNavigate } from "react-router-dom";
import { getAge } from "utils/get-age";
import { GenderType } from "constants/enums";
import { PatientDto } from "models/patient";

export default function PatientPage() {
  const [isOpenAssesment, setIsOpenAssesment] = useState(false);
  const [patientId, setPatientId] = useState<number>();
  const navigate = useNavigate();

  return (
    <>
      <GeneralTable
        headerTable="Biologics Accessibility Scoring System"
        dataName="Patient"
        getAllFunc={patient.getAll}
        delete
        deleteFunc={patient.Delete}
        create
        createFunc={patient.Create}
        formContent={PatientForm}
        tableColumns={patientColumns}
        customOnRowClick={(row: PatientDto) => {
          setIsOpenAssesment(true);
          setPatientId(row.id);
        }}
        tableDataMapper={(rows) => {
          return rows?.map((row: any, index: number) => {
            return {
              ...row,
              name: row.fName + " " + row.lName,
              age: getAge(row.birthDate) + " Y",
              gender: GenderType[row.gender],
            };
          });
        }}
        FormSubmitMapper={(data: any) => {
          return {
            ...data,
            created: new Date(),
          };
        }}
        customActions={[
          {
            Icon: (
              <img src={RefreshIcon} className="icon-button-table" alt="" />
            ),
            cb: (row) => {
              // FHIR.oauth2.ready().then(() => {
              //     // function to refresh patient
              // }).catch(() => {
              //     fhirAuthorize()
              // })
            },
            tooltip: "Refresh",
          },
          {
            Icon: (
              <img src={AssessmentIcon} className="icon-button-table" alt="" />
            ),
            // items: { items },
            cb: (row) => {
              setIsOpenAssesment(true);
              setPatientId(row.id);
              // navigate(`/auth/assessment/${row?.id}`);
            },
            tooltip: "Assessment",
          },
          {
            Icon: (
              <img src={DetailsIcon} className="icon-button-table" alt="" />
            ),
            cb: (row) => {
              navigate(`/auth/patient/${row.id}`);
            },
            tooltip: "Patient Details",
          },
        ]}
      />
      {isOpenAssesment && (
        <AssesmentModal
          setIsOpenAssesment={setIsOpenAssesment}
          isOpenAssesment={isOpenAssesment}
          patientId={patientId}
        />
      )}
    </>
  );
}
