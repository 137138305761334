import { useParams } from "react-router-dom";
import { planColumns } from "./data";
import MemberPlanForm from "./forms";
import GeneralTable from "components/general-table";
import memberPlan from "api/member-plan";

const MemberPlanPage = () => {
  const { id: patientId } = useParams();

  return (
    <GeneralTable
      dataName="MemberPlan"
      headerTable="MemberPlans"
      getAllFunc={memberPlan.getAll}
      getAllParams={{ patientId }}
      getFunc={memberPlan.Get}
      delete
      deleteFunc={memberPlan.Delete}
      create
      createFunc={memberPlan.Create}
      update
      updateFunc={memberPlan.Update}
      formContent={MemberPlanForm}
      tableColumns={planColumns}
      tableDataMapper={(rows) => {
        return rows?.map((row: any, index: number) => {
          return {
            ...row,
            plan: row.plan?.label,
          };
        });
      }}
      FromDataMapper={(data: any) => {
        return {
          ...data,
          planId: data.plan?.value,
        };
      }}
      FormSubmitMapper={(data: any) => {
        return {
          ...data,
          patientId: patientId,
        };
      }}
    />
  );
};
export default MemberPlanPage;
