import { useParams } from "react-router-dom";
import GeneralTable from "components/general-table";
import { observationColumns } from "./data";
import ObservationForm from "./forms";
import observation from "api/observation";
import { ObservationUnitType } from "constants/enums";
import { ObservationCreateDto } from "models/observation";

const Observation = () => {
  const { patientId: patientId, id: assessmentId } = useParams();

  return (
    <GeneralTable
      dataName="Observation"
      getAllFunc={observation.getAll}
      getAllParams={{ assessmentId, patientId }}
      getFunc={observation.Get}
      delete
      deleteFunc={observation.Delete}
      create
      createFunc={observation.Create}
      formContent={ObservationForm}
      update
      updateFunc={observation.Update}
      tableColumns={observationColumns}
      FormSubmitMapper={(data: ObservationCreateDto) => {
        return {
          ...data,
          patientId: patientId,
          assessmentId: assessmentId,
        };
      }}
    />
  );
};
export default Observation;
