import { Layout } from "antd";

export default function Footer() {
  const { Footer } = Layout;

  return (
    <Footer style={{ textAlign: "center" }}>
      CPI ©2024 Created by AccuClear AI
    </Footer>
  );
}
