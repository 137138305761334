import config from "./config";
import ApiService from "./baseApi/api-service";
class PaForm extends ApiService {
  constructor() {
    super({ baseURL: config.baseUrl, headers: config.headers });
  }
  /* @tags PaForm */
  /* @name PaFormGet */
  /* @request GET:/api/services/app/PaForm/Get */
  public Get = (params: { id?: number }): Promise<any> => {
    return this.get("/PaForm/Get", {
      params,
    });
  };

  /* @tags PaForm */
  /* @name PaFormGetAll */
  /* @request GET:/api/services/app/PaForm/GetAll */
  public getAll = (params: any): Promise<any> => {
    return this.get("/PaForm/GetAll", {
      params,
    });
  };

  /* @tags PaForm */
  /* @name inputOutputCreate */
  /* @request POST:/api/services/app/PaForm/Create */
  public Create = (params: any): Promise<any> => {
    return this.post("/PaForm/Create", {
      ...params,
    });
  };

  /* @tags PaForm */
  /* @name inputOutputUpdate */
  /* @request PUT:/api/services/app/PaForm/Update */
  public Update = (params: any): Promise<any> => {
    return this.put(`/PaForm/Update`, {
      ...params,
    });
  };

  /* @tags PaForm */
  /* @name inputOutputDelete */
  /* @request POST:/api/services/app/PaForm/Delete */
  public Delete = (params: { id: number }): Promise<any> => {
    return this.delete(`/PaForm/Delete`, { params });
  };
}
const paForm = new PaForm();
export default paForm;
