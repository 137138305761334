import { Col } from "antd";
import "styles/lable-color/index.scss";

export default function LableColor(label: string, color: string) {
  return (
    <Col className="col-s">
      {" "}
      <p className="squaer" style={{ backgroundColor: color }}></p>{" "}
      <p className="squaer-text">{label}</p>{" "}
    </Col>
  );
}
