import leftIcon from "assets/svgs/step/left.svg";
import rightIcon from "assets/svgs/step/right.svg";
import { currentIndexStepPaAtom } from "atoms/atoms";
import StepsDiv from "components/steps";
import { FunctionComponent } from "react";
import PaStepOne from "./steps/step-one";
import { Button, Col, Row } from "antd";
import { useRecoilState } from "recoil";
import { PAStepType } from "constants/enums";
import { mapEnumToItems } from "utils/map-enum";
import PaStepTwo from "./steps/step-two";
import PaStepthree from "./steps/step-three";
import PaStepFive from "./steps/step-five";
import PaStepSeven from "./steps/step-seven";
import PaStepSix from "./steps/step-six";
import PaStepFour from "./steps/step-four";

interface IActionStepProps {
  paId: number;
  data?: any;
}

const StepAction: FunctionComponent<IActionStepProps> = ({ paId, data }) => {
  const [currentIndex, setCurrentIndex] = useRecoilState(
    currentIndexStepPaAtom
  );

  const currentInternal = (index: number) => {
    switch (index) {
      case 1:
        return <PaStepOne paId={paId} />;
      case 2:
        return <PaStepTwo paId={paId} data={data} />;
      case 3:
        return <PaStepthree paId={paId} />;
      // case 4:
      //   return <PaStepFour paId={paId} />;
      case 5:
        return <PaStepFive paId={paId} />;
      case 6:
        return <PaStepSix paId={paId} />;
      case 7:
        return <PaStepSeven paId={paId} />;
      case 8:
        return <PaStepSeven paId={paId} disabled />;
    }
  };

  return (
    <div className="section">
      <Row gutter={[8, 8]} justify="center" align="middle">
        <Col span={1}>
          <Button
            style={{ display: `${currentIndex <= 1 ? "none" : ""}` }}
            className="pa-btn-step"
            icon={
              <img
                src={leftIcon}
                onClick={() => {
                  setCurrentIndex(currentIndex - 1);
                  // setLoading(true);
                }}
              />
            }
          />
        </Col>
        <Col span={22}>
          <StepsDiv
            current={currentIndex}
            items={mapEnumToItems(PAStepType)}
            onStepClick={(current: number) => {
              setCurrentIndex(current + 1);
              // setLoading(true);
            }}
          />
        </Col>
        <Col span={1}>
          <Button
            style={{ display: `${currentIndex > 7 ? "none" : ""}` }}
            className="pa-btn-step"
            icon={
              <img
                src={rightIcon}
                onClick={() => {
                  setCurrentIndex(currentIndex + 1);
                  // setLoading(true);
                }}
              />
            }
          />
        </Col>
      </Row>
      {currentInternal(currentIndex)}
    </div>
  );
};

export default StepAction;
