import React, { useState } from "react";
import { Col, Row } from "antd";
import FormElement from "components/form-element";
import observation from "api/observation";
import { useQuery } from "react-query";
import {
  ObservationConditionCategoryType,
  ObservationUnitType,
} from "constants/enums";
import _ from "lodash";
import { mapEnumToSelectValues } from "utils/map-enum";
import { IFormProps } from "models/baseModels/form-props";

interface ObservationFormProps extends IFormProps {}

const ObservationForm: React.FC<ObservationFormProps> = ({
  control,
  disabled,
  data,
}) => {
  const [category, setCategory] = useState(data?.observationCategory);
  const [code, setCode] = useState(data?.observationCode);
  const [observationItem, setObservationItem] = useState<any>();

  const sharedProps = {
    control,
    disabled,
  };

  const getAllTypes = useQuery(
    ["getAllTypes", category],
    () =>
      observation.getAllTypes({
        category: category,
      }),
    {
      enabled: !!category,
      onSuccess(data) {
        setObservationItem(
          data?.items?.find((element: any) => element.value == code)
        );
      },
    }
  );

  return (
    <Row>
      <Col span={12}>
        <FormElement
          {...sharedProps}
          label="Category"
          type="select"
          name="observationCategory"
          options={mapEnumToSelectValues(ObservationConditionCategoryType)}
          onChange={(value) => setCategory(value)}
          required
        />
      </Col>
      <Col span={12}>
        <FormElement
          {...sharedProps}
          label="Name"
          type="select"
          name="observationCode"
          otherFormProps={{ loading: getAllTypes.isLoading }}
          options={getAllTypes?.data?.items}
          onChange={(value, option) => {
            setCode(value);
            setObservationItem(option);
          }}
          required
        />
      </Col>
      {observationItem?.isValueRequired && (
        <Col span={12}>
          <FormElement
            {...sharedProps}
            label="Value"
            type="input"
            name="value"
            required
          />
        </Col>
      )}
      {!_.isEmpty(observationItem?.units) && (
        <Col span={12}>
          <FormElement
            {...sharedProps}
            label="Unit"
            type="select"
            name="unit"
            otherFormProps={{ loading: getAllTypes.isLoading }}
            options={observationItem?.units?.map((item: number) => ({
              label: ObservationUnitType[item],
              value: item,
            }))}
            defaultValue={observationItem?.units[0]}
          />
        </Col>
      )}
      <Col span={12}>
        <FormElement
          {...sharedProps}
          label="Issued"
          type="datePicker"
          name="issuedAt"
          required
        />
      </Col>
      {observationItem?.value == 6 && ( // Exacerbations
        <Col span={12}>
          <FormElement
            {...sharedProps}
            label="requiring hospital admission or emergency"
            type="checkbox"
            name="withER"
          />
        </Col>
      )}
      {observationItem?.value == 9 && ( // FEV1
        <>
          <Col span={12}>
            <FormElement
              {...sharedProps}
              label="with high reversibility"
              type="checkbox"
              name="withHighReversibility"
            />
          </Col>
          <Col span={12}>
            <FormElement
              {...sharedProps}
              label="with hyperresponsiveness"
              type="checkbox"
              name="withHyperresponsiveness"
            />
          </Col>
        </>
      )}
    </Row>
  );
};

export default ObservationForm;
