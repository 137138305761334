import { Card, Col, Row, Tooltip } from "antd";
import colors from "constants/colors";
import _ from "lodash";
import moment from "moment";
import { FunctionComponent } from "react";
import { Plus } from "react-feather";
import Chart from "react-google-charts";
import { dataChart, optionsChart } from "utils/chart-helper";
import missingIocn from "assets/svgs/patient-table/assesment/missing.svg";
import { dateFormatMonth } from "constants/index";
import { CardDto } from "models/assessment-step";
import { GraphType, ObservationStatusType } from "constants/enums";

interface CardAssessmentProps {
  card: CardDto;
  cardDtos?: any;
  index: number;
  numberCol: number;
  titleCard?: JSX.Element;
  titleHover?: JSX.Element;
  contentCard?: JSX.Element;
  onClickCard?: (value: any) => void;
}

const CardAssessment: FunctionComponent<CardAssessmentProps> = ({
  card,
  cardDtos,
  index,
  numberCol,
  titleCard,
  titleHover,
  contentCard,
  onClickCard: onClickCard,
}) => {
  const SuggestedChangeValue =
    card?.cardRecords?.[0]?.statusDto?.value !== ObservationStatusType.Normal &&
    card?.cardRecords?.[0]?.label?.includes("Suggested");

  return (
    <Col
      span={24 / numberCol}
      key={index}
      className={`${
        (index + 1) % numberCol != 0 ? "card-asess-border" : "col-card-asess"
      }`}
    >
      <Tooltip overlayClassName="tooltip-table" title={titleHover}>
        <Card
          title={
            titleCard ?? (
              <>
                <Row justify="space-between">
                  <Col> {card.cardElement.title} </Col>
                  <Col>
                    {" "}
                    <Plus
                      className="pr-1"
                      color={colors.primaryColor}
                      size="25"
                    />{" "}
                  </Col>
                </Row>

                {card.cardElement.displayAll && (
                  <Row justify="space-between">
                    <Col
                      style={{
                        fontWeight: "normal",
                        color: colors.primaryColor,
                      }}
                    >
                      {!_.isEmpty(card.cardRecords) &&
                        (card.cardRecords[0].label ??
                          card.cardRecords[0].value) +
                          " " +
                          card.cardElement.unit +
                          ", " +
                          moment(card.cardRecords[0].date).format(
                            dateFormatMonth
                          )}
                    </Col>
                  </Row>
                )}
              </>
            )
          }
          size={"small"}
          className={`card-asess ${
            index < cardDtos?.length - numberCol ? "card-asess-border-down" : ""
          }`}
          onClick={(v) => onClickCard && onClickCard(v)}
        >
          {contentCard ??
            (!_.isEmpty(card.cardRecords) ? (
              !card.cardElement.displayAll ? (
                <p
                  className="card-body-text"
                  style={{
                    color: SuggestedChangeValue
                      ? colors.dangerColor
                      : colors.black,
                    fontWeight: SuggestedChangeValue ? "bold" : undefined,
                    whiteSpace: "pre-wrap",
                  }}
                >
                  {card.cardRecords[0]
                    ? JSON.stringify(card.cardRecords[0]?.value)
                        .replace(/,/g, "\n")
                        .replace(/[{}"]/g, "")
                    : "" + " " + card.cardElement.unit}
                </p>
              ) : (
                <Chart
                  chartType={
                    card.cardElement.graphType == GraphType.Line
                      ? "LineChart"
                      : "ColumnChart"
                  }
                  className="card-body-chart"
                  height="85px"
                  width="100%"
                  options={optionsChart(card.cardRecords)}
                  data={dataChart(card.cardRecords)}
                />
              )
            ) : (
              <img className="icon-h" src={missingIocn} />
            ))}
        </Card>
      </Tooltip>
    </Col>
  );
};

export default CardAssessment;
