import React from "react";
import MainRouter from "./routers/main";
import { BrowserRouter } from "react-router-dom";
import { ToastContainer } from "react-toastify";
import { RecoilRoot } from "recoil";

function App() {
  return (
    <div className="App">
      <BrowserRouter>
        <RecoilRoot>
          <ToastContainer />
          <MainRouter />
        </RecoilRoot>
      </BrowserRouter>
    </div>
  );
}

export default App;
