import { columnsInterface } from "models/baseModels/table-columns";
import { Button } from "antd";

export const patientColumns: columnsInterface[] = [
  { type: "string", title: "Patient", dataSelector: "patient" },
  { type: "string", title: "Practitioner", dataSelector: "practitioner" },
  { type: "date", title: "Request Date", dataSelector: "requestDate" },
  { type: "string", title: "Biologic", dataSelector: "biologic" },
  { type: "string", title: "Outcome", dataSelector: "outCome" },
  { type: "string", title: "Request Type", dataSelector: "requestType" },
  { type: "string", title: "Status", dataSelector: "status" },
  { type: "date", title: "Submitted Date", dataSelector: "submittedDate" },
];
