import "styles/input/index.scss";
import { InputNumber as AntNumbherInput } from "antd";
import { FunctionComponent } from "react";
import { useAnimation } from "framer-motion";
import {
  defaultTranstion,
  maxBackgroundWidth,
  minBackgroundWidth,
} from "constants/framer";
import { Controller } from "react-hook-form";
import FromElemnetWrapper from "components/form-element-wrapper";
interface InputProps {
  name: string;
  control: any;
  rules?: any;
  min?: number;
  max?: number;
  placeholder?: string;
  label: string;
  disabled?: boolean;
  step?: string;
  onChange?: (value: any) => void;
}
const InputNumber: FunctionComponent<InputProps> = ({
  name,
  control,
  rules,
  placeholder,
  label,
  min,
  max,
  disabled,
  step,
  onChange: onPropsChange,
}) => {
  const focusController = useAnimation();
  const errorController = useAnimation();
  return (
    <>
      <FromElemnetWrapper
        required={rules.required?.value}
        focusController={focusController}
        errorController={errorController}
        label={label}
      >
        <Controller
          control={control}
          name={name}
          rules={rules}
          render={({
            field: { onChange, onBlur, value, name, ref },
            fieldState: { invalid, error },
          }) => {
            if (invalid) {
              errorController.start(maxBackgroundWidth);
            } else {
              errorController.start(minBackgroundWidth, {
                ease: defaultTranstion.ease.reverse(),
              });
            }
            return (
              <div className="relative overflow-hidden">
                <AntNumbherInput
                  onFocus={() => {
                    focusController.start(maxBackgroundWidth);
                  }}
                  onChange={(v) => {
                    onPropsChange && onPropsChange(v);
                    onChange(v);
                  }}
                  value={value}
                  ref={ref}
                  id={name}
                  className={
                    invalid ? "ant-custom-input-error" : "ant-custom-input"
                  }
                  onBlur={() => {
                    onBlur();
                    focusController.start(minBackgroundWidth);
                  }}
                  placeholder={placeholder}
                  min={min}
                  max={max}
                  disabled={disabled}
                  step={step ?? undefined}
                />
                {invalid && (
                  <p className="form-element-error">{error?.message}</p>
                )}
              </div>
            );
          }}
        />
      </FromElemnetWrapper>
    </>
  );
};

export default InputNumber;
