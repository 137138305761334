import { useParams } from "react-router-dom";
import { planColumns } from "./data";
import PlanForm from "./forms";
import GeneralTable from "components/general-table";
import plan from "api/plan";
import { PlanType } from "constants/enums";
import { attachmentMapper } from "utils/attachment-mapper";

const PlanPage = () => {
  const { id } = useParams();

  return (
    <GeneralTable
      dataName="Plan"
      headerTable="Payer Plans"
      getAllFunc={() => plan.getAll({ payerId: id })}
      getFunc={plan.Get}
      delete
      deleteFunc={plan.Delete}
      create
      createFunc={plan.Create}
      update
      updateFunc={plan.Update}
      formContent={PlanForm}
      tableColumns={planColumns}
      tableDataMapper={(rows) => {
        return rows?.map((row: any, index: number) => {
          return {
            ...row,
            planType: PlanType[row?.planType],
          };
        });
      }}
      FromDataMapper={(data: any) => {
        return {
          ...data,
        };
      }}
      FormSubmitMapper={(data: any) => {
        return {
          ...data,
          payerId: Number(id),
        };
      }}
    />
  );
};
export default PlanPage;
