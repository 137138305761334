import { useState } from "react";
import GeneralFormContainer, {
  IFormError,
} from "components/general-form-containner";
import BasicModal from "components/modal";
import condition from "api/condition";
import { notify } from "components/notification";
import { useMutation, useQueryClient } from "react-query";
import { useParams } from "react-router-dom";
import ConditionForm from "../condition/forms";

const ModalCondition = ({ formVisable, setFormVisable, cardInfo }: any) => {
  const { patientId } = useParams();
  const queryClient = useQueryClient();

  const toggleForm = () => {
    setFormVisable(false);
  };

  const onSubmit = useMutation(condition.Create, {
    onSuccess: () => {
      notify("success", "ok", "Success");
      toggleForm();
      queryClient.invalidateQueries(["assessmentStep"]);
    },
  });

  const formContent = () => (
    <GeneralFormContainer
      dataName="Condition"
      type="Create"
      content={ConditionForm}
      actionLoading={onSubmit.isLoading}
      toggleContent={toggleForm}
      onSubmit={(data: any) => {
        onSubmit.mutate({
          ...data,
          patientId: patientId,
          display: data.display?.label,
        });
      }}
      defaultValues={{
        display: {
          value: cardInfo?.cardElement?.conditionCode,
          label: cardInfo?.cardElement?.conditionName,
        },
        code: cardInfo?.cardElement?.conditionCode,
        search: cardInfo?.cardElement?.conditionName,
      }}
    />
  );

  return (
    <BasicModal
      header={<h2 className="text-primary">Create Condition</h2>}
      isOpen={formVisable}
      closable
      handleCancel={toggleForm}
      afterClose={toggleForm}
      width={"60vw"}
      footer={false}
      content={formContent()}
    />
  );
};
export default ModalCondition;
