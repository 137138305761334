import { Button, Col, Row } from "antd";
import "./assesment.scss";
import { AsthmaType, ControlType } from "constants/enums";
import FormElement from "components/form-element";
import { useForm } from "react-hook-form";
import { useMutation, useQueryClient } from "react-query";
import assessment from "api/assessment";
import { notify } from "components/notification";
import _ from "lodash";
import LableColor from "components/lable-color";
import { useRecoilState } from "recoil";
import { refetchMedicationDataAtom } from "atoms/atoms";
import ModalObservation from "./modal-observation";
import { FunctionComponent, useEffect, useState } from "react";
import ModalConfirm from "./modal-confirm";
import { mapEnumToSelectValues } from "utils/map-enum";
import CardAssessment from "components/card-assessment";
import { AssessmentStepOneDto, UpdateStepOneDto } from "models/assessment-step";

interface StepOneProps {
  data: AssessmentStepOneDto;
  assessmentId: number;
  getAssessment: any;
}

const StepOne: FunctionComponent<StepOneProps> = ({
  data,
  assessmentId,
  getAssessment,
}) => {
  const [refetchMedicationData, setRefetchMedicationData] = useRecoilState(
    refetchMedicationDataAtom
  );
  const [formVisable, setFormVisable] = useState<boolean>(false);
  const [confirmVisible, setConfirmVisible] = useState<boolean>(false);
  const [cardInfo, setCardInfo] = useState<number>();
  const queryClient = useQueryClient();

  const { control, reset, handleSubmit, setValue } = useForm<UpdateStepOneDto>({
    mode: "onBlur",
    // defaultValues: {
    //   asthmaType: data?.asthmaType
    //     ? data.asthmaType
    //     : data?.suggestedAsthmaType,
    //   controlCategory: data?.controlCategory
    //     ? data.controlCategory
    //     : data?.suggestedControlCategory,
    // },
    shouldFocusError: true,
  });

  const sharedProps = {
    control,
  };

  const ConfirmMutate = (data: UpdateStepOneDto) => {
    Confirm.mutate({
      ...data,
      id: assessmentId,
    });
  };

  const OnSubmitResetValue = () => {
    setValue?.("asthmaType", data?.suggestedAsthmaType);
    setValue?.("controlCategory", data?.suggestedControlCategory);
  };

  const Confirm = useMutation(assessment.updateStepOneAssessment, {
    onSuccess: (data) => {
      setConfirmVisible(false);
      setRefetchMedicationData(!refetchMedicationData);
      notify("success", "ok", "Success", "Update Step One");
      queryClient.invalidateQueries(["assessmentStepOne"]);
    },
  });

  useEffect(() => {
    setValue?.("asthmaType", data.asthmaType);
    setValue?.("controlCategory", data.controlCategory);
  }, [assessmentId, data]);

  return (
    <div className="one-step">
      <ModalObservation
        setFormVisable={setFormVisable}
        formVisable={formVisable}
        cardInfo={cardInfo}
        getAssessment={getAssessment}
      />
      {/* modal create observation after click on card */}
      <ModalConfirm
        formVisable={confirmVisible}
        setFormVisable={setConfirmVisible}
        onConfirmHandle={handleSubmit(ConfirmMutate)}
        loadingConfirm={Confirm.isLoading}
        Card={data.cardDtos}
      />
      <Row>
        <Col flex={3}>
          <Row>
            {" "}
            <p className="one-step-header-left">Medical Attributes</p>{" "}
          </Row>
        </Col>
        <Col flex={0.1}>
          <div className="one-step-header-right">
            <Row justify="space-between">
              {LableColor("Well", "#00B050")}
              {LableColor("Alert", "#EA2323")}
              {LableColor("Worse", "#FFFF99")}
            </Row>
          </div>
        </Col>
      </Row>
      <Row gutter={[8, 8]} className="row-card-asess">
        {data?.cardDtos?.map((card: any, index: number) => {
          return (
            <CardAssessment
              numberCol={4}
              card={card}
              index={index}
              cardDtos={data?.cardDtos}
              onClickCard={() => {
                setFormVisable(true);
                setCardInfo(card);
              }}
            />
            // <Col
            //   span={6}
            //   key={index}
            //   className={`${
            //     (index + 1) % (24 / 6) != 0
            //       ? "card-asess-border"
            //       : "col-card-asess"
            //   }`}
            // >
            //   <Card
            //     title={
            //       <>
            //         <Row justify="space-between">
            //           <Col> {card.cardElement.title} </Col>
            //           <Col>
            //             {" "}
            //             <Plus
            //               className="pr-1"
            //               color={colors.primaryColor}
            //               size="25"
            //             />{" "}
            //           </Col>
            //         </Row>
            //         {card.cardElement.displayAll && (
            //           <Row justify="space-between">
            //             <Col
            //               style={{
            //                 fontWeight: "normal",
            //                 color: colors.primaryColor,
            //               }}
            //             >
            //               {!_.isEmpty(card.cardRecords) &&
            //                 (card.cardRecords[0].label ??
            //                   card.cardRecords[0].value) +
            //                   " " +
            //                   card.unit +
            //                   ", " +
            //                   moment(card.cardRecords[0].date).format(
            //                     dateFormatMonth
            //                   )}
            //             </Col>
            //           </Row>
            //         )}
            //       </>
            //     }
            //     size={"small"}
            //     className={`card-asess ${
            //       index < data?.cardDtos?.length - 24 / 6
            //         ? "card-asess-border-down"
            //         : ""
            //     }`}
            //     onClick={() => {
            //       setFormVisable(true);
            //       setCardInfo(card);
            //     }}
            //   >
            //     {card.display_all === "no" ? (
            //       <p
            //         className="card-body-text"
            //         style={{
            //           whiteSpace: "pre",
            //           color:
            //             card.cardRecords[0]?.statusDto == 0 &&
            //             card.title == "Suggested Output"
            //               ? colors.dangerColor
            //               : colors.black,
            //           fontWeight:
            //             card.cardRecords[0]?.statusDto == 0 &&
            //             card.title == "Suggested Output"
            //               ? "bold"
            //               : undefined,
            //         }}
            //       >
            //         {card.cardRecords[0] !== "N/A" ? (
            //           card.cardRecords[0] ? (
            //             JSON.stringify(card.cardRecords[0])
            //               .replace(/,/g, "\n")
            //               .replace(/[{}"]/g, "")
            //           ) : (
            //             "" + " " + card.cardElement.unit
            //           )
            //         ) : (
            //           <img className="icon-h" src={missingIocn} />
            //         )}
            //       </p>
            //     ) : (
            //       <Chart
            //         chartType={
            //           card.graph == "line" ? "LineChart" : "ColumnChart"
            //         }
            //         className="card-body-chart"
            //         height="85px"
            //         width="100%"
            //         options={optionsChart(card.cardRecords)}
            //         data={dataChart(card.cardRecords)}
            //       />
            //     )}
            //   </Card>
            // </Col>
          );
        })}
      </Row>
      <Row className="row-asses-f">
        <Col span={7}>
          <FormElement
            {...sharedProps}
            // value={data?.asthmaType !== 0 ? data?.asthmaType : data?.suggestedAsthmaType}
            options={mapEnumToSelectValues(AsthmaType)}
            // className="full-width"
            placeholder="Select Type"
            label=""
            type="select"
            name="asthmaType"
            required
          />
        </Col>
        <Col span={7}>
          <FormElement
            {...sharedProps}
            // value={data?.asthmaType !== 0 ? data?.asthmaType : data?.suggestedAsthmaType}
            options={mapEnumToSelectValues(ControlType)}
            // className="full-width"
            placeholder="Select Contol Category"
            label=""
            type="select"
            name="controlCategory"
            required
          />
        </Col>
        <Col span={4}>
          <Button
            className="full-width"
            onClick={() => {
              OnSubmitResetValue();
            }}
          >
            Reset to default
          </Button>
        </Col>
        <Col span={6}>
          <Button
            className="full-width"
            type="primary"
            onClick={() => {
              setConfirmVisible(true);
            }}
          >
            Confirm Severity
          </Button>
        </Col>
      </Row>
    </div>
  );
};
export default StepOne;
