import { ValueLabelDto } from "models/baseModels/value-label";

type EnumType = { [s: number]: string };
/**
 * @description Maps an Enum to an object
 * @param  {EnumType} enumerable Enum to map
 * @param  {Function} fn The map function
 * @returns Object where the key is the number and the value is the string
 */
export function mapEnum(enumerable: EnumType, fn: Function): any[] {
  // get all the members of the enum
  let enumMembers: any[] = Object.keys(enumerable).map(
    (key: any) => enumerable[key]
  );
  // we are only interested in the numeric identifiers as these represent the values
  let enumValues: number[] = enumMembers.filter((v) => typeof v === "number");

  // now map through the enum values
  return enumValues.map((m, i) => fn({ [m]: enumerable[m] }, i));
}

export const mapEnumToSelectValues = (
  enumerable: EnumType
): ValueLabelDto[] => {
  const newOptions: ValueLabelDto[] = [];
  mapEnum(enumerable, (value: any) => {
    newOptions.push({
      value: Number(Object.keys(value)[0]),
      label: String(Object.values(value)[0]),
    });
  });
  return newOptions;
};

export const mapEnumToItems = (enumerable: EnumType) => {
  return Object.entries(enumerable)
    .filter(([key, value]) => isNaN(Number(key))) // Exclude reverse mapping
    .map(([value, key]) => ({
      title: value, // Enum value
    }));
};
