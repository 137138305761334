import GeneralTable from "components/general-table";
import { userColumns } from "./data";
import user from "api/user";
import { UserType } from "constants/enums";
import UserForm from "./forms";

export default function UsersPage() {
  return (
    <GeneralTable
      headerTable="Users"
      dataName="User"
      getAllFunc={user.getAll}
      getFunc={user.Get}
      delete
      deleteFunc={user.Delete}
      create
      createFunc={user.Create}
      update
      updateFunc={user.Update}
      formContent={UserForm}
      tableColumns={userColumns}
      tableDataMapper={(rows) => {
        return rows?.map((row: any, index: number) => {
          return {
            ...row,
            roleNames: row?.roleNames
              .map((roleName: number) => roleName)
              .join(", "),
          };
        });
      }}
      FromDataMapper={(data: any) => {
        return {
          ...data,
          roleNames: data?.roleNames.map(
            (roleName: number) => UserType[roleName]
          ),
        };
      }}
      FormSubmitMapper={(data: any) => {
        return {
          ...data,
          roleNames: data?.roleNames.map(
            (roleName: number) => UserType[roleName]
          ),
        };
      }}
    />
  );
}
