import React from "react";
import ReactDOM from "react-dom/client";
import App from "./App";
import reportWebVitals from "./reportWebVitals";
import "./styles/index.scss";
import { QueryClient, QueryClientProvider } from "react-query";
import { notify } from "./components/notification";

const queryClient = new QueryClient({
  defaultOptions: {
    queries: {
      refetchOnWindowFocus: false,
      onError: (err: any) => {
        notify(
          "danger",
          "Error",
          "Some Error Happend",
          err.response.data?.error?.message
        );
        if (err.response.status == 401) window.location.href = "/logout";
      },
    },
    mutations: {
      onSuccess: () => {
        notify("success", "ok", "Success", "Success");
      },
      onError: (err: any) => {
        if (err.response.data?.error?.validationErrors !== null) {
          err.response.data?.error?.validationErrors.forEach((error: any) => {
            if (error.members && error.members.length > 0) {
              error.members.forEach((member: any) => {
                notify(
                  "danger",
                  "error",
                  "someErrorHappend",
                  `${error.message} (${member})`
                );
              });
            } else {
              notify("danger", "error", "someErrorHappend", error.message);
            }
          });
        } else {
          notify(
            "danger",
            "error",
            "someErrorHappend",
            err.response.data?.error?.message
          );
        }
      },
    },
  },
});

const root = ReactDOM.createRoot(
  document.getElementById("root") as HTMLElement
);

root.render(
  <React.StrictMode>
    <QueryClientProvider client={queryClient}>
      <App />
    </QueryClientProvider>
  </React.StrictMode>
);

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();
