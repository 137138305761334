import "styles/input/index.scss";
import { Input as AntInput } from "antd";
import { FunctionComponent } from "react";
import { useAnimation } from "framer-motion";
import {
  defaultTranstion,
  maxBackgroundWidth,
  minBackgroundWidth,
} from "constants/framer";
import { Controller } from "react-hook-form";
import FromElemnetWrapper from "components/form-element-wrapper";
import { EyeInvisibleOutlined, EyeTwoTone } from "@ant-design/icons";
interface InputProps {
  name: string;
  control: any;
  rules?: any;
  placeholder?: string;
  label: string;
  disabled?: boolean;
  type?: "text" | "password" | "email" | "tel" | "url";
  onChange?: (value: any) => void;
  defaultValue?: any;
  value?: any;
}
const Input: FunctionComponent<InputProps> = ({
  name,
  control,
  rules,
  placeholder,
  label,
  disabled,
  type,
  onChange: onPropsChange,
  defaultValue,
  value,
}) => {
  const focusController = useAnimation();
  const errorController = useAnimation();
  return (
    <>
      <FromElemnetWrapper
        required={rules.required?.value}
        focusController={focusController}
        errorController={errorController}
        label={label}
      >
        <Controller
          control={control}
          name={name}
          rules={rules}
          defaultValue={defaultValue}
          render={({
            field: { onChange, onBlur, value, name, ref },
            fieldState: { invalid, error },
          }) => {
            if (invalid) {
              errorController.start(maxBackgroundWidth);
            } else {
              errorController.start(minBackgroundWidth, {
                ease: defaultTranstion.ease.reverse(),
              });
            }
            if (type === "password") {
              return (
                <div className="relative">
                  <AntInput.Password
                    onFocus={() => {
                      focusController.start(maxBackgroundWidth);
                    }}
                    onChange={(v) => {
                      onPropsChange && onPropsChange(v.target.value);
                      onChange(v);
                    }}
                    aria-label={label}
                    value={value}
                    ref={ref}
                    id={name}
                    iconRender={(visible) =>
                      visible ? <EyeTwoTone /> : <EyeInvisibleOutlined />
                    }
                    className={
                      invalid ? "ant-custom-input-error" : "ant-custom-input"
                    }
                    onBlur={() => {
                      onBlur();
                      focusController.start(minBackgroundWidth);
                    }}
                    placeholder={placeholder}
                    disabled={disabled}
                  />
                  {invalid && (
                    <p className="form-element-error">{error?.message}</p>
                  )}
                </div>
              );
            }
            return (
              <div className="relative">
                <AntInput
                  onFocus={() => {
                    focusController.start(maxBackgroundWidth);
                  }}
                  type={type}
                  onChange={(v) => {
                    onPropsChange && onPropsChange(v.target.value);
                    onChange(v);
                  }}
                  aria-label={label}
                  value={value ? value : defaultValue}
                  name={name}
                  ref={ref}
                  id={name}
                  defaultValue={defaultValue}
                  className={
                    invalid ? "ant-custom-input-error" : "ant-custom-input"
                  }
                  onBlur={() => {
                    onBlur();
                    focusController.start(minBackgroundWidth);
                  }}
                  placeholder={placeholder}
                  disabled={disabled}
                />
                {invalid && (
                  <p className="form-element-error">{error?.message}</p>
                )}
              </div>
            );
          }}
        />
      </FromElemnetWrapper>
    </>
  );
};

export default Input;
