import { atom } from "recoil";

export const currentIndexStepAtom = atom<number>({
  key: "currentIndexStep",
  default: 1,
});

export const refetchMedicationDataAtom = atom<boolean>({
  key: "refetchMedicationData",
  default: false,
});

export const currentIndexStepPaAtom = atom<number>({
  key: "currentIndexStepPa",
  default: 0,
});

export const paAtom = atom<any>({
  key: "pa",
  default: {},
});
