import React from "react";
import { Col, Row } from "antd";
import FormElement from "components/form-element";
import { IFormProps } from "models/baseModels/form-props";
import { useQuery } from "react-query";
import practitioner from "api/practitioner";
import moment from "moment";
import { dateFormatDateCalender } from "constants/index";

const PatientForm: React.FC<IFormProps> = ({ control, disabled, type }) => {
  const sharedProps = {
    control,
    disabled,
  };

  const getAllPractitioner = useQuery(["practitioner"], () =>
    practitioner.getAll({})
  );

  return (
    <Row>
      <Col span={12}>
        <FormElement
          {...sharedProps}
          label="Practitioner"
          type="select"
          name="practitionerId"
          options={getAllPractitioner?.data?.items?.map((item: any) => {
            return {
              label: item.name + " " + item.surname,
              value: item.id,
            };
          })}
          required
        />
      </Col>
      <Col span={12}>
        <FormElement
          {...sharedProps}
          label="Date"
          type="datePicker"
          defaultValue={moment(new Date()).format(dateFormatDateCalender)}
          name="date"
          required
        />
      </Col>
      <Col span={24}>
        <FormElement
          {...sharedProps}
          label="Reason For Assessment"
          type="textarea"
          name="reasonForAssessment"
          required
        />
      </Col>
    </Row>
  );
};

export default PatientForm;
