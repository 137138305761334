import { Button, Card, Col, Row, Tooltip } from "antd";
import "./assesment.scss";
import { useMutation } from "react-query";
import assessment from "api/assessment";
import { notify } from "components/notification";
import { useRecoilState } from "recoil";
import { refetchMedicationDataAtom } from "atoms/atoms";
import TableHoverCriterion from "./table-hover-criterion";
import CardAssessment from "components/card-assessment";
import { CardDto } from "models/assessment-step";

const StepFour = ({ data, assessmentId, getAssessment }: any) => {
  const [refetchMedicationData, setRefetchMedicationData] = useRecoilState(
    refetchMedicationDataAtom
  );

  const ConfirmMutate = () => {
    Confirm.mutate({
      id: assessmentId,
    });
  };

  const Confirm = useMutation(assessment.updateStepFourAssessment, {
    onSuccess: (data) => {
      setRefetchMedicationData(!refetchMedicationData);
      notify("success", "ok", "Success", "Update Step Four");
    },
  });

  return (
    <div className="one-step">
      <Row>
        <Col flex={3}>
          <Row>
            {" "}
            <p className="one-step-header-left">BACS Scoring System</p>{" "}
          </Row>
        </Col>
      </Row>
      <Row gutter={[8, 8]} className="row-card-asess">
        {data?.cardDtos?.map((card: CardDto, index: number) => (
          <Tooltip
            title={
              <p>
                <TableHoverCriterion
                  criteria={card.cardElement.title}
                  getAssessment={getAssessment}
                />
              </p>
            }
          >
            <CardAssessment
              numberCol={4}
              titleCard={<>{card.cardElement.title}</>}
              titleHover={
                <p>
                  <TableHoverCriterion
                    criteria={card.cardElement.title}
                    getAssessment={getAssessment}
                  />
                </p>
              }
              card={card}
              index={index}
              cardDtos={data?.cardDtos}
            />
          </Tooltip>
        ))}
      </Row>
      <Row className="row-asses-f">
        <Col span={6}>
          <Button
            className="full-width"
            type="primary"
            onClick={ConfirmMutate}
            loading={Confirm.isLoading}
          >
            Confirm Scores
          </Button>
        </Col>
      </Row>
    </div>
  );
};
export default StepFour;
