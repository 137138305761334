import { Button, Card, Col, Row } from "antd";
import pa from "api/pa";
import { FunctionComponent } from "react";
import { useMutation } from "react-query";

interface IPaStepFiveProps {
  paId: number;
}

const PaStepFive: FunctionComponent<IPaStepFiveProps> = ({ paId }) => {
  const paUpdateMutation = useMutation(pa.paUpdateStepFive);

  const handleConfirm = () => {
    paUpdateMutation.mutate({
      id: Number(paId),
    });
  };

  return (
    <>
      <Row align="middle" justify="end">
        <Col span={3}>
          <Button
            type="primary"
            className="mb-3 mt-2"
            onClick={handleConfirm}
            loading={paUpdateMutation.isLoading}
          >
            Confirm
          </Button>
        </Col>
      </Row>
    </>
  );
};

export default PaStepFive;
