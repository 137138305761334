import { Button, Tooltip } from "antd";
import { Edit2, Trash2 } from "react-feather";
import colors from "constants/colors";
import { columnsInterface } from "models/baseModels/table-columns";
import { IScaffoldCustomAction } from "models/baseModels/custom-actions";

export const actionsColumn = (
  events: {
    onDetailsRow: (id: string) => void;
    onUpdateRow: (id: string) => void;
    onDeleteRow: (id: string) => void;
    onCreateRow: () => void;
  },
  dataName: string,
  permissions: any,
  customActions?: IScaffoldCustomAction[],
  refetchCb?: () => void
): columnsInterface => ({
  title: "Actions",
  dataSelector: "actions",
  type: "custom",
  width: 200,
  // columnWidth: 900,

  render: (text: any, row: any, index: number) => (
    <div>
      {/* ==== Delete ==== */}
      {permissions?.Delete && (
        <Tooltip
          title={"Delete " + dataName}
          key={index}
          overlayClassName="danger-tooltip" // change class name
        >
          <Button
            onClick={() => events.onDeleteRow(row.id)}
            className="button-table-action cancelbtn"
            icon={
              <Trash2
                color={colors.dangerColor}
                className="icon-button-table"
              />
            }
          />
        </Tooltip>
      )}

      {/* ==== Update ==== */}
      {permissions?.Update && (
        <Tooltip title={"Update " + dataName} key={index}>
          <Button
            onClick={() => events.onUpdateRow(row.id)}
            className="button-table-action"
            icon={<Edit2 size="80%" className="icon-button-table edit-icon" />}
          ></Button>
        </Tooltip>
      )}

      {/* ==== Custom Actions ==== */}
      {customActions &&
        customActions.map((action, index) =>
          action.msgKey ? (
            <Tooltip title={action.tooltip} key={index}>
              <Button
                type="primary"
                onClick={() => events.onDetailsRow(row.id)}
                icon={action.Icon}
                key={index}
                className="icon-button-table"
              ></Button>
            </Tooltip>
          ) : (
            <Tooltip title={action.tooltip} key={index}>
              <Button
                key={index}
                onClick={() => {
                  action.cb(row);
                  if (action.refetch) refetchCb?.();
                }}
                icon={action.Icon}
                className="button-table-action"
              />
            </Tooltip>
          )
        )}
    </div>
  ),
});
