import { Table, TableProps } from "antd";

const TableHoverCriterion = ({ criteria, getAssessment }: any) => {
  const columns: TableProps["columns"] = [
    {
      key: "point",
      title: "Point",
      dataIndex: "point",
    },
    {
      key: "rule",
      title: "Rule",
      dataIndex: "rule",
    },
  ];

  const score = getAssessment?.data
    ? Object.keys(getAssessment?.data?.criteria).find((score: any) =>
        criteria?.toLowerCase().includes(score?.toLowerCase())
      )
    : undefined;

  const dataSourceTable = score && getAssessment?.data?.criteria[score].rules;

  return (
    <Table
      className="table-hover"
      columns={columns}
      dataSource={dataSourceTable}
      pagination={false}
      title={() => <p className="font-bold"> {criteria} </p>}
    />
  );
};

export default TableHoverCriterion;
