import { Input as AntInput } from "antd";
import { useAnimation } from "framer-motion";
import { FunctionComponent } from "react";
import { Controller } from "react-hook-form";
import "styles/input/index.scss";
import {
  defaultTranstion,
  maxBackgroundWidth,
  minBackgroundWidth,
} from "constants/framer";
import FromElemnetWrapper from "components/form-element-wrapper";
interface InputProps {
  name: string;
  control: any;
  rules?: any;
  placeholder?: string;
  label: string;
  disabled?: boolean;
  onChange?: (value: any) => void;
  defaultValue?: any;
}
const { TextArea: AntTextArea } = AntInput;
const TextArea: FunctionComponent<InputProps> = ({
  name,
  control,
  rules,
  placeholder,
  label,
  disabled,
  onChange: onPropsChange,
  defaultValue,
}) => {
  const focusController = useAnimation();
  const errorController = useAnimation();
  return (
    <>
      <FromElemnetWrapper
        focusController={focusController}
        errorController={errorController}
        label={label}
        required={rules.required?.value}
      >
        <Controller
          control={control}
          name={name}
          rules={rules}
          defaultValue={defaultValue}
          render={({
            field: { onChange, onBlur, value, name, ref },
            fieldState: { invalid, error },
          }) => {
            if (invalid) {
              errorController.start(maxBackgroundWidth);
            } else {
              errorController.start(minBackgroundWidth, {
                ease: defaultTranstion.ease.reverse(),
              });
            }
            return (
              <div className="relative">
                <AntTextArea
                  onFocus={() => {
                    focusController.start(maxBackgroundWidth);
                  }}
                  onChange={(v) => {
                    onPropsChange && onPropsChange(v.target.value);
                    onChange(v);
                  }}
                  value={value ? value : defaultValue}
                  ref={ref}
                  id={name}
                  rows={1}
                  maxLength={1000}
                  className={
                    invalid ? "ant-custom-input-error" : "ant-custom-input"
                  }
                  onBlur={() => {
                    onBlur();
                    focusController.start(minBackgroundWidth);
                  }}
                  placeholder={placeholder}
                  disabled={disabled}
                  autoSize={{
                    minRows: 1,
                    maxRows: 3,
                  }}
                />
                {invalid && (
                  <p className="form-element-error">{error?.message}</p>
                )}
              </div>
            );
          }}
        />
      </FromElemnetWrapper>
    </>
  );
};

export default TextArea;
