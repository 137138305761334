import { Button, Card, Col, Row } from "antd";
import pa from "api/pa";
import { FunctionComponent } from "react";
import { useMutation, useQueryClient } from "react-query";

interface IPaStepTwoProps {
  paId: number;
  data?: any;
}

const PaStepTwo: FunctionComponent<IPaStepTwoProps> = ({ paId, data }) => {
  const paUpdateMutation = useMutation(pa.paUpdateStepTwo, {
    onSuccess() {
      queryClient.invalidateQueries(["pa"]);
    },
  });
  const queryClient = useQueryClient();

  const handleConfirm = () => {
    paUpdateMutation.mutate({
      id: Number(paId),
    });
  };

  return (
    <>
      <Row gutter={[8, 8]} align="bottom" justify="center">
        <Col span={10}>
          <Card title="Primary Insurance" size="small" className="pa-card">
            <Row>
              {" "}
              <Col className="title-ac">Payer: </Col>{" "}
              <Col className="ml-2"> {data?.primaryMemberPlan?.payerName}</Col>{" "}
            </Row>
            <Row>
              {" "}
              <Col className="title-ac">Plan: </Col>{" "}
              <Col className="ml-2"> {data?.primaryMemberPlan?.planName}</Col>{" "}
            </Row>
            <Row>
              <Col className="title-ac"> Member Id: </Col>{" "}
              <Col className="ml-2"> {data?.primaryMemberPlan?.memberId}</Col>
              <Col className="title-ac"> , RxBin: </Col>{" "}
              <Col className="ml-2"> {data?.primaryMemberPlan?.rxBin}</Col>
            </Row>
          </Card>
        </Col>
        <Col span={10}>
          <Card title="Secondary Insurance" size="small" className="pa-card">
            <Row>
              {" "}
              <Col className="title-ac">Payer: </Col>{" "}
              <Col className="ml-2">
                {" "}
                {data?.secondaryMemberPlan?.payerName}
              </Col>{" "}
            </Row>
            <Row>
              {" "}
              <Col className="title-ac">Plan: </Col>{" "}
              <Col className="ml-2"> {data?.secondaryMemberPlan?.planName}</Col>{" "}
            </Row>
            <Row>
              <Col className="title-ac">Member Id: </Col>{" "}
              <Col className="ml-2"> {data?.secondaryMemberPlan?.memberId}</Col>
              <Col className="title-ac"> , RxBin: </Col>{" "}
              <Col className="ml-2"> {data?.secondaryMemberPlan?.rxBin}</Col>
            </Row>
          </Card>
        </Col>
        <Col span={2}>
          <Button
            type="primary"
            onClick={handleConfirm}
            loading={paUpdateMutation.isLoading}
          >
            Confirm
          </Button>
        </Col>
      </Row>
    </>
  );
};

export default PaStepTwo;
