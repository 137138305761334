import { Col, Row, Table as AntTable, Tag, Input, Spin, Button } from "antd";
import { ColumnsType } from "antd/lib/table";
// import { useAnimation } from 'framer-motion';
import _ from "lodash";
import { FunctionComponent, KeyboardEventHandler, useRef } from "react";
import { columnsInterface } from "models/baseModels/table-columns";
import "styles/input/index.scss";
import "styles/table/index.scss";
import {
  FilterValue,
  SorterResult,
  TableCurrentDataSource,
  TablePaginationConfig,
} from "antd/lib/table/interface";
import { useAnimation } from "framer-motion";
import { maxBackgroundWidth, minBackgroundWidth } from "constants/framer";
import moment from "moment";
import { dateFormatMonth, dateTimeFormatMonth } from "constants/index";
import { Link } from "react-router-dom";
import { LinkOutlined } from "@ant-design/icons";
import { ValueLabelDto } from "models/baseModels/value-label";
import FromElemnetWrapper from "components/form-element-wrapper";
import LoaderApp from "components/loader";

function tagFilter(data: Object[], colName: string) {
  return data
    ?.map((item: any) => item[colName].name)
    .filter((value, index, self) => self.indexOf(value) === index)
    .map((value: any) => {
      return { text: value, value };
    })
    .sort();
}

interface TableProps {
  columns: columnsInterface[];
  filterOptions?: ValueLabelDto[];
  data: Object[];
  onSearchchange?: (search: string) => void;
  onChange?: (
    pagination: TablePaginationConfig,
    filters: Record<string, FilterValue | null>,
    sorter: SorterResult<Object> | SorterResult<Object>[],
    extra: TableCurrentDataSource<Object>
  ) => void;
  onRow?: (
    record: any,
    index?: number
  ) => React.HTMLAttributes<HTMLTableRowElement>;
  tableHeaderActions?: JSX.Element;
  loading?: boolean;
  total: number;
  dataName?: string;
  modelContent?: JSX.Element;
  defaultPageSize?: number;
  pageSizeOptions?: number[];
  width?: number;
  columnWidth?: string | number;
}
const Table: FunctionComponent<TableProps> = ({
  columns,
  data,
  onChange,
  onSearchchange,
  onRow,
  tableHeaderActions,
  loading,
  total,
  defaultPageSize,
  pageSizeOptions,
  dataName,
  modelContent,
  width,
}) => {
  const focusController = useAnimation();
  const inputRef = useRef("");

  const newcolumns: ColumnsType<Object> | any = columns.map((col: any) => ({
    render:
      col.type === "custom"
        ? col.render
        : (value: any, row: any) => {
            switch (col.type) {
              case "date":
                return value ? (
                  // moment(value).format(dateFormatMonth)
                  moment.utc(value).format(dateFormatMonth)
                ) : (
                  // <FormattedDate
                  //     value={value}
                  //     dateStyle='medium'
                  // />
                  <div className="text-center">-</div>
                );
              case "dateTime":
                return value && moment.utc(value).format(dateTimeFormatMonth);
              case "number":
                return value;
              case "string":
                return value;
              case "select":
                return value?.label;
              case "link":
                return (
                  <a href={value} target="_blank">
                    {" "}
                    <Button icon={<LinkOutlined />} />{" "}
                  </a>
                );
              case "tag":
                return (
                  <Tag color={col.tagcolor} key={value}>
                    {value}
                  </Tag>
                );
              case "custom":
              default:
                return value;
            }
          },
    title: col.title,
    dataIndex: col.dataSelector,
    key: col.dataSelector,
    sorter: col.sort,
    filterSearch: true,
    filters:
      col.valueType === "tag"
        ? tagFilter(data, col.dataSelector)
        : col.filterType === "select"
        ? _.uniqBy(data, col.dataSelector).map(
            ({ [col.dataSelector]: value }: any) => {
              return { text: value?.toString(), value };
            }
          )
        : col.filters,
    onFilter:
      col.onFilter ??
      ((value: string, record: any) => record[col.dataSelector] === value),
    filterMultiple: false,
    alignContent: col.align,
    width: col.width,
    columnWidth: col.columnWidth,
    onCell: (row: any) => {
      return {
        onClick: () => col.onCellClick && col.onCellClick(row), // click row
      };
    },
    /*  ...(col.filterType === 'search' &&
                 getColumnSearchProps(col.dataSelector, col.render)), */
  }));

  const handleKeyboard: KeyboardEventHandler<HTMLInputElement> = (event) => {
    if (event.key === "Enter") {
      onSearchchange && onSearchchange(inputRef.current);
    }
  };

  return (
    <>
      <Row justify="center">
        <Col span={12}>
          <FromElemnetWrapper focusController={focusController}>
            <Input
              onFocus={() => {
                focusController.start(maxBackgroundWidth);
              }}
              onChange={(e) => {
                inputRef.current = e.target.value;
              }}
              onKeyDown={handleKeyboard}
              className={"input-search-t"}
              onBlur={() => {
                focusController.start(minBackgroundWidth);
              }}
              placeholder={dataName ? "Search In " + `${dataName}` : "Search"}
            />
          </FromElemnetWrapper>
        </Col>
        {tableHeaderActions && <Col span={8}>{tableHeaderActions}</Col>}
      </Row>
      {/* <Row className='w-full content-between mb-4' >
                <Col span={16}>
                    <Row>
                        <FromElemnetWrapper
                            focusController={focusController}
                        >
                            <div className='relative'>
                                <Input
                                    onFocus={() => {
                                        focusController.start(
                                            maxBackgroundWidth,
                                        );
                                    }}
                                    onChange={(e) => {
                                        // onsearchchange &&
                                        // onsearchchange(e.target.value)
                                    }
                                    }
                                    className={'ant-custom-input'}
                                    onBlur={() => {
                                        focusController.start(
                                            minBackgroundWidth,
                                        );
                                    }}
                                    
                                    placeholder={
                                        dataName
                                            ? 'SearchIn ' + `${dataName}s`
                                            : 'searchTheTableData'
                                    }
                                />
                            </div>
                        </FromElemnetWrapper>
                    </Row>
                </Col>
                <Col span={8}>{tableHeaderActions}</Col>
            </Row> */}
      <AntTable
        scroll={{ x: true }}
        // size={'middle'}
        bordered
        dataSource={data}
        columns={newcolumns}
        onChange={onChange}
        loading={{
          indicator: (
            <div className="">
              <LoaderApp />
            </div>
          ),
          spinning: loading,
        }}
        onRow={(record) => (onRow ? onRow(record) : {})}
        pagination={{
          total,
          showSizeChanger: true,
          defaultPageSize: defaultPageSize ?? 10,
          pageSizeOptions: pageSizeOptions,
        }}
      />
    </>
  );
};
export default Table;
