import config from "./config";
import ApiService from "./baseApi/api-service";
import {
  PlanCreateDto,
  PlanDto,
  PlanGetAllParamsDto,
  PlanPageDto,
  PlanUpdateDto,
} from "models/plan";
import { ValueLabelDto } from "models/baseModels/value-label";
import { PagedResultDto } from "models/baseModels/paged-result";
class Plan extends ApiService {
  constructor() {
    super({ baseURL: config.baseUrl, headers: config.headers });
  }
  /* @tags Plan */
  /* @name PlanGet */
  /* @request GET:/api/services/app/Plan/Get */
  public Get = (params: { id?: number }): Promise<PlanDto> => {
    return this.get("/Plan/Get", {
      params,
    });
  };

  /* @tags Plan */
  /* @name PlanGetAll */
  /* @request GET:/api/services/app/Plan/GetAll */
  public getAll = (params: PlanGetAllParamsDto): Promise<PlanPageDto> => {
    return this.get("/Plan/GetAll", {
      params,
    });
  };

  public getAllLite = (
    params: PlanGetAllParamsDto
  ): Promise<PagedResultDto<ValueLabelDto>> => {
    return this.get("/Plan/GetAllLite", {
      params,
    });
  };

  /* @tags Plan */
  /* @name inputOutputCreate */
  /* @request POST:/api/services/app/Plan/Create */
  public Create = (params: PlanCreateDto): Promise<PlanDto> => {
    return this.post("/Plan/Create", {
      ...params,
    });
  };

  /* @tags Plan */
  /* @name inputOutputUpdate */
  /* @request PUT:/api/services/app/Plan/Update */
  public Update = (params: PlanUpdateDto): Promise<PlanDto> => {
    return this.put(`/Plan/Update`, {
      ...params,
    });
  };

  /* @tags Plan */
  /* @name inputOutputDelete */
  /* @request POST:/api/services/app/Plan/Delete */
  public Delete = (params: { id: number }): Promise<any> => {
    return this.delete(`/Plan/Delete`, { params });
  };
}
const plan = new Plan();
export default plan;
