import "./assesment.scss";
import { Button, Col, Row } from "antd";
import { TreatmentType } from "constants/enums";
import { useForm } from "react-hook-form";
import { useMutation, useQueryClient } from "react-query";
import assessment from "api/assessment";
import { notify } from "components/notification";
import FormElement from "components/form-element";
import _ from "lodash";
import LableColor from "components/lable-color";
import { useRecoilState } from "recoil";
import { refetchMedicationDataAtom } from "atoms/atoms";
import { Plus } from "react-feather";
import colors from "constants/colors";
import { useEffect, useState } from "react";
import ModalCondition from "./modal-condition";
import { mapEnumToSelectValues } from "utils/map-enum";
import CardAssessment from "components/card-assessment";
import { CardDto, UpdateStepThreeDto } from "models/assessment-step";

const StepThree = ({ data, assessmentId }: any) => {
  const [refetchMedicationData, setRefetchMedicationData] = useRecoilState(
    refetchMedicationDataAtom
  );
  const [formVisable, setFormVisable] = useState<boolean>(false);
  const [cardInfo, setCardInfo] = useState<any>();
  const queryClient = useQueryClient();

  const { control, reset, handleSubmit, setValue } =
    useForm<UpdateStepThreeDto>({
      mode: "onBlur",
      // defaultValues: {
      // treatments: !_.isEmpty(data?.treatments)
      //   ? data.treatments?.map((item: string) => Number(item))
      //   : data.suggestedTreatments?.map((item: string) => Number(item)),
      // },
      shouldFocusError: true,
    });

  const sharedProps = {
    control,
  };

  const OnSubmitResetValue = () => {
    setValue?.(
      "treatments",
      data.suggestedTreatments?.map((item: string) => Number(item))
    );
  };

  const ConfirmMutate = (data: UpdateStepThreeDto) => {
    Confirm.mutate({
      ...data,
      id: assessmentId,
    });
  };

  const Confirm = useMutation(assessment.updateStepThreeAssessment, {
    onSuccess: (data) => {
      setRefetchMedicationData(!refetchMedicationData);
      notify("success", "ok", "Success", "Update Step Three");
      queryClient.invalidateQueries(["assessmentStep"]);
    },
  });

  useEffect(() => {
    setValue?.(
      "treatments",
      !_.isEmpty(data?.treatments)
        ? data.treatments?.map((item: string) => Number(item))
        : null
    );
  }, [assessmentId, data]);

  return (
    <div className="one-step">
      <ModalCondition
        setFormVisable={setFormVisable}
        formVisable={formVisable}
        cardInfo={cardInfo}
      />
      <Row>
        <Col flex={3}>
          <Row>
            {" "}
            <p className="one-step-header-left">Comorbidities</p>{" "}
          </Row>
        </Col>
        <Col flex={0.1}>
          <div className="one-step-header-right">
            <Row justify="space-between">
              {LableColor("No", "#00B050")}
              {LableColor("Yes", "#d0f444")}
              {LableColor("Empty", "#A6A6A6")}
            </Row>
          </div>
        </Col>
      </Row>
      <Row gutter={[8, 8]} className="row-card-asess">
        {data?.cardDtos?.map((card: CardDto, index: number) => {
          return (
            <CardAssessment
              numberCol={4}
              card={card}
              index={index}
              cardDtos={data?.cardDtos}
              onClickCard={() => {
                card?.cardRecords?.[0]?.value === "No" && setFormVisable(true);
                setCardInfo(card);
              }}
              titleCard={
                <Row justify="space-between">
                  <Col> {card.cardElement.title} </Col>
                  {card?.cardRecords?.[0]?.value === "No" && (
                    <Col>
                      {" "}
                      <Plus
                        className="pr-1"
                        color={colors.primaryColor}
                        size="25"
                      />{" "}
                    </Col>
                  )}
                </Row>
              }
            />
            // <Col
            //   span={8}
            //   key={index}
            //   className={`${
            //     (index + 1) % (24 / 8) != 0
            //       ? "card-asess-border"
            //       : "col-card-asess"
            //   }`}
            // >
            //   <Card
            //     title={
            //       <Row justify="space-between">
            //         <Col> {card.title} </Col>
            //         {card?.list?.[0]?.value === "NO" && (
            //           <Col>
            //             {" "}
            //             <Plus
            //               className="pr-1"
            //               color={colors.primaryColor}
            //               size="25"
            //             />{" "}
            //           </Col>
            //         )}
            //       </Row>
            //     }
            //     size={"small"}
            //     className={`card-asess ${
            //       index < data?.cards.length - 24 / 8
            //         ? "card-asess-border-down"
            //         : ""
            //     }`}
            //     onClick={() => {
            //       card?.list?.[0]?.value === "NO" && setFormVisable(true);
            //       setCardInfo(card);
            //     }}
            //   >
            //     {card.display_all === "no" ? (
            //       <p
            //         className="card-body-text"
            //         style={{
            //           color:
            //             card.list[0]?.status?.value == 0 &&
            //             card.title == "Suggested Output"
            //               ? colors.dangerColor
            //               : colors.black,
            //           fontWeight:
            //             card.list[0]?.status?.value == 0 &&
            //             card.title == "Suggested Output"
            //               ? "bold"
            //               : undefined,
            //         }}
            //       >
            //         {card.list[0]
            //           ? JSON.stringify(card.list[0]?.value)
            //               .replace(/,/g, "\n")
            //               .replace(/[{}"]/g, "")
            //           : ""}
            //       </p>
            //     ) : (
            //       <Chart
            //         chartType={
            //           card.graph == "line" ? "LineChart" : "ColumnChart"
            //         }
            //         className="card-body-chart"
            //         height="85px"
            //         width="100%"
            //         options={optionsChart(card)}
            //         data={dataChart(card)}
            //       />
            //     )}
            //   </Card>
            // </Col>
          );
        })}
      </Row>
      <Row className="row-asses-f">
        <Col span={3}>
          {" "}
          <p className="full-width"> Treatment </p>{" "}
        </Col>
        <Col span={7}>
          <FormElement
            {...sharedProps}
            // value={data?.Phenotype !== 0 ? data?.Phenotype : data?.suggested_phenotype}
            options={mapEnumToSelectValues(TreatmentType)}
            // className="full-width"
            placeholder="Allergic"
            label=""
            type="multiSelect"
            name="treatments"
            required
          />
        </Col>
        <Col span={4}>
          <Button
            className="full-width"
            onClick={() => {
              OnSubmitResetValue();
            }}
          >
            Reset to default
          </Button>
        </Col>
        <Col span={6}>
          <Button
            className="full-width"
            type="primary"
            onClick={handleSubmit(ConfirmMutate)}
            loading={Confirm.isLoading}
          >
            Confirm Treatment
          </Button>
        </Col>
      </Row>
    </div>
  );
};
export default StepThree;
