import { useParams } from "react-router-dom";
import { drugPolicyColumns } from "./data";
import DrugPolicyForm from "./forms";
import GeneralTable from "components/general-table";
import drugPolicy from "api/drug-policy";
import { BiologicType } from "constants/enums";
import { attachmentMapper } from "utils/attachment-mapper";

const DrugPolicyPage = () => {
  const { id } = useParams();

  return (
    <GeneralTable
      dataName="Drug Policy"
      headerTable="Payer Drug Policies"
      getAllFunc={() => drugPolicy.getAll({ payerId: id })}
      getFunc={drugPolicy.Get}
      delete
      deleteFunc={drugPolicy.Delete}
      create
      createFunc={drugPolicy.Create}
      update
      updateFunc={drugPolicy.Update}
      formContent={DrugPolicyForm}
      tableColumns={drugPolicyColumns}
      customOnRowClick={() => {}}
      tableDataMapper={(rows) => {
        return rows?.map((row: any, index: number) => {
          return {
            ...row,
            biologic: BiologicType[row?.biologic],
          };
        });
      }}
      FromDataMapper={(data: any) => {
        return {
          ...data,
        };
      }}
      FormSubmitMapper={(data: any) => {
        return {
          ...data,
          ...attachmentMapper(["attachment"], data, false),
          payerId: Number(id),
        };
      }}
    />
  );
};
export default DrugPolicyPage;
