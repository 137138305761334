import "styles/steps/index.scss";
import { FunctionComponent } from "react";
import { StepProps, Steps } from "antd";
interface StepsPropsDiv {
  // items: ValueLabelDto[];
  items: StepProps[];
  current: number;
  currentLable?: string;
  currentContent?: any;
  onStepClick?: (current: number) => void;
}

const StepsDiv: FunctionComponent<StepsPropsDiv> = (props) => {
  const onStepClick = (current: number) => {
    console.log(current);
  };

  return (
    <Steps
      className="steps"
      current={props.current - 1}
      items={props.items}
      onChange={props?.onStepClick}
    />
  );
};

export default StepsDiv;
