import { FunctionComponent } from "react";
import moment from "moment";
import { Avatar, Card, Col, Row } from "antd";
import { dateFormatMonth } from "constants/index";
import idIocn from "assets/svgs/patient-table/assesment/id.svg";
import usenameIocn from "assets/svgs/patient-table/assesment/user-name.svg";
import birthdayIocn from "assets/svgs/patient-table/assesment/birthday.svg";
import FemalePatient from "assets/svgs/female-patient.svg";
import MalePatient from "assets/svgs/male-patient.svg";
import { GenderType } from "constants/enums";

interface IPatientCard {
  gender: GenderType;
  name: string;
  age: number;
  birthDate: Date;
  patientId: number;
  weight?: number;
  loading?: boolean;
}

const PatientCard: FunctionComponent<IPatientCard> = ({
  gender,
  name,
  age,
  birthDate,
  patientId,
  weight,
  loading,
}) => {
  return (
    <Card size={"small"} className="patient-info" loading={loading}>
      <Row>
        <Col span={5}>
          <Avatar
            className="antd-avatar"
            size={70}
            src={
              gender == GenderType.Male
                ? MalePatient
                : gender == GenderType.Female
                ? FemalePatient
                : undefined
            }
          />
        </Col>
        <Col span={19}>
          <Row className="row-info">
            <Col span={12}>
              <p className="p-row-info">
                {" "}
                <img className="icon-h" src={usenameIocn} /> {name}{" "}
              </p>
            </Col>
            <Col span={12}>
              {" "}
              <p className="p-row-info">
                {" "}
                {age} Years , {GenderType[gender]} , {weight}
              </p>
            </Col>
          </Row>
          <Row className="row-info">
            <Col span={12}>
              <p className="p-row-info">
                <img className="icon-h" src={birthdayIocn} />{" "}
                {moment(birthDate)?.format(dateFormatMonth)}
              </p>
            </Col>
            <Col span={12}>
              <p className="p-row-info">
                <img className="icon-h" src={idIocn} /> Id: {patientId}
              </p>
            </Col>
          </Row>
          <Row>
            <Col span={12}>
              {" "}
              <p className="p-row-info">Severe Asthma</p>
            </Col>
          </Row>
        </Col>
      </Row>
    </Card>
  );
};

export default PatientCard;
