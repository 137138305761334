import { useState } from "react";
import GeneralFormContainer, {
  IFormError,
} from "components/general-form-containner";
import BasicModal from "components/modal";
import observation from "api/observation";
import { notify } from "components/notification";
import { useMutation, useQueryClient } from "react-query";
import { useParams } from "react-router-dom";
import ObservationForm from "../observation/forms";
import moment from "moment";
import { dateFormatDateCalender, dateFormatMonth } from "constants/index";
import { Col, Row } from "antd";

const ModalObservation = ({
  formVisable,
  setFormVisable,
  cardInfo,
  getAssessment,
}: any) => {
  const { patientId, id: assessmentId } = useParams();
  const queryClient = useQueryClient();

  const toggleForm = () => {
    setFormVisable(false);
  };

  const onSubmit = useMutation(observation.Create, {
    onSuccess: () => {
      notify("success", "ok", "Success");
      toggleForm();
      queryClient.invalidateQueries(["assessmentStepOne"]);
      queryClient.invalidateQueries(["assessmentStepTwo"]);
    },
  });

  const formContent = () => (
    <GeneralFormContainer
      dataName="Observation"
      type="Create"
      content={ObservationForm}
      actionLoading={onSubmit.isLoading}
      toggleContent={toggleForm}
      onSubmit={(data: any) => {
        onSubmit.mutate({
          ...data,
          patientId: patientId,
          assessmentId: assessmentId,
        });
      }}
      defaultValues={{
        observationCategory:
          cardInfo?.cardElement?.observationConditionCategory,
        observationCode: cardInfo?.cardElement?.observationCode,
        issuedAt: moment(getAssessment?.data?.date).format(
          dateFormatDateCalender
        ),
      }}
    />
  );

  return (
    <BasicModal
      header={
        <Row align="middle">
          <Col md={{ span: 12 }}>
            <h2 className="text-primary">Create Observation </h2>
          </Col>
          <Col>
            <h3 className="text-primary">
              Asessment Date
              {": " + moment(getAssessment?.data?.date).format(dateFormatMonth)}
            </h3>
          </Col>
        </Row>
      }
      isOpen={formVisable}
      closable
      handleCancel={toggleForm}
      afterClose={toggleForm}
      width={"60vw"}
      footer={false}
      content={formContent()}
    />
  );
};
export default ModalObservation;
