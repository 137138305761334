import config from "./config";
import ApiService from "./baseApi/api-service";
import {
  ConditionCreateDto,
  ConditionDto,
  ConditionGetAllParamsDto,
  ConditionPageDto,
  ConditionUpdateDto,
} from "models/condition";
import { PagedResultDto } from "models/baseModels/paged-result";
import { ValueLabelDto } from "models/baseModels/value-label";
class Condition extends ApiService {
  constructor() {
    super({ baseURL: config.baseUrl, headers: config.headers });
  }
  /* @tags Condition */
  /* @name ConditionGet */
  /* @request GET:/api/services/app/Condition/Get */
  public Get = (params: { id?: number }): Promise<ConditionDto> => {
    return this.get("/Condition/Get", {
      params,
    });
  };

  /* @tags Condition */
  /* @name ConditionGetAll */
  /* @request GET:/api/services/app/Condition/GetAll */
  public getAll = (
    params: ConditionGetAllParamsDto
  ): Promise<ConditionPageDto> => {
    return this.get("/Condition/GetAll", {
      params,
    });
  };

  public getAllLite = (
    params: ConditionGetAllParamsDto
  ): Promise<PagedResultDto<ValueLabelDto>> => {
    return this.get("/Condition/GetAllLite", {
      params,
    });
  };

  /* @tags Condition */
  /* @name inputOutputCreate */
  /* @request POST:/api/services/app/Condition/Create */
  public Create = (params: ConditionCreateDto): Promise<ConditionDto> => {
    return this.post("/Condition/Create", {
      ...params,
    });
  };

  /* @tags Condition */
  /* @name inputOutputUpdate */
  /* @request PUT:/api/services/app/Condition/Update */
  public Update = (params: ConditionUpdateDto): Promise<ConditionDto> => {
    return this.put(`/Condition/Update`, {
      ...params,
    });
  };

  /* @tags Condition */
  /* @name inputOutputDelete */
  /* @request POST:/api/services/app/Condition/Delete */
  public Delete = (params: { id: number }): Promise<any> => {
    return this.delete(`/Condition/Delete`, { params });
  };
}
const condition = new Condition();
export default condition;
