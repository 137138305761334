import { currentIndexStepPaAtom, paAtom } from "atoms/atoms";
import { defaultLayoutPlugin } from "@react-pdf-viewer/default-layout";
import { Viewer, Worker } from "@react-pdf-viewer/core";
import ActionRight from "./components/row-action/action-right";
import LoaderApp from "components/loader";
import StepAction from "./components/row-action/step-action";
import GuidPanel from "./components/row-body/guid-panel";
import TopRight from "./components/row-header/top-right";
import { useParams } from "react-router-dom";
import TopLeft from "./components/row-header/top-left";
import { useQuery } from "react-query";
import { useRecoilState } from "recoil";
import pa from "api/pa";
import { Col, Row } from "antd";
import PaStepFour from "./components/row-action/steps/step-four";
import "@react-pdf-viewer/core/lib/styles/index.css";
import "@react-pdf-viewer/default-layout/lib/styles/index.css";
import "./index.scss";

type RouteParams = {
  id: string;
  biologic: any;
};

export default function PaDashboardPage() {
  const { id } = useParams<RouteParams>();

  const [_, setPaAtom] = useRecoilState(paAtom);
  const [currentIndex, _s] = useRecoilState(currentIndexStepPaAtom);
  const defaultLayoutPluginInstance = defaultLayoutPlugin();

  const getPa = useQuery(["pa", currentIndex], () => pa.Get({ id: id }), {
    onSuccess(data) {
      // if(!id) setCurrentIndex(data.status);
      setPaAtom(data);
    },
    enabled: !!id,
  });

  return (
    <LoaderApp loading={getPa.isLoading}>
      <Row
        gutter={[16, 16]}
        className="row-section"
        justify="center"
        align="middle"
      >
        <Col span={18}>
          <TopLeft data={getPa?.data} />
        </Col>
        <Col span={6}>
          <TopRight data={getPa?.data} />
        </Col>
      </Row>

      <Row
        gutter={[16, 16]}
        className="row-section"
        justify="center"
        align="middle"
      >
        <Col span={18}>
          <StepAction data={getPa?.data} paId={Number(id)} />
        </Col>
        <Col span={6}>
          <ActionRight data={getPa?.data} />
        </Col>
      </Row>

      <Row gutter={16}>
        <Col span={18}>
          {/* {paInfo.data && paData.attachment && currentIndex > 0 && ( */}
          {getPa?.data?.patientForm?.url && currentIndex > 4 && (
            <Worker workerUrl="https://unpkg.com/pdfjs-dist@3.4.120/build/pdf.worker.js">
              <div
                style={{
                  height: "750px",
                  // maxWidth: "900px",
                  marginLeft: "auto",
                  marginRight: "auto",
                }}
              >
                <Viewer
                  fileUrl={getPa?.data?.patientForm.url}
                  plugins={[defaultLayoutPluginInstance]}
                />
              </div>
            </Worker>
          )}
        </Col>
        {currentIndex == 4 && (
          <Col span={18}>
            <PaStepFour paId={Number(id)} />
          </Col>
        )}
        <Col span={6}>
          <GuidPanel guideData={getPa?.data?.guideInfo} />
        </Col>
      </Row>
    </LoaderApp>
  );
}
