import config from "./config";
import ApiService from "./baseApi/api-service";
import {
  ObservationCreateDto,
  ObservationDto,
  ObservationGetAllParamsDto,
  ObservationPageDto,
  ObservationUpdateDto,
} from "models/observation";
class Observation extends ApiService {
  constructor() {
    super({ baseURL: config.baseUrl, headers: config.headers });
  }

  /* @tags Observation */
  /* @name ObservationGet */
  /* @request GET:/api/services/app/Observation/Get */
  public Get = (params: { id?: number }): Promise<ObservationDto> => {
    return this.get("/Observation/Get", {
      params,
    });
  };

  /* @tags Observation */
  /* @name ObservationGetAll */
  /* @request GET:/api/services/app/Observation/GetAll */
  public getAll = (
    params: ObservationGetAllParamsDto
  ): Promise<ObservationPageDto> => {
    return this.get("/Observation/GetAll", {
      params,
    });
  };

  /* @tags Observation */
  /* @name inputOutputCreate */
  /* @request POST:/api/services/app/Observation/Create */
  public Create = (params: ObservationCreateDto): Promise<ObservationDto> => {
    return this.post("/Observation/Create", {
      ...params,
    });
  };

  /* @tags Observation */
  /* @name inputOutputUpdate */
  /* @request PUT:/api/services/app/Observation/Update */
  public Update = (params: ObservationUpdateDto): Promise<ObservationDto> => {
    return this.put(`/Observation/Update`, {
      ...params,
    });
  };

  /* @tags Observation */
  /* @name inputOutputDelete */
  /* @request POST:/api/services/app/Observation/Delete */
  public Delete = (params: { id: number }): Promise<any> => {
    return this.delete(`/Observation/Delete`, { params });
  };

  public getAllTypes = (params: { category?: number }): Promise<any> => {
    return this.get("/Observation/GetAllTypes", {
      params,
    });
  };
}
const observation = new Observation();
export default observation;
