import { Col, Row } from "antd";
import PatientInfo from "../components/patient-info";
import SidePage from "../components/side";
import _ from "lodash";
import { useParams } from "react-router-dom";
import { useQuery } from "react-query";
import assessment from "api/assessment";
import { useRecoilState } from "recoil";
import { currentIndexStepAtom } from "atoms/atoms";
import BodyPage from "../components/body";
import "../index.scss";

const AssessmentDetailsPage = () => {
  const [_c, setCurrentIndex] = useRecoilState(currentIndexStepAtom);
  const { id: assessmentId } = useParams();

  const getAssessment = useQuery(
    ["assessment step", assessmentId],
    () => assessment.Get({ id: Number(assessmentId) }),
    {
      enabled: !!assessmentId,
      onSuccess(data) {
        setCurrentIndex(data?.currentStep);
      },
    }
  );

  return (
    <>
      <PatientInfo getAssessment={getAssessment} />

      <Row justify="space-between" className="b-s-page">
        <Col span={18}>
          <BodyPage getAssessment={getAssessment} />
        </Col>
        <Col span={6}>
          <SidePage getAssessment={getAssessment} />
        </Col>
      </Row>
    </>
  );
};

export default AssessmentDetailsPage;
