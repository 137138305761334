import config from "./config";
import ApiService from "./baseApi/api-service";
import {
  AssessmentCreateDto,
  AssessmentDto,
  AssessmentGetAllParamsDto,
  AssessmentPageDto,
} from "models/assessment";
import {
  AssessmentStepFourDto,
  AssessmentStepOneDto,
  AssessmentStepThreeDto,
  AssessmentStepTwoDto,
  UpdateStepFourDto,
  UpdateStepOneDto,
  UpdateStepThreeDto,
  UpdateStepTwoDto,
} from "models/assessment-step";

class Assessment extends ApiService {
  constructor() {
    super({ baseURL: config.baseUrl, headers: config.headers });
  }

  /* @tags Condition */
  /* @name ConditionGet */
  /* @request GET:/api/services/app/Condition/Get */
  public Get = (params: { id?: number }): Promise<AssessmentDto> => {
    return this.get("/Assessment/Get", {
      params,
    });
  };

  /* @tags assessment */
  /* @name AssessmentGetAll */
  /* @request GET:/api/services/app/assessment/GetAll */
  public GetAll = (
    params: AssessmentGetAllParamsDto
  ): Promise<AssessmentPageDto> => {
    return this.get("/Assessment/GetAll", {
      params,
    });
  };

  /* @tags assessment */
  /* @name inputOutputCreate */
  /* @request POST:/api/services/app/assessment/Create */
  public Create = (params: AssessmentCreateDto): Promise<any> => {
    return this.post("/Assessment/Create", {
      ...params,
    });
  };

  /* @tags assessment */
  /* @name inputOutputDelete */
  /* @request POST:/api/services/app/assessment/Delete */
  public Delete = (params: { assessmentId: number }): Promise<any> => {
    return this.delete(`/Assessment/Delete`, {
      params,
    });
  };

  /* @tags assessment */
  /* @name AssessmentGetAll */
  /* @request GET:/api/services/app/assessment/getStepOne */
  public getStepOne = (params: {
    id: number;
  }): Promise<AssessmentStepOneDto> => {
    return this.get(`/Assessment/GetStepOne`, {
      params,
    });
  };

  /* @tags assessment */
  /* @name AssessmentGetAll */
  /* @request GET:/api/services/app/assessment/GetStepTwo */
  public getStepTwo = (params: {
    id: number;
  }): Promise<AssessmentStepTwoDto> => {
    return this.get(`/Assessment/GetStepTwo`, {
      params,
    });
  };

  /* @tags assessment */
  /* @name AssessmentGetAll */
  /* @request GET:/api/services/app/assessment/GetStepThree */
  public getStepThree = (params: {
    id: number;
  }): Promise<AssessmentStepThreeDto> => {
    return this.get(`/Assessment/GetStepThree`, {
      params,
    });
  };

  /* @tags assessment */
  /* @name AssessmentGetAll */
  /* @request GET:/api/services/app/assessment/GetStepFour */
  public getStepFour = (params: {
    id: number;
  }): Promise<AssessmentStepFourDto> => {
    return this.get(`/Assessment/GetStepFour`, {
      params,
    });
  };
  /* @tags assessment */
  /* @name AssessmentGetAll */
  /* @request GET:/api/services/app/assessment/GetStepFive */
  public getStepFive = (params: { id: number }): Promise<any> => {
    return this.get(`/Assessment/GetStepFive`, {
      params,
    });
  };

  /* @tags assessment */
  /* @name AssessmentUpdate */
  /* @request GET:/api/services/app/assessment/UpdateStepOne */
  public updateStepOneAssessment = (params: UpdateStepOneDto): Promise<any> => {
    return this.put(`/Assessment/UpdateStepOne`, {
      ...params,
    });
  };

  /* @tags assessment */
  /* @name AssessmentUpdate */
  /* @request GET:/api/services/app/assessment/UpdateStepTwo */
  public updateStepTwoAssessment = (params: UpdateStepTwoDto): Promise<any> => {
    return this.put(`/Assessment/UpdateStepTwo`, {
      ...params,
    });
  };

  /* @tags assessment */
  /* @name AssessmentUpdate */
  /* @request GET:/api/services/app/assessment/UpdateStepThree */
  public updateStepThreeAssessment = (
    params: UpdateStepThreeDto
  ): Promise<any> => {
    return this.put(`/Assessment/UpdateStepThree`, {
      ...params,
    });
  };

  /* @tags assessment */
  /* @name AssessmentUpdate */
  /* @request GET:/api/services/app/assessment/UpdateStepFour */
  public updateStepFourAssessment = (
    params: UpdateStepFourDto
  ): Promise<any> => {
    return this.put(`/Assessment/UpdateStepFour`, {
      ...params,
    });
  };
}
const assessment = new Assessment();
export default assessment;
